import { firestore } from "@/firebase";
import { OrgVersion } from "@/models/version";
import * as Sentry from "@sentry/nextjs";

const getVersions =
  (orgId: string) =>
  async (): Promise<{ majorVersion: number; minorVersion: number }[]> => {
    try {
      if (orgId) {
        const orgVersionQuery = await firestore
          .collection("orgs")
          .doc(orgId)
          .collection("versions")
          .orderBy("majorVersion", "desc")
          .orderBy("minorVersion", "desc")
          .get();
        if (!orgVersionQuery.size) return null;
        return orgVersionQuery.docs.map((doc) => {
          return {
            majorVersion: doc.data().majorVersion,
            minorVersion: doc.data().minorVersion,
          };
        });
      } else return null;
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      return null;
    }
  };

export default getVersions;
