import * as firebaseClient from "@/firebase";
import { Org } from "@/models/org";

const getMyOrgs = (uid: string) => async (): Promise<Org[]> => {
  const firestore = firebaseClient.firestore;
  const query = await firestore
    .collection("orgs")
    .where("admins", "array-contains", uid)
    .get();

  return query.docs.map((doc) => doc.data() as Org);
};

export default getMyOrgs;
