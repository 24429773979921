import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { usePopper } from "react-popper";
import useReady from "@/hooks/useReady";
import { colors } from "@/lib/styles";
import Portal from "./Portal";

const Wrapper = styled.div`
  position: relative;
`;

const Reference = styled.div``;

const Pop = styled.div<{ visible: boolean }>`
  visibility: ${(props) => (props.visible ? "shown" : "hidden")};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? "auto" : "none")};
  flex-direction: column;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGray};
  border-radius: 10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  padding: 10px;
  z-index: 300;
`;

type Props = {
  children: React.ReactNode | React.ReactNodeArray;
  component: React.FC<any>;
  style?: object;
  popStyle?: object;
  placement?: string;
  persistOnComponentClick?: boolean;
  offset?: [number, number];
  handleVisibility?: (visible: boolean) => void;
  disabled?: boolean;
  portal?: boolean;
};

const Popper: React.FC<Props> = ({
  children,
  component,
  style,
  popStyle,
  placement,
  handleVisibility,
  persistOnComponentClick,
  offset,
  disabled,
  portal,
}) => {
  const [visible, setVisibility] = useState(false);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: (placement as any) || "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: offset || [10, 10],
        },
      },
    ],
  });

  useEffect(() => {
    // listen for clicks and close dropdown on body
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [referenceElement, popperElement]);

  const handleDocumentClick = useCallback(
    (event) => {
      if (!referenceElement || !popperElement) return;
      if (
        referenceElement?.contains(event.target) ||
        popperElement?.contains(event.target)
      ) {
        return;
      }
      setVisibility(false);
      if (handleVisibility) handleVisibility(false);
    },
    [referenceElement, popperElement]
  );
  function handleDropdownClick(event) {
    if (event?.stopPropagation) event.stopPropagation();
    if (event?.preventDefault) event.preventDefault();
    if (persistOnComponentClick && visible) return;
    setVisibility(!visible);
    if (handleVisibility) handleVisibility(!visible);
  }

  const ready = useReady();
  if (!ready) return null;

  const PopComponent = (
    <Pop
      visible={!disabled && visible}
      ref={setPopperElement}
      style={{ ...styles.popper, ...popStyle }}
      {...attributes.popper}
    >
      {children}
    </Pop>
  );

  return (
    <Wrapper style={style}>
      <Reference ref={setReferenceElement}>
        {component({ onClick: handleDropdownClick })}
      </Reference>
      {portal && <Portal el="popup">{PopComponent}</Portal>}
      {!portal && PopComponent}
    </Wrapper>
  );
};

export default Popper;
