import React from "react";
import * as Sentry from "@sentry/nextjs";
import styled from "styled-components";
import Title from "../universal/Text/Title";
import Description from "../universal/Text/Description";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

export const ErrorComponent: React.FC = () => {
  return (
    <Wrapper>
      <Title>Something Went Wrong</Title>
      <Description>
        If this error continues, please contact an administrator
      </Description>
    </Wrapper>
  );
};

type Props = {};
type State = { hasError: boolean };

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo) {
    // You can also log the error to an error reporting service
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      <ErrorComponent />;
    }

    return <>{this.props.children}</>;
  }
}
