import { firestore } from "@/firebase";
import { ownerSlug, ownerType } from "@/lib/owner";
import { Folder } from "@/models/folder";
import { Owner } from "@/models/owner";
import { Program } from "@/models/program";
import { FolderVersion } from "@/models/version";
import { VERSION } from "lodash";
import getVersion from "../version/getVersion";

const getFolders =
  (owner: Owner, admin?: boolean, parent?: string) =>
  async (): Promise<Folder[]> => {
    if (!owner) return null;
    const filterFolders = (folders: Folder[]): Folder[] => {
      return folders.filter((folder) => {
        if (
          folder.status !== "ARCHIVED" &&
          ((admin &&
            !version?.explicit &&
            ["PRIVATE", "TEASER", "PUBLIC"].includes(folder.status)) ||
            ["TEASER", "PUBLIC"].includes(folder.status))
        )
          return true;
        else return false;
      });
    };

    let query = firestore
      .collection(ownerType(owner) === "org" ? "orgs" : "users")
      .doc(owner?.id)
      .collection("folders")
      .orderBy("position", "asc");
    if (typeof parent !== "undefined")
      query = query.where("parent", "==", parent);
    const queryResult = await query.get();

    const version = await getVersion(owner?.id)();

    if (admin && !version?.explicit) {
      return filterFolders(
        queryResult.docs.map((folderDoc) => {
          return folderDoc.data() as Folder;
        })
      );
    }

    let folders: Folder[] = [];

    for (const folderDoc of queryResult.docs) {
      const latestFolderDoc = await folderDoc.ref
        .collection("versions")
        .doc(
          version?.latest
            ? "latest"
            : `${version?.majorVersion}.${version?.minorVersion}`
        )
        .get();
      if (!latestFolderDoc.exists) continue;
      const folderVersion = latestFolderDoc.data() as FolderVersion;
      const folder = folderVersion.folder;
      folders.push(folder);
    }

    return filterFolders(folders);
  };

export default getFolders;
