import React from "react";

type Props = {
  size?: number;
  color?: string;
  style?: Record<string, unknown>;
};
const LinkIcon: React.FC<Props> = ({ size, style, color }) => {
  return (
    <div style={style}>
      <svg
        width={String(size)}
        height={String(size)}
        viewBox="0 0 23 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.71777 12.0659C10.1472 12.64 10.6951 13.1151 11.3243 13.4588C11.9535 13.8026 12.6493 14.007 13.3644 14.0582C14.0796 14.1094 14.7974 14.0063 15.4691 13.7557C16.1409 13.5051 16.7509 13.113 17.2578 12.6059L20.2578 9.60588C21.1686 8.66287 21.6725 7.39986 21.6611 6.08888C21.6498 4.77789 21.1239 3.52383 20.1969 2.59679C19.2698 1.66975 18.0158 1.14391 16.7048 1.13251C15.3938 1.12112 14.1308 1.62509 13.1878 2.53588L11.4678 4.24588"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          vectorEffect="non-scaling-stroke"
          className="stroke-path"
        />
        <path
          d="M13.718 10.0659C13.2885 9.49177 12.7406 9.01672 12.1114 8.67296C11.4822 8.3292 10.7865 8.12478 10.0713 8.07357C9.35618 8.02235 8.63839 8.12553 7.96663 8.37612C7.29487 8.6267 6.68486 9.01883 6.17797 9.5259L3.17797 12.5259C2.26718 13.4689 1.76321 14.7319 1.7746 16.0429C1.786 17.3539 2.31184 18.608 3.23888 19.535C4.16592 20.462 5.41998 20.9879 6.73097 20.9993C8.04195 21.0107 9.30496 20.5067 10.248 19.5959L11.958 17.8859"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          vectorEffect="non-scaling-stroke"
          className="stroke-path"
        />
      </svg>
    </div>
  );
};
LinkIcon.defaultProps = {
  style: {},
};

export default LinkIcon;
