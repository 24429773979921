import { useEffect, useState } from "react";

const useDragging = () => {
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    const startDrag = () => setDragging(true);
    let timeout: NodeJS.Timeout;
    const onClick = () => {
      timeout = setTimeout(startDrag, 200);
    };
    const stopDrag = () => {
      setDragging(false);
      clearTimeout(timeout);
    };

    window.addEventListener("mousedown", onClick);
    window.addEventListener("mouseup", stopDrag);

    return () => {
      window.removeEventListener("mousedown", onClick);
      window.removeEventListener("mouseup", stopDrag);
    };
  }, []);

  return dragging;
};

export default useDragging;
