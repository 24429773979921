import { MOBILE_WIDTH, TABLET_WIDTH } from "@/lib/constants";
import { colors } from "@/lib/styles";
import Link from "next/link";
import styled, { css, CSSObject } from "styled-components";

type Props = {
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  hoverColor?: string;
  color?: string;
  disabled?: boolean;
  href?: string;
  style?: Record<string, unknown>;
  desktopStyle?: CSSObject;
  tabletStyle?: CSSObject;
  mobileStyle?: CSSObject;
  onClick?: () => void;
  submit?: boolean;
  id?: string;
  target?: string;
  icon?: React.ReactElement;
};

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const style = (props: Props) => css<Props>`
  padding: 8px 10px;
  border-radius: 6px;
  width: 100%;
  outline: none;
  display: flex;
  flex-direction: row;
  text-align: left;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.white};
  color: ${(props) => props.color};
  font-weight: 400;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  text-decoration: none;
  ${(props) => props.desktopStyle && css(props.desktopStyle)}
  border: none;
  &:hover {
    background-color: ${(props) =>
      props.hoverBackgroundColor
        ? props.hoverBackgroundColor
        : props.backgroundColor
        ? props.backgroundColor
        : colors.nearWhite};
    color: ${(props) => (props.hoverColor ? props.hoverColor : props.color)};
  }
  path,
  rect,
  polyline {
    stroke: ${(props) => props.color};
  }
  &:hover path,
  &:hover rect,
  &:hover polyline {
    stroke: ${(props) => (props.hoverColor ? props.hoverColor : props.color)};
  }
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    ${(props) => props.tabletStyle && css(props.tabletStyle)}
  }
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    ${(props) => props.mobileStyle && css(props.mobileStyle)}
  }
  &:visited {
    color: ${(props) => props.color};
  }
`;
const StyledButton = styled.button<Props>`
  ${style}
`;

const StyledAnchor = styled.a<Props>`
  ${style}
  &:visited {
    color: inherit;
  }
`;

const PopupButton: React.FC<Props> = (props) => {
  if (props.href)
    return (
      <Link href={props.href}>
        <StyledAnchor {...props}>
          <IconWrapper>{props.icon}</IconWrapper>
          {props.children}
        </StyledAnchor>
      </Link>
    );
  else
    return (
      <StyledButton type={props.submit ? "submit" : "button"} {...props}>
        <IconWrapper>{props.icon}</IconWrapper>
        {props.children}
      </StyledButton>
    );
};
PopupButton.defaultProps = {
  color: colors.nearBlack,
};
export default PopupButton;
