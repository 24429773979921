import React from "react";

type Props = {
  height?: number;
  color?: string;
  style?: Record<string, unknown>;
};
const PlusIcon: React.FC<Props> = (props) => {
  return (
    <span style={props.style}>
      <svg
        width={String(props.height)}
        height={String(props.height)}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 1V13"
          stroke={props.color}
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 7H13"
          stroke={props.color}
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export default PlusIcon;
