import React from "react";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import useOwnerColor from "@/hooks/useOwnerColor";
import { rgba } from "polished";

type Props = {
  text: string;
  active?: boolean;
  style?: Record<string, unknown>;
  clickable?: boolean;
  onClick?: () => void;
};

const Wrapper = styled.div<{
  active?: boolean;
  color?: string;
  clickable?: boolean;
}>`
  padding: 4px 6px;
  border-radius: 6px;
  background-color: ${(props) =>
    props.active ? rgba(props.color, 0.1) : colors.nearWhite};
  color: ${(props) => (props.active ? colors.nearBlack : colors.darkGray)};
  font-size: 12px;
  font-weight: 500;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  border: none;
  &:hover {
    cursor: ${(props) => (props.clickable ? "pointer" : "default")};
    color: ${colors.nearBlack};
  }
`;

const Version: React.FC<Props> = (props) => {
  const { style, active, text, clickable, onClick, ...otherProps } = props;

  const color = useOwnerColor();

  return (
    <Wrapper
      style={style}
      {...otherProps}
      active={active}
      color={color}
      clickable={clickable}
      onClick={onClick}
    >
      {text}
    </Wrapper>
  );
};
Version.defaultProps = {
  style: {},
};

export default Version;
