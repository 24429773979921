import { OwnerContext } from "@/components/App";
import getVersion from "@/db/version/getVersion";
import getLatestOrgVersion from "@/db/version/getVersion";
import { useContext } from "react";
import { useQuery } from "react-query";
import useOwner from "./useOwner";

const useVersion = (latest?: boolean) => {
  const owner = useOwner();
  const versionQuery = useQuery(
    ["version", owner?.id],
    getVersion(owner?.id, latest)
  );
  if (versionQuery.data) return versionQuery.data;
};
export default useVersion;
