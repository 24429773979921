import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  color?: string;
  style?: Record<string, unknown>;
};
const TrashIcon: React.FC<Props> = ({ size, color, style }) => {
  return (
    <span style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={String(size)}
        height={String(size)}
        viewBox="0 0 24 24"
        fill="none"
      >
        <polyline
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={color}
          points="3 6 5 6 21 6"
        />
        <path
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={color}
          d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
        />
      </svg>
    </span>
  );
};
TrashIcon.defaultProps = {
  color: colors.darkGray,
  size: 22,
};

export default TrashIcon;
