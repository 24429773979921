import { firestore } from "@/firebase";
import { Program, Step } from "@/models/program";
import moment from "moment";
import * as Sentry from "@sentry/nextjs";
import { Group } from "@/models/group";
import { Owner } from "@/models/owner";
import { Feedback } from "@/models/feedback";
import log from "@/db/utils/log";
import getVersion from "../version/getVersion";

const addFeedback =
  (data: {
    userId: string;
    url: string;
    owner?: Owner;
    program?: Program;
    stepIndex?: number;
    group?: Group;
    feeling?: string;
    message?: string;
  }) =>
  async (): Promise<void> => {
    try {
      const {
        userId,
        owner,
        program,
        stepIndex,
        group,
        url,
        feeling,
        message,
      } = data;

      const feedback: Feedback = {
        id: "PENDING",
        userId,
        url,
        createdTime: moment().unix(),
      };

      if (owner?.id) {
        const { majorVersion, minorVersion } = await getVersion(owner?.id)();
        if (majorVersion) feedback.majorVersion = majorVersion;
        if (minorVersion) feedback.minorVersion = minorVersion;
      }

      if (owner) feedback.orgId = owner.id;
      if (program && program.id) feedback.programId = program.id;
      if (typeof stepIndex === "number") feedback.stepIndex = stepIndex;
      if (group) feedback.groupId = group?.id;
      if (feeling) feedback.feeling = feeling;
      if (message) feedback.message = message;

      const feedbackDoc = await firestore.collection("feedback").add(feedback);

      await feedbackDoc.update({ id: feedbackDoc.id });

      await log(
        "feedback",
        feeling && message ? `${feeling} ${message}` : message || feeling,
        url,
        userId
      )();
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

export default addFeedback;
