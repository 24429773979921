import { ownerAdmin } from "@/lib/owner";
import { Org } from "@/models/org";
import { authSelectors } from "@/redux/auth";
import { userSelectors } from "@/redux/user";
import { useSelector } from "react-redux";
import useOwner from "./useOwner";

const useAdmin = () => {
  const uid = useSelector(authSelectors.uid);
  const owner = useOwner();
  return ownerAdmin(owner, uid);
};
export default useAdmin;
