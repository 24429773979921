import styled from "styled-components";
import { colors } from "@/lib/styles";
import Button from "./Button/Button";
import { useState } from "react";
import TrashIcon from "../icons/TrashIcon";
import Input from "./Input/Input";
import { useMutation } from "react-query";
import Loading from "./Loading";
import { toast } from "react-hot-toast";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  z-index: 201;
  max-width: 360px;
`;

const Title = styled.p`
  font-size: 21px;
  color: ${colors.nearBlack};
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 28px;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 21px;
  color: ${colors.nearBlack};
  font-weight: 400;
  margin-bottom: 0px;
`;

const Slug = styled.span`
  color: ${colors.red};
  font-weight: 500;
`;

type Props = {
  title: string;
  description: string;
  slug: string;
  onConfirm: () => Promise<void>;
  deleteTitle?: string;
  successMessage?: string;
  style?: object;
};

export const ConfirmPopup: React.FC<Props> = ({
  title,
  description,
  slug,
  onConfirm,
  deleteTitle,
  style,
  successMessage,
}) => {
  const [confirm, setConfirm] = useState("");

  const disabled = confirm !== slug;

  const confirmMutation = useMutation(onConfirm, {
    onSuccess: () => {
      document.getElementById("content").click();
      if (successMessage) toast.success(successMessage);
    },
  });

  return (
    <>
      <Wrapper
        style={style}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        <Title>{title}</Title>
        <Description>
          {description}
          <br />
          Type <Slug>{slug}</Slug> to confirm
        </Description>
        <Input
          color={colors.red}
          type="text"
          value={confirm}
          onChange={(e) => setConfirm(e.target.value)}
          style={{ margin: 0 }}
          autoFocus
          placeholder={slug}
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        />
        <Button
          backgroundColor={!disabled ? colors.red : colors.nearWhite}
          color={!disabled ? colors.white : colors.nearBlack}
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            confirmMutation.mutate();
          }}
          disabled={disabled}
          style={{ marginTop: 0, marginBottom: 0, width: "100%" }}
        >
          {!confirmMutation.isLoading && (
            <TrashIcon
              color={!disabled ? colors.white : colors.nearBlack}
              style={{ marginRight: 10 }}
              size={20}
            />
          )}
          {confirmMutation.isLoading && (
            <Loading size={20} style={{ margin: "2px 10px 2px 0" }} light />
          )}
          {deleteTitle || "Archive"}
        </Button>
      </Wrapper>
    </>
  );
};
export default ConfirmPopup;
