import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  height?: number;
  color?: string;
  style?: Record<string, unknown>;
  className?: string;
};
const CheckIcon: React.FC<Props> = (props) => {
  const width = props.height ? String((props.height / 11) * 12) : "12";
  const height = props.height ? String(props.height) : "11";

  return (
    <div style={props.style} className={props.className ? props.className : ""}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transition: "all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99)",
        }}
      >
        <path
          d="M1 6L5.5 9.5L11 1.5"
          stroke={props.color || colors.primary}
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default CheckIcon;
