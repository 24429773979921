import { firestore } from "@/firebase";
import { Owner } from "@/models/owner";

const getSlugPaths = async (data: {
  owner: Owner;
  parent: string;
}): Promise<string[]> => {
  const { owner, parent } = data;

  const slugPaths: string[] = [];
  let parentId: string = parent;

  while (parentId) {
    const parentFolder = await firestore
      .collection("orgs")
      .doc(owner.id)
      .collection("folders")
      .doc(parentId)
      .get();
    if (parentFolder.exists) {
      slugPaths.push(parentFolder.data().slug as string);
      parentId = parentFolder.data().parent as string;
    }
  }

  return slugPaths.reverse();
};

export default getSlugPaths;
