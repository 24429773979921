import React, { useCallback } from "react";
import SideBarSection from "./SideBarSection";
import { useRouter } from "next/router";
import useOwner from "@/hooks/useOwner";
import { ownerPath } from "@/lib/path";
import PopupButton from "@/components/universal/Button/PopupButton";
import EmptySection from "./EmptySection";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Program } from "@/models/program";
import getPrograms from "@/db/program/getPrograms";
import useAdmin from "@/hooks/useAdmin";
import Image from "next/image";
import SideBarItem from "./SideBarItem";
import SettingsIcon from "@/components/icons/SettingsIcon";
import { colors } from "@/lib/styles";
import TrashIcon from "@/components/icons/TrashIcon";
import FolderIcon from "@/components/icons/FolderIcon";
import BuildIcon from "@/components/icons/BuildIcon";
import TogglePopup from "@/components/universal/TogglePopup";
import { FOLDER, PROGRAM } from "@/models/dnd";
import getFolders from "@/db/folder/getFolders";
import { Folder } from "@/models/folder";
import SideBarItemGroup from "./SideBarItemGroup";
import { Owner } from "@/models/owner";
import PlayIcon from "@/components/icons/PlayIcon";
import LinkIcon from "@/components/icons/LinkIcon";
import { toast } from "react-hot-toast";
import { ownerDomain } from "@/lib/owner";
import useGroupAdmin from "@/hooks/useGroupAdmin";
import ConfirmPopup from "@/components/universal/ConfirmPopup";
import archiveFolder from "@/db/folder/archiveFolder";
import archiveProgram from "@/db/program/archiveProgram";
import LibraryIcon from "@/components/icons/LibraryIcon";
import styled from "styled-components";
import PlusIcon from "@/components/icons/PlusIcon";
import useGroup from "@/hooks/useGroup";

export const NewButton = styled.button`
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  border: 1px solid ${colors.lightGray};
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  background: none;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:hover {
    background-color: ${colors.nearWhite};
    cursor: pointer;
  }
  path: {
    stroke: ${colors.darkGray};
  }
  &:hover path {
    stroke: ${colors.nearBlack};
  }
`;

type Props = {};
const Library: React.FC<Props> = ({}) => {
  const router = useRouter();
  const owner = useOwner();
  const admin = useAdmin();
  const groupAdmin = useGroupAdmin();
  const queryClient = useQueryClient();
  const group = useGroup();

  const archiveProgramMutation = useMutation(archiveProgram, {
    onSuccess: () => {
      queryClient.invalidateQueries(["sidebar-items"]);
      queryClient.invalidateQueries(["folders", owner.id]);
      queryClient.invalidateQueries(["programs", owner.id, admin]);
    },
  });

  const archiveFolderMutation = useMutation(archiveFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries(["sidebar-items"]);
      queryClient.invalidateQueries(["folders", owner.id]);
      queryClient.invalidateQueries(["programs", owner.id, admin]);
    },
  });

  const foldersQuery = useQuery<Folder[]>(
    ["folders", owner?.id, admin],
    getFolders(owner, admin, null),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  const programsQuery = useQuery<Program[]>(
    ["programs", owner?.id, admin, null],
    getPrograms(owner, admin, null),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  const ProgramComponent = (program: Program, depth: number) => (
    <SideBarItem
      id={program.id}
      key={`program-${program.id}`}
      parent={program.parent}
      type={PROGRAM}
      peerType={[PROGRAM, FOLDER]}
      position={program.position}
      title={program.title}
      href={ownerPath(owner, [...program.slugPaths, program.slug].join("/"))}
      moreLabel={"Preview, edit, settings..."}
      item={program}
      more={
        admin || groupAdmin
          ? [
              <PopupButton
                key={`program-${program.id}-preview`}
                href={ownerPath(owner, `program/${program.id}/preview`, {
                  i: 0,
                })}
                icon={<PlayIcon size={16} color={colors.nearBlack} />}
              >
                Preview
              </PopupButton>,
              <PopupButton
                key={`program-${program.id}-copy-link`}
                icon={<LinkIcon size={16} color={colors.nearBlack} />}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${ownerDomain(owner)}/${program.slug}`
                  );
                  toast.success("Link copied to clipboard");
                }}
              >
                Copy link
              </PopupButton>,
              admin ? (
                <PopupButton
                  key={`program-${program.id}-edit`}
                  href={ownerPath(owner, `program/${program.id}/build`, {
                    i: 0,
                  })}
                  icon={<BuildIcon size={16} color={colors.nearBlack} />}
                >
                  Edit
                </PopupButton>
              ) : null,
              admin ? (
                <PopupButton
                  key={`program-${program.id}-settings`}
                  href={ownerPath(owner, `program/${program.id}/settings`)}
                  icon={<SettingsIcon size={16} color={colors.nearBlack} />}
                >
                  Settings
                </PopupButton>
              ) : null,
              admin ? (
                <TogglePopup
                  popupId={`program-${program.id}-delete`}
                  z={201}
                  key={`program-${program.id}-delete`}
                  style={{ width: "100%" }}
                  toggle={
                    <PopupButton
                      icon={<TrashIcon size={16} color={colors.nearBlack} />}
                    >
                      Archive
                    </PopupButton>
                  }
                >
                  <ConfirmPopup
                    title={`Archive ${program.title}`}
                    description="This course will be hidden and archived"
                    successMessage="Course archived"
                    slug={program.slug}
                    onConfirm={async () => {
                      if (program.protected)
                        alert(
                          "Please contact support to archive a protected program"
                        );
                      else
                        await archiveProgramMutation.mutateAsync({
                          programId: program.id,
                        });
                    }}
                  />
                </TogglePopup>
              ) : null,
            ]
          : null
      }
      icon={
        program.image && (
          <div
            style={{
              width: 16,
              height: 16,
              overflow: "hidden",
            }}
          >
            <Image
              src={program.image}
              width={16}
              height={16}
              objectFit="contain"
              objectPosition={"center center"}
            />
          </div>
        )
      }
      active={(path) =>
        path.includes(ownerPath(owner, `program/${program.id}`)) ||
        path.includes(
          ownerPath(owner, [...program.slugPaths, program.slug].join("/"))
        )
      }
      depth={depth}
    />
  );

  const getItems = useCallback(
    (owner: Owner, folder: Folder, admin: boolean) =>
      (depth: number) =>
      async () => {
        const subFolders = await getFolders(owner, admin, folder?.id)();
        const programs = await getPrograms(owner, admin, folder?.id)();
        const sortedItems = [
          ...subFolders.map((f) => ({ ...f, type: "FOLDER" })),
          ...programs.map((p) => ({ ...p, type: "PROGRAM" })),
        ].sort((a, b) => b.position - a.position);
        return sortedItems.map((item) => {
          switch (item.type) {
            case "FOLDER":
              return FolderComponent(item as Folder, depth + 1);
            case "PROGRAM":
              return ProgramComponent(item as Program, depth + 1);
          }
        });
      },
    []
  );

  const FolderComponent = useCallback(
    (folder: Folder, depth: number) => (
      <SideBarItemGroup
        id={folder.id}
        parent={folder.parent}
        itemParent={folder.id}
        type={FOLDER}
        itemType={[PROGRAM, FOLDER]}
        peerType={[PROGRAM, FOLDER]}
        position={folder.position}
        key={`folder-${folder.id}`}
        title={folder.title}
        href={ownerPath(owner, [...folder.slugPaths, folder.slug].join("/"))}
        emptyPlaceholder={"No programs"}
        depth={depth}
        icon={
          folder?.image ? (
            <Image
              src={folder.image}
              width={16}
              height={16}
              objectFit="contain"
              objectPosition={"center center"}
            />
          ) : (
            <FolderIcon size={16} style={{ transform: "translateY(2px)" }} />
          )
        }
        moreLabel={"Add, settings, more..."}
        more={
          admin
            ? [
                <PopupButton
                  key={`folder-${folder.id}-folder`}
                  href={ownerPath(owner, `folder/new`, { parent: folder.id })}
                  icon={<FolderIcon size={16} color={colors.nearBlack} />}
                >
                  New folder
                </PopupButton>,
                <PopupButton
                  key={`folder-${folder.id}-program`}
                  href={ownerPath(owner, `new`, { parent: folder.id })}
                  icon={<BuildIcon size={16} color={colors.nearBlack} />}
                >
                  New program
                </PopupButton>,
                <PopupButton
                  key={`folder-${folder.id}-settings`}
                  href={ownerPath(owner, `folder/${folder.id}/settings`, null)}
                  icon={<SettingsIcon size={16} color={colors.nearBlack} />}
                >
                  Settings
                </PopupButton>,
                <TogglePopup
                  popupId={`folder-${folder.id}-delete`}
                  z={201}
                  key={`folder-${folder.id}-delete`}
                  style={{ width: "100%" }}
                  toggle={
                    <PopupButton
                      icon={<TrashIcon size={16} color={colors.nearBlack} />}
                    >
                      Archive
                    </PopupButton>
                  }
                >
                  <ConfirmPopup
                    title={`Archive ${folder.title}`}
                    description="This folder will be hidden and archived"
                    successMessage="Folder archived"
                    slug={folder.slug}
                    onConfirm={async () =>
                      await archiveFolderMutation.mutateAsync({
                        folderId: folder.id,
                        owner,
                      })
                    }
                  />
                </TogglePopup>,
              ]
            : undefined
        }
        active={(path) =>
          path ===
            ownerPath(owner, [...folder.slugPaths, folder.slug].join("/")) ||
          path === ownerPath(owner, `folder/${folder.id}`)
        }
        open={(path) =>
          path.includes(folder.slug) ||
          path.includes(ownerPath(owner, `folder/${folder.id}`))
        }
        getItems={getItems(owner, folder, admin)}
      />
    ),
    [owner, router.asPath, admin]
  );

  const rootFolders = foldersQuery.data?.filter((f) => f.parent === null) || [];
  const rootPrograms =
    programsQuery.data?.filter((p) => p.parent === null) || [];

  const sortedItems = [
    ...rootFolders.map((f) => ({ ...f, type: "FOLDER" })),
    ...rootPrograms.map((p) => ({ ...p, type: "PROGRAM" })),
  ].sort((a, b) => a.position - b.position);
  const Items = sortedItems.map((item) => {
    switch (item.type) {
      case "FOLDER":
        return FolderComponent(item as Folder, 0);
      case "PROGRAM":
        return ProgramComponent(item as Program, 0);
    }
  });

  return (
    <>
      <SideBarSection
        icon={
          <LibraryIcon size={16} style={{ transform: "translateY(2px)" }} />
        }
        href={ownerPath(owner, "")}
        active={router.asPath.split("?")[0] === ownerPath(owner, "")}
        title="Library"
        action={
          admin ? (
            <TogglePopup
              popupId={`create`}
              tooltip="Create"
              tooltipPosition="left"
              toggle={
                <NewButton>
                  <PlusIcon
                    color={colors.gray}
                    height={12}
                    style={{ transform: "translateY(1px)" }}
                  />
                </NewButton>
              }
            >
              <PopupButton
                href={ownerPath(owner, `folder/new`, null)}
                icon={<FolderIcon size={16} color={colors.nearBlack} />}
              >
                New folder
              </PopupButton>
              <PopupButton
                href={ownerPath(owner, `new`, null)}
                icon={<BuildIcon size={16} color={colors.nearBlack} />}
              >
                New program
              </PopupButton>
            </TogglePopup>
          ) : null
        }
      >
        <>
          {programsQuery.data &&
            programsQuery.data?.length === 0 &&
            foldersQuery &&
            foldersQuery.data?.length === 0 &&
            programsQuery.isSuccess &&
            foldersQuery.isSuccess && (
              <EmptySection title={"Add a folder or create a steppable"} />
            )}
          {Items}
        </>
      </SideBarSection>
    </>
  );
};

export default Library;
