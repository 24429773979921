import { MOBILE_WIDTH } from "@/lib/constants";
import { colors } from "@/lib/styles";
import styled from "styled-components";

const Title = styled.h1`
  font-size: 50px;
  color: ${colors.nearBlack};
  font-weight: 600;
  text-align: center;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  margin-bottom: 20px;
  margin-top: 20px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 37px;
  }
`;
export default Title;
