import { groupAdmin } from "@/lib/group";
import { ownerAdmin } from "@/lib/owner";
import { Org } from "@/models/org";
import { authSelectors } from "@/redux/auth";
import { userSelectors } from "@/redux/user";
import { useSelector } from "react-redux";
import useGroup from "./useGroup";
import useOwner from "./useOwner";

const useGroupAdmin = () => {
  const uid = useSelector(authSelectors.uid);
  const owner = useOwner();
  const group = useGroup();
  return groupAdmin(owner, group, uid);
};
export default useGroupAdmin;
