import { firestore } from "@/firebase";
import { Group } from "@/models/group";
import { Org } from "@/models/org";
import { Program, Step } from "@/models/program";
import * as Sentry from "@sentry/nextjs";

const updateGroup = async (data: {
  orgId: string;
  groupId: string;
  groupUpdate: Partial<Group>;
}): Promise<void> => {
  try {
    const { orgId, groupId, groupUpdate } = data;
    const groupDoc = await firestore
      .collection("orgs")
      .doc(orgId)
      .collection("groups")
      .doc(groupId)
      .get();
    if (groupDoc.exists) {
      await groupDoc.ref.update(groupUpdate);
    } else throw new Error("Could not retrieve group");
  } catch (error) {
    Sentry.captureException(error);
  }
};

export default updateGroup;
