import { colors } from "@/lib/styles";
import { rgba } from "polished";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.color ? rgba(props.color, 0.1) : colors.white};
  border: 1px solid
    ${(props) => (props.color ? rgba(props.color, 0.2) : colors.lightGray)};
  color: ${colors.darkGray};
`;

type Props = {
  title?: string;
  icon?: React.ReactElement;
  size: number;
  color?: string;
  textColor?: string;
  style?: Record<string, unknown>;
};

const EmptyIcon: React.FC<Props> = ({ title, size, color, style, icon }) => {
  return (
    <Wrapper
      className="empty-icon"
      color={color}
      style={{
        width: size,
        height: size,
        borderRadius: Math.min(size / 5, 10),
        fontSize: Math.max(12, Math.min(size - 10, 21)),
        ...style,
      }}
    >
      {title && title?.[0]}
      {!title && icon}
    </Wrapper>
  );
};

export default EmptyIcon;
