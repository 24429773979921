import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
};
const UsersIcon: React.FC<Props> = (props) => {
  return (
    <span style={props.style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={String(props.size)}
        height={String(props.size)}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          stroke={props.color}
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"
        />
        <circle
          stroke={props.color}
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          cx="9"
          cy="7"
          r="4"
        />
        <path
          stroke={props.color}
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M23 21v-2a4 4 0 0 0-3-3.87"
        />
        <path
          stroke={props.color}
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 3.13a4 4 0 0 1 0 7.75"
        />
      </svg>
    </span>
  );
};
UsersIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default UsersIcon;
