import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
};
const NestingIcon: React.FC<Props> = ({ size, style, color }) => {
  const height = size ? String(size) : "8";
  const width = size ? String((size / 10) * 8) : "10";
  return (
    <div style={style}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transition: "all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99)" }}
      >
        <path
          d="M5.86601 7.5C5.48111 8.16667 4.51886 8.16667 4.13396 7.5L0.669856 1.5C0.284955 0.833333 0.76608 1.9314e-07 1.53588 2.60438e-07L8.46408 8.66121e-07C9.23389 9.33419e-07 9.71501 0.833334 9.33011 1.5L5.86601 7.5Z"
          fill={color}
          strokeWidth={0}
        />
      </svg>
    </div>
  );
};
NestingIcon.defaultProps = {
  color: colors.darkGray,
  size: 15,
};

export default NestingIcon;
