import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import loadingLight from "@/lib/animations/loadingLight.json";
import loadingDark from "@/lib/animations/loadingDark.json";

const Wrapper = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  align-self: center;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
`;

type Props = {
  size: number;
  style?: object;
  light?: boolean;
};
const Loading: React.FC<Props> = (props) => {
  const { size, style, light } = props;

  return (
    <Wrapper size={size} style={style}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: !light ? loadingDark : loadingLight,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={size * 3}
        width={size * 3}
      />
    </Wrapper>
  );
};
Loading.defaultProps = {
  style: {},
};

export default Loading;
