import { useEffect, useState } from "react";

const useReady = () => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, 5);
  }, []);
  return ready;
};
export default useReady;
