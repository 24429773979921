import Button from "@/components/universal/Button/Button";
import { ownerColor } from "@/lib/owner";
import { ownerPath } from "@/lib/path";
import { colors } from "@/lib/styles";
import { Folder } from "@/models/folder";
import { Org } from "@/models/org";
import { Owner } from "@/models/owner";
import { Program } from "@/models/program";
import React from "react";
import Feedback from "./Feedback";
import { NavLink } from "./NavLinks";
import User from "./UserIcon";
import { Group } from "@/models/group";
import { ImageWrapper, ItemDivider, ItemTitle, ItemWrapper } from "./NavPath";
import Image from "next/image";

export const LANDING_LINKS = (data: {
  owner: Owner;
  program?: Program;
  folder?: Folder;
}): (NavLink | React.ReactElement)[] => [
  HELP_LINK(data.owner as Org, "left"),
  <Feedback key="topbar-feedback" />,
];

export const ADMIN_LINKS = (data: {
  owner: Owner;
  program?: Program;
  folder?: Folder;
}): (NavLink | React.ReactElement)[] => [
  HELP_LINK(data.owner as Org, "left"),
  <Feedback key="topbar-feedback" />,
];

export const GROUP_ADMIN_LINKS = (data: {
  owner: Owner;
  program?: Program;
  folder?: Folder;
}): (NavLink | React.ReactElement)[] => [
  HELP_LINK(data.owner as Org, "left"),
  <Feedback key="topbar-feedback" />,
];

export const RESOURCES_LINK = (): NavLink => ({
  name: "resources",
  title: "Resources",
  dropdown: [
    {
      name: "tutorials",
      title: "Tutorials",
      path: "/tutorials",
      comingSoon: true,
    },
    {
      name: "templates",
      title: "Templates",
      path: "/templates",
      comingSoon: true,
    },
    {
      name: "blog",
      title: "Blog",
      path: "/blog",
      comingSoon: true,
    },
  ],
});

export const HELP_LINK = (org: Org, side?: NavLink["side"]): NavLink => ({
  name: "help",
  title: "Help",
  dropdown: [
    {
      name: "phone",
      title: "+1 (978) 254-1076",
      path: "tel:9782541076",
    },
    {
      name: "email",
      title: org?.contactEmail ? org.contactEmail : "team@steppable.com",
      path: org?.contactEmail
        ? `mailto:${org.contactEmail}`
        : "mailto:team@steppable.com",
    },
  ],
  side,
});

export const ABOUT_LINK = (): NavLink => ({
  name: "about",
  title: "About",
  path: "/",
});

export const STORY_LINK = (): NavLink => ({
  name: "story",
  title: "Story",
  path: "/story",
});

export const PRICING_LINK = (): NavLink => ({
  name: "pricing",
  title: "Pricing",
  url: "https://calendly.com/steppable/demo",
  newTab: true,
});

export const GROUP_LINK = (owner: Owner, group: Group): NavLink => ({
  name: group.slug,
  icon: (
    <>
      <ItemWrapper>
        {group?.image && (
          <ImageWrapper>
            <Image
              src={group.image}
              layout="fixed"
              objectFit="contain"
              width={30}
              height={30}
              priority
            />
          </ImageWrapper>
        )}
        <ItemTitle id="group-title">{group.title}</ItemTitle>
      </ItemWrapper>
      <ItemDivider style={{ marginRight: 0 }}>/</ItemDivider>
    </>
  ),
  path: "",
  style: {
    marginRight: 0,
  },
});

export const ACCOUNT_LINK = (owner?: Owner): NavLink => ({
  name: "account",
  icon: <User />,
  style: { paddingRight: 0, marginRight: 0, marginLeft: 10 },
  path: ownerPath(owner, "my/progress", null),
  side: "left",
  dropdown: [
    {
      name: "progress",
      title: "Progress",
      path: owner ? ownerPath(owner, "my/progress", null) : "/logout",
    },
    {
      name: "settings",
      title: "Settings",
      path: owner ? ownerPath(owner, "my/settings", null) : "/logout",
    },
    {
      name: "logout",
      title: "Log out",
      path: owner ? ownerPath(owner, "logout", null) : "/logout",
    },
  ],
});

export const LOGIN_LINK = (owner?: Owner, getPath?: () => string): NavLink => ({
  name: "account",
  icon: (
    <Button backgroundColor={ownerColor(owner)} color={colors.white}>
      Log in
    </Button>
  ),
  style: { paddingRight: 0, marginRight: 0, marginLeft: 10 },
  path: ownerPath(owner, "login", {
    next: getPath && getPath() !== "/" ? getPath() : undefined,
  }),
});
