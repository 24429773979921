import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
};
const DashboardIcon: React.FC<Props> = ({ size, style, color }) => {
  return (
    <span style={style}>
      <svg
        width={String(size)}
        height={String(size)}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12.5" cy="12.5" r="2.5" stroke={color} strokeWidth="2" />
        <path
          d="M1 5.5V1.5C1 1.22386 1.22386 1 1.5 1H5.5C5.77614 1 6 1.22386 6 1.5V5.5C6 5.77614 5.77614 6 5.5 6H1.5C1.22386 6 1 5.77614 1 5.5Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M10 5.5V1.5C10 1.22386 10.2239 1 10.5 1H14.5C14.7761 1 15 1.22386 15 1.5V5.5C15 5.77614 14.7761 6 14.5 6H10.5C10.2239 6 10 5.77614 10 5.5Z"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M5.5 10H1.5C1.22386 10 1 10.2239 1 10.5V14.5C1 14.7761 1.22386 15 1.5 15H5.5C5.77614 15 6 14.7761 6 14.5V10.5C6 10.2239 5.77614 10 5.5 10Z"
          stroke={color}
          strokeWidth="2"
        />
      </svg>
    </span>
  );
};
DashboardIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default DashboardIcon;
