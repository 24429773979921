import useOwner from "@/hooks/useOwner";
import { TABLET_WIDTH } from "@/lib/constants";
import { colors } from "@/lib/styles";
import { useRouter } from "next/router";
import React from "react";
import styled from "styled-components";
import SideBarLink from "./SideBarLink";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 0px;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${colors.white};
  position: sticky;
  top: 0;
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    top: -15px;
  }
`;

const Children = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const Title = styled.h4`
  font-size: 12px;
  color: ${colors.darkGray};
  font-weight: 400;
  white-space: nowrap;
`;

type Props = {
  title: string;
  href: string;
  action?: React.ReactElement;
  children?: React.ReactElement | React.ReactElement[];
  style?: object;
  active?: boolean;
  icon?: React.ReactElement;
  iconStyle?: object;
};
const SideBarSection: React.FC<Props> = ({
  title,
  action,
  children,
  icon,
  active,
  style,
  href,
  iconStyle,
}) => {
  const router = useRouter();
  const owner = useOwner();
  return (
    <Wrapper style={style}>
      <Children>{children}</Children>
      <Header>
        <SideBarLink
          title={title}
          iconStyle={iconStyle}
          href={href}
          icon={icon}
          active={active}
          action={action}
        />
      </Header>
    </Wrapper>
  );
};

export default SideBarSection;
