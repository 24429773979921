import { firestore } from "@/firebase";
import { ownerCollection } from "@/lib/owner";
import { Folder } from "@/models/folder";
import firebase from "firebase";
import { Owner } from "@/models/owner";
import * as Sentry from "@sentry/nextjs";
import updateChildrenPaths from "../path/updateChildrenSlugPaths";

const updateFolder = async (data: {
  owner: Owner;
  folderId: string;
  folderUpdate: Partial<Folder>;
}): Promise<void> => {
  try {
    const { owner, folderId, folderUpdate } = data;
    const folderDoc = await firestore
      .collection(ownerCollection(owner))
      .doc(owner.id)
      .collection("folders")
      .doc(folderId)
      .get();
    if (folderDoc.exists) {
      await folderDoc.ref.update(folderUpdate);
      const oldFolder = folderDoc.data() as Folder;
      if (oldFolder.slug !== folderUpdate.slug) {
        await updateChildrenPaths({
          owner,
          folder: { ...oldFolder, ...folderUpdate },
        });
      }
      await firestore
        .collection("orgs")
        .doc(oldFolder.id)
        .collection("versions")
        .doc("next")
        .set(
          {
            changed: firebase.firestore.FieldValue.arrayUnion(folderId),
          },
          { merge: true }
        );
    } else throw new Error("Could not retrieve folder");
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

export default updateFolder;
