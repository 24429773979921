import { colors } from "@/lib/styles";
import React from "react";
import styled from "styled-components";

const Span = styled.span<{ color: string }>`
  path {
    stroke: ${(props) => props.color};
  }
`;

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
};
const LockIcon: React.FC<Props> = (props) => {
  const height = props.size ? String(props.size) : "22";
  const width = props.size ? String((props.size * 20) / 22) : "20";

  return (
    <Span style={props.style} color={props.color}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 10H3C1.89543 10 1 10.8954 1 12V19C1 20.1046 1.89543 21 3 21H17C18.1046 21 19 20.1046 19 19V12C19 10.8954 18.1046 10 17 10Z"
          stroke={props.color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 10V6C5 4.67392 5.52678 3.40215 6.46447 2.46447C7.40215 1.52678 8.67392 1 10 1C11.3261 1 12.5979 1.52678 13.5355 2.46447C14.4732 3.40215 15 4.67392 15 6V10"
          stroke={props.color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Span>
  );
};
LockIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default LockIcon;
