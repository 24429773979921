import UserIcon from "@/components/icons/UserIcon";
import EmptyIcon from "@/components/universal/EmptyIcon";
import useLoggedIn from "@/hooks/useLoggedIn";
import useUserImage from "@/hooks/useUserImage";
import useUserName from "@/hooks/useUserName";
import { TABLET_WIDTH } from "@/lib/constants";
import { colors } from "@/lib/styles";

import Image from "next/image";
import styled from "styled-components";

const Wrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  height: 60px;
  flex-shrink: 0;
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    justify-content: center;
    margin-left: 0;
  }
`;

const UserImage = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  background-color: ${colors.nearWhite};
`;

type Props = {};

const User: React.FC<Props> = (props) => {
  const loggedIn = useLoggedIn();
  const userName = useUserName();
  const userImage = useUserImage();

  if (!loggedIn) return null;

  return (
    <Wrapper>
      <UserImage>
        {!!userImage && (
          <Image
            src={userImage}
            layout="responsive"
            width={30}
            height={30}
            className="contain"
          />
        )}
        {!userImage && (
          <EmptyIcon
            size={30}
            title={userName}
            icon={
              <UserIcon
                size={18}
                style={{ transform: "translateY(2px)" }}
                color={colors.gray}
              />
            }
            style={{
              borderRadius: 15,
            }}
          />
        )}
      </UserImage>
    </Wrapper>
  );
};

export default User;
