import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
};
const ZapIcon: React.FC<Props> = ({ size, style, color }) => {
  return (
    <span style={style}>
      <svg
        width={String(size)}
        height={String(size)}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.4691 2.9852L3.46912 14.9852H12.4691L11.4691 22.9852L21.4691 10.9852H12.4691L13.4691 2.9852Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
ZapIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default ZapIcon;
