import useAdmin from "@/hooks/useAdmin";
import useOwner from "@/hooks/useOwner";
import { ownerType } from "@/lib/owner";
import React, { useCallback } from "react";
import SideBarSection from "./SideBarSection";
import { orgGroupPath, ownerPath } from "@/lib/path";
import { useQuery, useQueryClient } from "react-query";
import { Group } from "@/models/group";
import getGroups from "@/db/group/getGroups";
import EmptySection from "./EmptySection";
import Image from "next/image";
import PopupButton from "@/components/universal/Button/PopupButton";
import SideBarItem from "./SideBarItem";
import SideBarItemGroup from "./SideBarItemGroup";
import getGroupPeople from "@/db/people/getGroupPeople";
import TrashIcon from "@/components/icons/TrashIcon";
import { colors } from "@/lib/styles";
import TogglePopup from "@/components/universal/TogglePopup";
import SettingsIcon from "@/components/icons/SettingsIcon";
import UsersIcon from "@/components/icons/UsersIcon";
import { GROUP, USER } from "@/models/dnd";
import getSubGroups from "@/db/group/getSubGroups";
import { Owner } from "@/models/owner";
import useGroupAdmin from "@/hooks/useGroupAdmin";
import ConfirmPopup from "@/components/universal/ConfirmPopup";
import archiveGroup from "@/db/group/archiveGroup";
import { Org } from "@/models/org";
import { useRouter } from "next/router";
import getUser from "@/db/user/getUser";
import useGroup from "@/hooks/useGroup";
import styled from "styled-components";
import * as Sentry from "@sentry/nextjs";
import { NewButton } from "./Library";
import PlusIcon from "@/components/icons/PlusIcon";

const GroupItemsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
`;

type Props = {};
const Groups: React.FC<Props> = ({}) => {
  const admin = useAdmin();
  const groupAdmin = useGroupAdmin();
  const owner = useOwner();
  const queryClient = useQueryClient();
  const router = useRouter();
  const group = useGroup();

  const groupsQuery = useQuery<Group[]>(
    ["groups", owner?.id],
    admin && owner?.id === "uid"
      ? null
      : getGroups(owner?.id, group?.id, admin),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  const userId = router.query?.userId as string;
  const activeUserQuery = useQuery(
    [`user`, userId],
    userId ? getUser(userId) : null
  );
  const activeUser = activeUserQuery.data;

  const rootGroups =
    groupsQuery.data?.filter((group) => group?.parent === null) || [];

  const getItems =
    (owner: Owner, group: Group) => (depth: number) => async () => {
      try {
        const subGroups = await getSubGroups({ owner, group })();
        const users =
          depth >= (admin ? 1 : 0)
            ? await getGroupPeople(
                group.parent
                  ? {
                      ownerId: owner?.id,
                      groupId: group?.parent,
                      subgroupId: group?.id,
                    }
                  : {
                      ownerId: owner?.id,
                      groupId: group?.id,
                    }
              )()
            : null;
        const items = [
          ...(users || [])?.map((user, i) => (
            <SideBarItem
              id={user?.id}
              key={`people-user-${user?.id}`}
              parent={group?.id}
              position={i}
              title={user?.name}
              href={ownerPath(owner, `user/${user?.id}`)}
              type={USER}
              icon={
                user.image && (
                  <div
                    style={{
                      width: 16,
                      height: 16,
                      borderRadius: 8,
                      overflow: "hidden",
                    }}
                  >
                    <Image
                      src={user.image}
                      width={16}
                      height={16}
                      objectFit="contain"
                      objectPosition={"center center"}
                    />
                  </div>
                )
              }
              iconStyle={{ borderRadius: 8, overflow: "hidden" }}
              active={(path) =>
                path.includes(ownerPath(owner, `user/${user?.id}`))
              }
              depth={depth ? depth + 1 : 1}
            />
          )),

          ...(subGroups || [])?.map((subGroup, i) =>
            GroupComponent(subGroup, depth + 1)
          ),
        ];
        return items.filter(Boolean);
      } catch (error) {
        Sentry.captureException(error);
        return [];
      }
    };

  const GroupComponent = useCallback(
    (group: Group, depth: number) => (
      <SideBarItemGroup
        id={group?.id}
        parent={group?.parent}
        itemType={[GROUP, USER]}
        itemParent={group?.id}
        position={group?.position}
        key={`group-link-${group?.id}`}
        title={group?.title}
        href={orgGroupPath(owner as Org, group, "")}
        emptyPlaceholder={group.parent ? "No members" : "No groups"}
        depth={depth}
        icon={
          group?.image ? (
            <Image
              src={group?.image}
              width={16}
              height={16}
              objectFit="contain"
              objectPosition={"center center"}
            />
          ) : (
            <UsersIcon size={16} />
          )
        }
        moreLabel={"Invite, settings, more..."}
        more={[
          group.parent ? (
            <PopupButton
              key={`people-${group?.id}-members`}
              icon={<UsersIcon size={16} color={colors.nearBlack} />}
              href={orgGroupPath(owner as Org, group, "")}
            >
              Add members
            </PopupButton>
          ) : (
            <PopupButton
              key={`people-${group?.id}-admins`}
              icon={<UsersIcon size={16} color={colors.nearBlack} />}
              href={ownerPath(owner, `group/new`, { parent: group?.id })}
            >
              Add group
            </PopupButton>
          ),
          admin ? (
            <PopupButton
              key={`people-${group?.id}-settings`}
              icon={<SettingsIcon size={16} color={colors.nearBlack} />}
              href={orgGroupPath(owner as Org, group, "settings")}
            >
              Settings
            </PopupButton>
          ) : null,
          admin || groupAdmin ? (
            <TogglePopup
              popupId={`group-${group?.id}-delete`}
              z={201}
              key={`group-${group?.id}-delete`}
              style={{ width: "100%" }}
              tooltipPosition={"right"}
              toggle={
                <PopupButton
                  icon={<TrashIcon size={16} color={colors.nearBlack} />}
                >
                  Archive
                </PopupButton>
              }
            >
              <ConfirmPopup
                title={`Archive ${group?.title}`}
                description="This group will be hidden and archived"
                successMessage="Group archived"
                slug={group?.slug}
                onConfirm={async () => {
                  await archiveGroup(owner, group?.id);
                  queryClient.invalidateQueries(["sidebar-items"]);
                  queryClient.invalidateQueries(["groups", owner.id]);
                }}
              />
            </TogglePopup>
          ) : null,
        ]}
        active={(path) => path.includes(ownerPath(owner, `group/${group?.id}`))}
        open={(path) =>
          path.includes(ownerPath(owner, `group/${group?.id}`)) ||
          activeUser?.memberships?.includes(`${group?.orgId}/${group?.id}`)
        }
        type={GROUP}
        getItems={getItems(owner, group)}
      />
    ),
    [owner]
  );

  const groupItemsQuery = useQuery(
    ["group-items", owner, group],
    getItems(owner, group)(-1)
  );

  return (
    <>
      {(admin || groupAdmin) && ownerType(owner) === "org" && (
        <SideBarSection
          title={"Groups"}
          href={
            admin
              ? ownerPath(owner, "groups", null)
              : ownerPath(owner, `group/${group?.id}/groups`, null)
          }
          active={
            router.asPath.split("?")[0] ===
            (admin
              ? ownerPath(owner, "groups", null)
              : ownerPath(owner, `group/${group?.id}/groups`, null))
          }
          icon={
            <UsersIcon size={16} style={{ transform: "translateY(2px)" }} />
          }
          action={
            <TogglePopup
              popupId={`add-group`}
              tooltip={"Add group"}
              tooltipPosition="left"
              toggle={
                <NewButton>
                  <PlusIcon
                    color={colors.gray}
                    height={12}
                    style={{ transform: "translateY(1px)" }}
                  />
                </NewButton>
              }
            >
              <PopupButton
                href={ownerPath(owner, `group/new`)}
                icon={<UsersIcon size={16} color={colors.nearBlack} />}
              >
                New group
              </PopupButton>
            </TogglePopup>
          }
        >
          {admin && (
            <>
              {groupsQuery.isSuccess && rootGroups.length === 0 && (
                <EmptySection title={"Add a group"} />
              )}
              {rootGroups?.map((group, i) => GroupComponent(group, 0))}
            </>
          )}
          {groupAdmin && !admin && (
            <GroupItemsWrapper>
              {groupItemsQuery.data?.length > 0 && groupItemsQuery.data}
              {groupItemsQuery.data?.length === 0 && (
                <EmptySection title={"Add a class/cohort"} />
              )}
            </GroupItemsWrapper>
          )}
        </SideBarSection>
      )}
    </>
  );
};

export default Groups;
