import React from "react";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import Link from "next/link";
import { useRouter } from "next/router";
import CaretIcon from "@/components/icons/CaretIcon";
import { MOBILE_WIDTH, TABLET_WIDTH } from "@/lib/constants";

const Wrapper = styled.div<{ inverse: boolean }>`
  margin: 0 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  z-index: 98;
`;

const Badge = styled.div`
  padding: 3px 5px;
  border-radius: 10px;
  background-color: ${colors.nearBlack};
  color: ${colors.white};
  position: absolute;
  right: 100%;
  margin-right: 10px;
  font-size: 12px;
  pointer-events: none;
  white-space: nowrap;
  opacity: 0;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  opacity: 0;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  transform: translateX(10px);
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
  }
`;

const DropdownLink = styled.div<{
  comingSoon?: boolean;
}>`
  text-decoration: none;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 400;
  color: ${colors.nearBlack};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  width: 100%;
  &:hover {
    background-color: ${colors.nearWhite};
    cursor: ${(props) => (props.comingSoon ? "default" : "pointer")};
  }
  &:hover ${Badge} {
    opacity: 1;
    transform: translateX(0px);
  }
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    width: auto;
  }
`;

const Dropdown = styled.div<{ side?: "left" | "right"; inverse?: boolean }>`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  ${(props) => (props.inverse ? "border-radius: 10px;" : "")}
  border: 1px solid ${colors.lightGray};
  padding: 10px 0px;
  pointer-events: none;
  opacity: 0;
  background-color: ${colors.white};
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 59px;
  ${(props) => (props.side === "left" ? "right: -20px;" : "left: -20px;")}
  z-index: 102;
  overflow: visible;
  transform: scale(0.8);
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 20px 0;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    border-radius: 10px;
  }
`;

const Tab = styled.a<{ inverse: boolean; clickable: boolean }>`
  display: inline-block;
  text-decoration: none;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  line-height: 120%;
  font-weight: 400;
  position: relative;
  flex-shrink: 0;
  margin: 0 10px;
  color: ${(props) => (props.inverse ? colors.lightGray : colors.darkGray)};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:hover {
    color: ${(props) => (props.inverse ? colors.white : colors.nearBlack)};
    cursor: ${(props) => (props.clickable ? "pointer" : "default")};
  }

  &:hover ${Dropdown} {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0) scale(1);
  }
`;

export type NavLink = {
  name: string;
  title?: string;
  path?: string;
  url?: string;
  onClick?: () => void;
  icon?: React.ReactElement;
  dropdown?: NavLink[];
  comingSoon?: boolean;
  style?: object;
  side?: "left" | "right";
  newTab?: boolean;
};

type Props = {
  inverse?: boolean;
  style?: object;
  tabStyle?: object;
  links?: (NavLink | React.ReactElement)[];
};

const NavLinks: React.FC<Props> = (props) => {
  const { links, inverse, style, tabStyle } = props;
  const router = useRouter();
  let url = router.asPath;
  if (url.includes("?")) url = url.split("?")[0];

  const renderTab = (tab: NavLink, index: number) => {
    const TabComponent = (
      <Tab
        key={`tab-${index}`}
        inverse={inverse}
        style={{ ...tab.style, ...tabStyle }}
        clickable={!!tab.path || !!tab.onClick || !!tab.url}
        target={tab.newTab ? "_blank" : undefined}
        href={tab.url || undefined}
      >
        {!!tab.icon && tab.icon}
        {!!tab.title && tab.title}
        {tab.dropdown && (
          <>
            {!tab.icon && (
              <CaretIcon
                color={inverse ? colors.nearWhite : colors.darkGray}
                style={{
                  transform: "rotate(90deg)",
                  marginLeft: 8,
                }}
              />
            )}
            <Dropdown inverse={inverse} side={tab.side}>
              {tab.dropdown.map((link, j) => {
                const LinkComponent = (
                  <DropdownLink
                    key={`dropdown-link-${j}`}
                    comingSoon={!!link.comingSoon}
                  >
                    {link.title}
                    {link.comingSoon && <Badge>Coming Soon</Badge>}
                  </DropdownLink>
                );
                return link.path && !link.comingSoon ? (
                  <Link
                    key={`tab-${tab.name}-link-${link.name}`}
                    href={link.path}
                    passHref={true}
                  >
                    {LinkComponent}
                  </Link>
                ) : (
                  <div
                    style={{ width: "100%" }}
                    key={`tab-${tab.name}-link-${link.name}`}
                  >
                    {LinkComponent}
                  </div>
                );
              })}
            </Dropdown>
          </>
        )}
      </Tab>
    );
    return tab.path ? (
      <Link key={`tab-${tab.name}`} href={tab.path || "#"} passHref={true}>
        {TabComponent}
      </Link>
    ) : (
      TabComponent
    );
  };

  return (
    <>
      <Wrapper inverse={inverse} style={style}>
        {links &&
          links.map((link, i) =>
            (link as NavLink).name ? renderTab(link as NavLink, i) : link
          )}
      </Wrapper>
    </>
  );
};

export default NavLinks;
