import { firestore } from "@/firebase";
import { Org } from "@/models/org";
import { Program } from "@/models/program";
import { User } from "@/models/user";
import { ProgramVersion } from "@/models/version";
import * as Sentry from "@sentry/nextjs";
import getVersion from "../version/getVersion";

// gets program by id
const getProgram =
  (programId: string, admin?: boolean) => async (): Promise<Program> => {
    try {
      if (!programId) return null;
      const programDoc = await firestore
        .collection("programs")
        .doc(programId)
        .get();
      const currentProgram = programDoc.data() as Program;

      const version = await getVersion(currentProgram.orgId)();

      if (programId) {
        // if not admin, pull latest version of program
        if (!admin || version?.explicit) {
          const latestProgramDoc = await firestore
            .collection("programs")
            .doc(programId)
            .collection("versions")
            .doc(
              version?.latest
                ? "latest"
                : `${version.majorVersion}.${version.minorVersion}`
            )
            .get();
          if (!latestProgramDoc.exists) return currentProgram;
          const latestProgramVersion =
            latestProgramDoc.data() as ProgramVersion;
          return latestProgramVersion.program;
        } else {
          // if admin, pull current version of program
          return currentProgram;
        }
      } else return null;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  };

export default getProgram;
