import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  height?: number;
  color?: string;
  style?: Record<string, unknown>;
  onClick?: () => void;
};
const SearchIcon: React.FC<Props> = (props) => {
  const width = props.height ? String((props.height * 18) / 16) : "18";
  const height = props.height ? String(props.height) : "16";

  return (
    <div style={props.style} onClick={props.onClick}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 18 16`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transition: "all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99)",
        }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7899 6.79975C11.7899 9.14687 9.84456 11.0496 7.44486 11.0496C5.04516 11.0496 3.09982 9.14687 3.09982 6.79975C3.09982 4.45262 5.04516 2.54991 7.44486 2.54991C9.84456 2.54991 11.7899 4.45262 11.7899 6.79975ZM11.9071 12.0142C10.6996 13.0037 9.14339 13.5995 7.44486 13.5995C3.60534 13.5995 0.492798 10.5551 0.492798 6.79975C0.492798 3.04435 3.60534 0 7.44486 0C11.2844 0 14.3969 3.04435 14.3969 6.79975C14.3969 7.97244 14.0934 9.0758 13.559 10.0388C13.6032 10.0721 13.6457 10.1085 13.6861 10.1481L17.0567 13.4448C17.5658 13.9427 17.5658 14.75 17.0567 15.2479C16.5477 15.7458 15.7223 15.7458 15.2133 15.2479L11.9071 12.0142Z"
          fill={props.color || colors.primary}
        />
      </svg>
    </div>
  );
};
SearchIcon.defaultProps = {
  style: {},
};

export default SearchIcon;
