import React from "react";
import { colors } from "@/lib/styles";
import styled, { css, CSSObject } from "styled-components";
import Link from "next/link";
import { MOBILE_WIDTH, TABLET_WIDTH } from "@/lib/constants";

const LogoText = styled.a<{
  tabletStyle: CSSObject;
  mobileStyle: CSSObject;
  color: string;
  hoverColor?: string;
}>`
  font-weight: 700;
  text-decoration: none;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  color: ${(props) => props.color};
  &:hover {
    color: ${(props) => props.hoverColor || props.color};
    rect {
      fill: ${(props) => props.hoverColor || props.color};
    }
  }
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    ${(props) => props.tabletStyle && css(props.tabletStyle)}
  }
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    ${(props) => props.mobileStyle && css(props.mobileStyle)}
  }
`;

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  tabletStyle?: CSSObject;
  mobileStyle?: CSSObject;
  color?: string;
  hoverColor?: string;
  href?: string;
  text?: string;
  target?: "_blank";
  noIcon?: boolean;
};
const Logo: React.FC<Props> = (props) => {
  return (
    <Link href={props.href || "/"} passHref>
      <LogoText
        style={{ ...props.style, fontSize: props.size }}
        tabletStyle={props.tabletStyle}
        mobileStyle={props.mobileStyle}
        color={props.color}
        hoverColor={props.hoverColor}
        target={props.target}
      >
        {!props.noIcon && (
          <svg
            width={String((props.size * 197) / 316)}
            height={`${props.size}`}
            viewBox="0 0 197 316"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              marginRight: 6,
              transform: `translateY(${props.size * 0.12}px)`,
              transition: "all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99)",
            }}
          >
            <rect
              x="122.001"
              width="86.269"
              height="172.535"
              rx="10"
              transform="rotate(45 122.001 0)"
              fill={props.color}
            />
            <rect
              x="135.469"
              y="132.431"
              width="86.269"
              height="172.535"
              rx="10"
              transform="rotate(45 135.469 132.431)"
              fill={props.color}
            />
          </svg>
        )}
        Steppable
      </LogoText>
    </Link>
  );
};
Logo.defaultProps = {
  color: colors.white,
  size: 21,
};

export default Logo;
