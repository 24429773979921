import React from "react";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import { MOBILE_WIDTH } from "@/lib/constants";
import CheckIcon from "@/components/icons/CheckIcon";
import { Description, Title, Top } from "./Input";

/*
Select Input
Select from a number of options
*/

const Error = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${colors.red};
  margin-top: 5px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: auto;
  padding: 20px 0 15px;
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Options = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 5px 0 0;
`;

const Option = styled.div<{ selected: boolean }>`
  padding: 10px ${(props) => (props.selected ? 35 : 12)}px 10px 12px;
  border-radius: 6px;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid
    ${(props) => (props.selected ? colors.nearBlack : colors.lightGray)};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  font-size: 16px;
  font-weight: ${(props) => (props.selected ? 500 : 400)};
  color: ${(props) => (props.selected ? colors.nearBlack : colors.darkGray)};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  position: relative;
  &:hover {
    color: ${colors.nearBlack};
    cursor: pointer;
    background-color: ${colors.nearWhite};
  }
  .filter-check path {
    stroke: ${(props) =>
      props.selected ? colors.nearBlack : colors.lightGray};
  }
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 5px;
`;

export type SelectInputProps = Partial<React.HTMLProps<HTMLSelectElement>> & {
  title?: string;
  value: any;
  onChange: ({ target }: { target: { name: string; value: any } }) => void;
  error?: string;
  style?: object;
  inputStyle?: Record<string, unknown>;
  titleStyle?: Record<string, unknown>;
  descriptionStyle?: Record<string, unknown>;
  description?: string;
  icon?: React.ReactElement;
  options: {
    title: string;
    value: any;
    icon?: React.ReactElement;
  }[];
  placeholder?: string;
  noPlaceholder?: boolean;
};
const SelectInput: React.FC<SelectInputProps> = (props) => {
  const {
    name,
    value,
    onChange,
    error,
    title,
    options,
    placeholder,
    icon,
    inputStyle,
    titleStyle,
    description,
    descriptionStyle,
    style,
  } = props;

  return (
    <Wrapper style={style}>
      {title && (
        <Top>
          <Title style={titleStyle}>{title}</Title>
          {description && (
            <Description style={descriptionStyle}>{description}</Description>
          )}
        </Top>
      )}

      <Options>
        {options?.map((option, i) => (
          <Option
            key={`option-${name}-${i}`}
            selected={value === option.value}
            onClick={() => onChange({ target: { name, value: option.value } })}
          >
            {option.icon && <IconWrapper>{option.icon}</IconWrapper>}
            {option.title}
            {value === option.value && (
              <CheckIcon
                className="select-check"
                height={14}
                color={colors.nearBlack}
                style={{
                  marginTop: 2,
                  marginLeft: "auto",
                  paddingLeft: 20,
                  position: "absolute",
                  right: 12,
                }}
              />
            )}
          </Option>
        ))}
      </Options>

      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};
SelectInput.defaultProps = {
  style: {},
};

export default SelectInput;
