import { colors } from "@/lib/styles";

import styled from "styled-components";
import { useQuery } from "react-query";
import Link from "next/link";
import Image from "next/image";
import { ownerTitle, ownerType } from "@/lib/owner";
import useUid from "@/hooks/useUid";
import { Org } from "@/models/org";
import getMyOrgs from "@/db/org/getMyOrgs";
import { useEffect, useState } from "react";
import useUserImage from "@/hooks/useUserImage";
import useOwner from "@/hooks/useOwner";
import SwitcherIcon from "@/components/icons/SwitcherIcon";
import { User } from "@/models/user";
import useUserName from "@/hooks/useUserName";
import EmptyIcon from "@/components/universal/EmptyIcon";
import useAdmin from "@/hooks/useAdmin";
import PlusIcon from "@/components/icons/PlusIcon";
import { ownerPath } from "@/lib/path";
import useUserEmail from "@/hooks/useUserEmail";

const Wrapper = styled.div<{ open: boolean; admin: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 10px 20px 0;
  position: sticky;
  top: 0;
  z-index: 201;
  background-color: ${colors.white};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      !props.admin
        ? colors.white
        : props.open
        ? colors.white
        : colors.nearWhite};
  }
`;

const ImageWrapper = styled.div<{ round: boolean }>`
  overflow: hidden;
  border-radius: ${({ round }) => (round ? "50%" : "4px")};
  width: 20px;
  height: 20px;
  margin-right: 10px;
  overflow: hidden;
`;

const Title = styled.h2<{ active?: boolean }>`
  font-size: 16px;
  font-weight: ${(props) => (props.active ? 600 : 500)};
  color: ${colors.nearBlack};
  flex: 1;
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Switcher = styled.div<{ open: boolean }>`
  position: absolute;
  background-color: ${colors.white};
  top: 4px;
  left: 4px;
  right: 4px;
  padding: 5px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid ${colors.lightGray};
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 20px 0;
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  pointer-events: ${({ open }) => (open ? "auto" : "none")};
`;

const SwitcherLine = styled.a<{ gray?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  padding: 14px 10px;
  text-decoration: none;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  h2 {
    color: ${(props) => (props.gray ? colors.darkGray : colors.nearBlack)};
  }
  &:hover h2 {
    color: ${colors.nearBlack};
  }
  &:hover path {
    stroke: ${colors.nearBlack};
  }
  &:hover {
    background-color: ${colors.nearWhite};
    color: ${colors.nearBlack};
  }
`;

type Props = {};

const WorkspaceSwitcher: React.FC<Props> = (props) => {
  const uid = useUid();

  const [open, setOpen] = useState(false);

  const userName = useUserName();
  const userEmail = useUserEmail();
  const userImage = useUserImage();
  const owner = useOwner();
  const admin = useAdmin();

  useEffect(() => {
    setOpen(false);
  }, [owner]);

  const myOrgsQuery = useQuery<Org[]>(["orgs", uid], getMyOrgs(uid), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  return (
    <>
      <Wrapper
        open={open}
        admin={admin}
        onClick={admin ? () => setOpen(true) : undefined}
        onMouseLeave={admin ? () => setOpen(false) : undefined}
      >
        {owner && (
          <>
            {owner.image && (
              <ImageWrapper round={!!(owner as User).name}>
                <Image src={owner.image} width={20} height={20} />
              </ImageWrapper>
            )}
            {!owner.image && (
              <EmptyIcon
                title={ownerTitle(owner) || userName || userEmail}
                size={20}
                style={{
                  marginRight: 10,
                  borderRadius: ownerType(owner) === "user" ? "50%" : "4px",
                }}
              />
            )}
            {ownerType(owner) === "org" && (
              <Title active>{(owner as Org).title}</Title>
            )}
            {admin && (
              <SwitcherIcon
                style={{
                  transform: "translateY(2px)",
                  position: "absolute",
                  right: 10,
                }}
                size={18}
              />
            )}
          </>
        )}
        <Switcher open={open}>
          <Link href={ownerPath(owner, "")} passHref>
            <SwitcherLine key={`switcher-line-active`}>
              {owner?.image && (
                <ImageWrapper round={ownerType(owner) === "user"}>
                  <Image src={owner.image} width={20} height={20} />
                </ImageWrapper>
              )}
              {!owner?.image && (
                <EmptyIcon
                  title={ownerTitle(owner) || userName || userEmail}
                  size={20}
                  style={{
                    marginRight: 10,
                    borderRadius: ownerType(owner) === "user" ? "50%" : "4px",
                  }}
                />
              )}
              <Title>{ownerTitle(owner) || "Personal"}</Title>
            </SwitcherLine>
          </Link>
          {myOrgsQuery.data &&
            myOrgsQuery.data.length > 0 &&
            myOrgsQuery.data.map((org, index) =>
              org.id !== owner?.id ? (
                <Link
                  key={`switcher-line-${index}`}
                  href={ownerPath(org, "")}
                  passHref
                >
                  <SwitcherLine>
                    {org.image && (
                      <ImageWrapper round={false}>
                        <Image src={org.image} width={20} height={20} />
                      </ImageWrapper>
                    )}
                    {!org.image && (
                      <EmptyIcon
                        title={org.title}
                        size={20}
                        style={{ marginRight: 10 }}
                      />
                    )}
                    {org.title && <Title>{org.title}</Title>}
                  </SwitcherLine>
                </Link>
              ) : null
            )}
          <Link href={`/new`} passHref>
            <SwitcherLine gray>
              <PlusIcon
                color={colors.darkGray}
                height={14}
                style={{ marginRight: 10, padding: 3 }}
              />
              <Title>New workspace</Title>
            </SwitcherLine>
          </Link>
        </Switcher>
      </Wrapper>
    </>
  );
};

export default WorkspaceSwitcher;
