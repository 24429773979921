import { auth } from "@/firebase";
import axios, { AxiosRequestConfig } from "axios";

export default function api(
  username?: string,
  password?: string,
  extraConfig?: Partial<AxiosRequestConfig>
) {
  const insertObj: { auth?: { username: string; password: string } } = {};
  if (username && password) {
    insertObj.auth = {
      username,
      password,
    };
  }

  const baseURL =
    process.env.NODE_ENV === "development" ||
    ["steppable.dev", "steppable.com"].includes(window.location.hostname)
      ? "/api/"
      : window.location.hostname.includes("steppable.dev")
      ? "https://steppable.vercel.app/api/"
      : "https://steppable.vercel.app/api/";

  return axios.create({
    baseURL,
    timeout: 8000,
    ...insertObj,
    ...extraConfig,
  });
}

export const idToken = async (): Promise<string | null> => {
  const currentUser = auth.currentUser;
  if (currentUser && typeof currentUser.getIdToken === "function") {
    const token = await auth.currentUser?.getIdToken(true);
    if (token) return token;
    else return null;
  } else {
    return null;
  }
};
