import styled from "styled-components";
import TopBar, { TopBarType } from "@/components/App/TopBar";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "@/redux";
import { connect, ConnectedProps } from "react-redux";
import React, { useEffect, useState } from "react";
import { userSelectors, WatchUser } from "@/redux/user";
import { WatchAuth } from "@/redux/auth";
import { WatchSession } from "@/redux/session";
import { Program } from "@/models/program";
import { authSelectors } from "@/redux/auth";
import watchProgram from "@/db/program/watchProgram";
import * as Sentry from "@sentry/nextjs";
import ErrorBoundary from "./ErrorBoundary";
import { useRouter } from "next/router";
import { colors } from "@/lib/styles";
import { useMutation } from "react-query";
import getProgram from "@/db/program/getProgram";
import { Owner } from "@/models/owner";
import { ownerAdmin } from "@/lib/owner";
import { SideBar, SideBarType } from "./SideBar";
import { Group } from "@/models/group";
import { TABLET_WIDTH } from "@/lib/constants";
import { Toaster } from "react-hot-toast";
import getGroup from "@/db/group/getGroup";
import watchGroup from "@/db/group/watchGroup";
import watchOwner from "@/db/owner/watchOwner";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { Folder } from "@/models/folder";
import getFolder from "@/db/folder/getFolder";
import watchFolder from "@/db/folder/watchFolder";
import { Session } from "@/models/session";
import watchSession from "@/db/session/watchSession";
import { groupAdmin } from "@/lib/group";

export const OwnerContext = React.createContext(null as Owner | null);
export const ProgramContext = React.createContext(null as Program | null);
export const FolderContext = React.createContext(null as Folder | null);
export const GroupContext = React.createContext(null as Group | null);
export const SelectedGroupContext = React.createContext(null as Group | null);
export const SessionContext = React.createContext(null as Session | null);

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  height: -webkit-fill-available;
  min-height: 100vh;
  width: 100vw;
  position: relative;
`;

const OuterContent = styled.div<{ topBar: TopBarType; sideBar: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-top: ${(props) => (props.topBar ? 60 : 0)}px;
  padding-left: ${(props) => (!!props.sideBar ? 300 : 0)}px;
  flex: 1;
  height: 100%;
  max-height: 100vh;
  min-height: 100vh;
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    padding-top: ${(props) => (props.topBar ? 60 : 0)}px;
    padding-left: 0;
  }
`;

const Content = styled.div`
  display: flex;
  pointer-events: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 100%;
  /* z-index: 10; */
  flex: 1;
  box-sizing: border-box;
  position: relative;
`;

const TopBarMock = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: ${colors.nearWhite};
`;

const SafeHydrate = ({ children }) => {
  return (
    <div suppressHydrationWarning>
      {typeof window === "undefined" ? null : children}
    </div>
  );
};

export type OwnProps = {
  owner?: Owner;
  program?: Program;
  group?: Group;
  selectedGroup?: Group;
  folder?: Folder;
  children: React.ReactElement | React.ReactElement[];
  sessionId?: string;
  topBar?: TopBarType;
  sideBar?: SideBarType;
};
type Props = OwnProps & ConnectedProps<typeof connector>;
const RawApp: React.FC<Props> = (props) => {
  const {
    children,
    sessionId,
    uid,
    topBar,
    sideBar,
    userOwnerships,
    userMemberships,
  } = props;
  const router = useRouter();
  const ownerVal = props.owner;
  const programVal = props.program;
  const folderVal = props.folder;
  const groupVal = props.group;
  const selectedGroupVal = props.selectedGroup;
  const groupId = router.query.group as string;

  const [owner, setOwner] = useState(ownerVal as Owner | null);
  const [folder, setFolder] = useState(folderVal as Folder | null);
  const [program, setProgram] = useState(programVal as Program | null);
  const [group, setGroup] = useState(groupVal as Group | null);
  const [selectedGroup, setSelectedGroup] = useState(
    selectedGroupVal as Group | null
  );
  const [session, setSession] = useState(null as Session | null);

  const refreshProgramMutation = useMutation(
    (data: { programId: string; isAdmin: boolean }) =>
      data.programId ? getProgram(data.programId, data.isAdmin)() : null,
    {
      onSuccess: (data) => {
        if (data) setProgram(data);
      },
    }
  );

  const refreshFolderMutation = useMutation(
    (folderId: string) =>
      folderVal ? getFolder(owner, folderId, ownerAdmin(owner, uid))() : null,
    {
      onSuccess: (data) => {
        if (data) setFolder(data);
      },
    }
  );

  const refreshGroupMutation = useMutation(
    (groupId: string) =>
      ownerVal && groupId ? getGroup(ownerVal?.id, groupId)() : null,
    {
      onSuccess: (data) => {
        if (data) setGroup(data);
      },
    }
  );

  const refreshSelectedGroupMutation = useMutation(
    (groupId: string) =>
      ownerVal && groupId ? getGroup(ownerVal?.id, groupId)() : null,
    {
      onSuccess: (data) => {
        if (data) setSelectedGroup(data);
      },
    }
  );

  // sync owner
  useEffect(() => {
    // watch owner if admin
    if (ownerVal && ownerAdmin(ownerVal, uid)) {
      return watchOwner(ownerVal?.id, setOwner, (error) => {
        Sentry.captureException(error);
      });
    }
  }, [ownerVal, uid]);

  // sync program
  useEffect(() => {
    // watch program if admin
    if (programVal && ownerAdmin(owner, uid)) {
      return watchProgram(programVal?.id, setProgram, (error) => {
        Sentry.captureException(error);
      });
    }
    // get most recent published program if not admin
    else if ((owner && uid && programVal && !ownerAdmin(owner, uid)) || !uid) {
      refreshProgramMutation.mutate({
        programId: programVal?.id,
        isAdmin: ownerAdmin(owner, uid),
      });
    }
    if (!programVal) setProgram(null);
  }, [programVal?.id, owner, uid]);

  // sync folder
  useEffect(() => {
    // watch folder if admin
    if (folderVal && ownerAdmin(owner, uid)) {
      return watchFolder(owner, folderVal?.id, setFolder, (error) => {
        Sentry.captureException(error);
      });
    }
    // get most recent published folder if not admin
    else if ((owner && uid && folderVal && !ownerAdmin(owner, uid)) || !uid) {
      refreshFolderMutation.mutate(folderVal?.id);
    }
    if (!folderVal) setFolder(null);
  }, [folderVal?.id, owner, uid]);

  // sync group
  useEffect(() => {
    if (groupId || groupVal || userOwnerships || userMemberships) {
      let g =
        groupId ||
        groupVal?.id ||
        userOwnerships
          ?.filter((o) => o.includes(`${owner?.id}/`))?.[0]
          ?.split("/")?.[1] ||
        userMemberships
          ?.filter((o) => o.includes(`${owner?.id}/`))?.[0]
          ?.split("/")?.[1];

      // watch group if admin
      const admin = ownerAdmin(owner, uid);
      if (owner && g && admin) {
        return watchGroup(ownerVal?.id, g, setGroup, (error) => {
          Sentry.captureException(error);
        });
      } else if (g && !admin) {
        refreshGroupMutation.mutate(g);
      }
    }
  }, [owner, uid, userOwnerships?.length, userMemberships?.length, groupId]);

  // sync selected group
  useEffect(() => {
    if (groupId || selectedGroupVal?.id) {
      // watch group if admin
      const g = groupId || selectedGroupVal?.id;
      console.log("WATCHING GROUP", g);
      const admin = ownerAdmin(owner, uid);
      if (owner && g && admin) {
        return watchGroup(ownerVal?.id, g, setSelectedGroup, (error) => {
          Sentry.captureException(error);
        });
      } else if (g && !admin) {
        refreshSelectedGroupMutation.mutate(g);
      }
    }
  }, [owner, uid, groupId, selectedGroupVal]);

  // sync auth
  useEffect(() => {
    return props.WatchAuth();
  }, []);

  // sync user
  useEffect(() => {
    if (uid) return props.WatchUser(owner);
  }, [uid]);

  // sync session
  useEffect(() => {
    return watchSession(sessionId, (session) => {
      setSession(session);
    });
  }, [uid, sessionId]);

  // render fallback if still generating page
  if (router.isFallback) {
    return <TopBarMock />;
  }

  const showSideBar =
    sideBar === "app" &&
    (ownerAdmin(owner, uid) || groupAdmin(owner, group, uid));

  return (
    <SafeHydrate>
      <OwnerContext.Provider value={owner}>
        <FolderContext.Provider value={folder}>
          <ProgramContext.Provider value={program}>
            <GroupContext.Provider value={group}>
              <SelectedGroupContext.Provider value={selectedGroup}>
                <SessionContext.Provider value={session}>
                  <DndProvider backend={HTML5Backend}>
                    <Wrapper id="wrapper">
                      <OuterContent topBar={topBar} sideBar={showSideBar}>
                        <Content id="content">{children}</Content>
                      </OuterContent>
                    </Wrapper>
                    {!!showSideBar && <SideBar type={sideBar} />}
                    {!!topBar && <TopBar type={topBar} />}

                    <Toaster
                      position="top-center"
                      gutter={10}
                      containerStyle={{
                        padding: "5px 10px",
                        zIndex: 999,
                      }}
                      toastOptions={{
                        duration: 3000,
                        style: {
                          background: colors.white,
                          border: `1px solid ${colors.lightGray}`,
                          color: colors.nearBlack,
                        },
                      }}
                    />
                    <div id="popup" />
                  </DndProvider>
                </SessionContext.Provider>
              </SelectedGroupContext.Provider>
            </GroupContext.Provider>
          </ProgramContext.Provider>
        </FolderContext.Provider>
      </OwnerContext.Provider>
    </SafeHydrate>
  );
};

const mapState = (state: RootState) => ({
  uid: authSelectors.uid(state),
  userEmail: userSelectors.userEmail(state),
  userOwnerships: userSelectors.userOwnerships(state),
  userMemberships: userSelectors.userMemberships(state),
});
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      WatchUser,
      WatchAuth,
      WatchSession,
    },
    dispatch
  );
const connector = connect(mapState, mapDispatch);

const AppComp = connector(RawApp);

const App = (props: OwnProps) => (
  <ErrorBoundary>
    <AppComp {...props} />
  </ErrorBoundary>
);

export default App;
