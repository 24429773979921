import React, { useState } from "react";
import { colors } from "@/lib/styles";
import styled from "styled-components";
import Link from "next/link";
import TogglePopup from "@/components/universal/TogglePopup";
import MoreIcon from "@/components/icons/MoreIcon";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { useQueryClient } from "react-query";
import { DragItem } from "@/models/dnd";
import moveItem from "@/db/organize/moveItem";
import useOwner from "@/hooks/useOwner";
import useAdmin from "@/hooks/useAdmin";
import { rgba } from "polished";
import { ownerColor } from "@/lib/owner";
import useDragging from "@/hooks/useDragging";
import EmptyIcon from "@/components/universal/EmptyIcon";
import { useRouter } from "next/router";
import LockIcon from "@/components/icons/LockIcon";
import { Program } from "@/models/program";
import useAccess from "@/hooks/useAccess";

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  position: relative;
`;

export const MoreButton = styled.button`
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  outline: none;
  border: 1px solid transparent;
  overflow: hidden;
  position: absolute;
  right: 0;
  width: 20px;
  opacity: 0;
  height: 20px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: ${colors.nearWhite};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  pointer-events: none;
  &:hover {
    background-color: ${colors.lightGray};
    cursor: pointer;
    circle {
      fill: ${colors.darkGray};
    }
  }
`;

const Indicator = styled.div<{ active: boolean }>`
  width: 4px;
  height: 4px;
  margin: 0 9px 0 9px;
  border-radius: 2px;
  flex-shrink: 0;
  background-color: ${(props) =>
    props.active ? colors.darkGray : colors.gray};
`;

const Wrapper = styled.div<{ active: boolean; depth: number; href?: string }>`
  text-decoration: none;
  width: 100%;
  padding: 10px 20px 10px ${(props) => 20 + props.depth * 10}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  background-color: ${(props) =>
    props.active ? colors.nearWhite : "transparent"};
  &:hover {
    background-color: ${(props) =>
      props.href ? colors.nearWhite : "transparent"};
    cursor: ${(props) => (props.active || !props.href ? "default" : "pointer")};
  }
  h5 {
    font-weight: ${(props) => (props.active ? 500 : 400)};
    color: ${(props) => (props.active ? colors.nearBlack : colors.darkGray)};
  }
  &:hover h5 {
    color: ${(props) => (props.href ? colors.nearBlack : colors.darkGray)};
  }
  path,
  circle,
  rect {
    stroke: ${(props) => (props.active ? colors.nearBlack : colors.darkGray)};
  }
  &:hover path,
  &:hover circle,
  &:hover rect {
    stroke: ${colors.nearBlack};
  }
  &:hover ${MoreButton} {
    opacity: 1;
    pointer-events: auto;
  }
  &:hover ${Indicator} {
    background-color: ${colors.darkGray};
  }
`;

const Title = styled.h5`
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
  flex-shrink: 0;
`;

const ActiveIndicator = styled.div<{ active: boolean }>`
  position: absolute;
  right: 0;
  width: 2px;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: ${colors.nearBlack};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  opacity: ${(props) => (props.active ? 1 : 0)};
`;

export const IconWrapper = styled.button`
  width: 20px;
  height: 20px;
  overflow: hidden;
  position: relative;
  outline: none;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  border-radius: 6px;
  margin-right: 5px;
  &:hover {
    background-color: ${(props) =>
      props.disabled ? "transparent" : colors.lightGray};
    ${(props) => (props.disabled ? "" : "pointer-events: cursor;")}
    pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  }
`;

export const Line = styled.div<{
  disabled: boolean;
  isOver: boolean;
  color: string;
  depth: number;
}>`
  width: 100%;
  padding: 8px 0 8px
    ${(props) => (props.depth === 0 ? 0 : 20 + (props.depth - 1) * 10)}px;
  border-radius: 6px;
  position: absolute;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  top: -10px;
  z-index: ${(props) => (props.disabled ? -1 : 10)};
  div {
    background-color: ${(props) =>
      props.isOver ? rgba(props.color, 0.2) : "transparent"};
    height: 5px;
    width: 100%;
  }
`;

export type Props = {
  id: string;
  type: string;
  peerType?: string | string[];
  icon?: React.ReactElement;
  parent: string;
  position: number;
  title: string;
  active: (path: string) => boolean;
  href?: string;
  action?: React.ReactElement;
  style?: object;
  depth?: number;
  iconStyle?: object;
  disabled?: boolean;
  moreLabel?: string;
  more?: React.ReactElement | React.ReactElement[];
  noIcon?: boolean;
  fixedIcon?: boolean;
  item?: Program;
};
const SideBarItem: React.FC<Props> = ({
  peerType,
  moreLabel,
  type,
  id,
  parent,
  position,
  icon,
  title,
  active,
  action,
  href,
  style,
  iconStyle,
  depth,
  disabled,
  more,
  noIcon,
  fixedIcon,
  item,
}) => {
  const owner = useOwner();
  const admin = useAdmin();
  const router = useRouter();
  const access = useAccess(item);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type,
      item: {
        parent,
        position,
        id,
        type,
      },
      canDrag: !!admin,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, parent, position, type]
  );

  const queryClient = useQueryClient();
  const dragging = useDragging();

  // line above group line for reordering
  const lineDrop = useDrop(
    () => ({
      accept: peerType || [],
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
      drop(item: DragItem, monitor: DropTargetMonitor) {
        if (monitor.isOver({ shallow: true }))
          moveItem(owner, item, { parent, position: position - 0.5 })(() => {
            queryClient.invalidateQueries(["groups", owner.id]);
            queryClient.invalidateQueries(["folders", owner.id]);
            queryClient.invalidateQueries(["programs", owner.id, admin]);
            queryClient.invalidateQueries("sidebar-items");
          });
      },
    }),
    [owner, parent, position, peerType]
  );
  const isOverLine = lineDrop[0].isOver;
  const lineDropRef = lineDrop[1];

  const [focused, setFocused] = useState(false);
  const highlighted = active(router.asPath) || focused;
  const ItemComponent = (
    <OuterWrapper>
      {peerType && (
        <Line
          disabled={!dragging}
          color={ownerColor(owner)}
          isOver={isOverLine}
          ref={lineDropRef}
          depth={0}
        >
          <div />
        </Line>
      )}
      <Wrapper
        ref={drag}
        href={href}
        depth={depth}
        active={highlighted}
        style={style}
      >
        <Indicator active={active(router.asPath)} />
        {fixedIcon && icon && !noIcon && (
          <IconWrapper disabled={!admin || disabled} style={iconStyle}>
            {icon}
            {!icon && <EmptyIcon size={16} title={title} />}
          </IconWrapper>
        )}
        {!noIcon && !fixedIcon && (
          <TogglePopup
            popupId={`choose-icon-empty`}
            tooltip="Choose icon"
            tooltipPosition="bottom"
            onToggle={(open) => setFocused(open)}
            disabled={!admin}
            toggle={
              icon ? (
                <IconWrapper disabled={!admin || disabled} style={iconStyle}>
                  {icon}
                </IconWrapper>
              ) : (
                <IconWrapper style={iconStyle}>
                  <EmptyIcon size={16} title={title} />
                </IconWrapper>
              )
            }
          >
            {/* choose icon popup */}
          </TogglePopup>
        )}
        <Title>{title}</Title>
        {!disabled && more && (
          <TogglePopup
            popupId={`choose-icon-${id}`}
            onToggle={(open) => setFocused(open)}
            tooltip={moreLabel}
            tooltipPosition="bottom"
            toggle={
              <MoreButton>
                <MoreIcon size={4} color={colors.gray} />
              </MoreButton>
            }
          >
            {more}
          </TogglePopup>
        )}
        {item && (
          <>
            {access && action}
            {!access && <LockIcon color={colors.gray} size={12} />}
            <ActiveIndicator active={active(router.asPath)} />
          </>
        )}
      </Wrapper>
    </OuterWrapper>
  );

  return href && !disabled ? (
    <Link href={href} passHref>
      {ItemComponent}
    </Link>
  ) : (
    ItemComponent
  );
};

export default SideBarItem;
