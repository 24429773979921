import { Folder } from "@/models/folder";
import { Program } from "@/models/program";
import { useRouter } from "next/router";
import useAdmin from "./useAdmin";
import useGroup from "./useGroup";

const useAccess = (item: Program | Folder): boolean => {
  const admin = useAdmin();
  const router = useRouter();
  if (admin) return true;
  const group = useGroup();
  const hasKey = router.query?.key === item?.id;
  if (hasKey) return true;
  if (item) {
    if (!!item.licenses && item.licenses.length > 0) {
      for (const license of item.licenses) {
        if (
          group?.licenses?.filter((value) => item?.licenses?.includes(value))
            ?.length > 0
        )
          return true;
      }
      return false;
    } else return true;
  }
};
export default useAccess;
