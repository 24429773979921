import styled from "styled-components";
import Link from "next/link";
import { colors } from "@/lib/styles";
import { fadeIn } from "@/lib/animations";
import { Program } from "@/models/program";
import { ownerPath } from "@/lib/path";
import Image from "next/image";
import { MOBILE_WIDTH, TABLET_WIDTH } from "@/lib/constants";
import { rgba } from "polished";
import useOwnerColor from "@/hooks/useOwnerColor";
import TagComponent from "../universal/Tags/Tag";
import { useQuery } from "react-query";
import { Tag } from "@/models/tag";
import getTags from "@/db/tag/getTags";
import { find } from "lodash";
import useOwner from "@/hooks/useOwner";
import BuildIcon from "../icons/BuildIcon";

const Wrapper = styled.a`
  height: 200px;
  margin: 10px;
  border: 1px solid ${colors.lightGray};
  flex-basis: 50%;
  /* flex: 1; */
  flex-shrink: 0;
  max-width: calc(50% - 20px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  text-decoration: none;
  position: relative;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  ${fadeIn}
  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.08) 0 1px 5px 0;
  }
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    max-width: calc(50% - 20px);
    flex-basis: 50%;
  }
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    height: auto;
    max-width: calc(100% - 20px);
    flex-basis: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px;
`;

const Title = styled.h3`
  font-size: 21px;
  font-weight: 500;
  color: ${colors.nearBlack};
  flex: 1;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 21px;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 0 0;
  margin-top: auto;
`;

const CoverImage = styled.div<{ color: string; empty?: boolean }>`
  width: 300px;
  flex-shrink: 0;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 1px solid ${colors.lightGray};
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.empty ? rgba(props.color, 0.1) : colors.nearWhite};
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    height: 150px;
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-right: 0;
    border-bottom: 1px solid ${colors.lightGray};
  }
`;

type Props = {
  program: Program;
  style?: object;
};
export const ProgramResult: React.FC<Props> = ({ program, style }) => {
  const owner = useOwner();
  const ownerColor = useOwnerColor();
  const link = ownerPath(owner, [...program.slugPaths, program.slug].join("/"));

  const tagsQuery = useQuery<Tag[]>(["tags", owner.id], getTags(owner));

  return (
    <Link href={link} passHref>
      <Wrapper style={style}>
        <CoverImage color={ownerColor} empty={!program.coverImage}>
          {!!program.coverImage && (
            <Image layout="fill" objectFit="cover" src={program.coverImage} />
          )}
          {!program.coverImage && (
            <BuildIcon
              size={40}
              color={rgba(ownerColor, 0.5)}
              strokeWidth={3}
            />
          )}
        </CoverImage>
        <Content>
          <Title>{program.title}</Title>
          {!tagsQuery.isLoading && program.tags && (
            <Bottom>
              {program.tags.map((tag, i) => (
                <TagComponent
                  key={`tag-${i}`}
                  tag={find(tagsQuery.data, { id: tag })}
                  style={{ marginRight: 10 }}
                />
              ))}
            </Bottom>
          )}
        </Content>
      </Wrapper>
    </Link>
  );
};

export default ProgramResult;
