import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
  fill?: string;
  svgStyle?: Record<string, unknown>;
  strokeWidth?: number;
};
const PlayIcon: React.FC<Props> = (props) => {
  const height = props.size ? String(props.size) : "19";
  const width = props.size ? String((props.size / 19) * 16) : "16";

  return (
    <span style={props.style}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 2.20923C1 1.41806 1.87525 0.940222 2.54076 1.36805L13.6915 8.53638C14.3038 8.93002 14.3038 9.82511 13.6915 10.2187L2.54076 17.3871C1.87525 17.8149 1 17.3371 1 16.5459V2.20923Z"
          stroke={props.color}
          fill={props.fill || "undefined"}
          strokeWidth={props.strokeWidth ? String(props.strokeWidth) : "2"}
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
PlayIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default PlayIcon;
