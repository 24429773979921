import { firestore } from "@/firebase";
import firebase from "firebase";
import { Org } from "@/models/org";
import { Program, Step } from "@/models/program";
import * as Sentry from "@sentry/nextjs";
import moment from "moment";

const updateProgram = async (data: {
  programId: string;
  programUpdate: Partial<Program>;
}): Promise<void> => {
  try {
    const { programId, programUpdate } = data;
    const programDoc = await firestore
      .collection("programs")
      .doc(programId)
      .get();
    if (programDoc.exists) {
      await programDoc.ref.update({
        ...programUpdate,
        updatedTime: moment().unix(),
      });
      await firestore
        .collection("orgs")
        .doc((programDoc.data() as Program).orgId)
        .collection("versions")
        .doc("next")
        .set(
          {
            changed: firebase.firestore.FieldValue.arrayUnion(programId),
          },
          { merge: true }
        );
    } else throw new Error("Could not retrieve program");
  } catch (error) {
    Sentry.captureException(error);
  }
};

export default updateProgram;
