import { firestore } from "@/firebase";
import { ownerType } from "@/lib/owner";
import { Owner } from "@/models/owner";

// takes programs or people workspace, path, and cleans positions (recounts)

const resetPositions = async (
  type: "library" | "users" | "tags" | "licenses",
  owner: Owner,
  parent?: string
) => {
  switch (type) {
    case "library":
      // get programs and folders at path
      const programsQuery = await firestore
        .collection("programs")
        .where(ownerType(owner) === "org" ? "orgId" : "userId", "==", owner.id)
        .where("parent", "==", parent)
        .get();
      const programDocs = programsQuery.docs;
      const foldersQuery = await firestore
        .collection(ownerType(owner) === "org" ? "orgs" : "users")
        .doc(owner.id)
        .collection("folders")
        .where("parent", "==", parent)
        .get();
      const folderDocs = foldersQuery.docs;
      // sort programs and folders by position
      const sortedDocs = [...programDocs, ...folderDocs].sort(
        (a, b) => a.data().position - b.data().position
      );
      // reset positions
      for (let i = 0; i < sortedDocs.length; i++) {
        await sortedDocs[i].ref.update({ position: i });
      }
      break;
    case "users":
      // get groups at path
      const groupsQuery = await firestore
        .collection(ownerType(owner) === "org" ? "orgs" : "users")
        .doc(owner.id)
        .collection("groups")
        .where("parent", "==", parent)
        .orderBy("position", "asc")
        .get();
      // reset positions
      for (let i = 0; i < groupsQuery.docs.length; i++) {
        await groupsQuery.docs[i].ref.update({ position: i });
      }
      break;
    case "tags":
      // get org tags
      const tagsQuery = await firestore
        .collection("orgs")
        .doc(owner.id)
        .collection("tags")
        .orderBy("position", "asc")
        .get();
      // reset positions
      for (let i = 0; i < tagsQuery.docs.length; i++) {
        await tagsQuery.docs[i].ref.update({ position: i });
      }
      break;
    case "licenses":
      // get org tags
      const licensesQuery = await firestore
        .collection("orgs")
        .doc(owner.id)
        .collection("licenses")
        .orderBy("position", "asc")
        .get();
      // reset positions
      for (let i = 0; i < licensesQuery.docs.length; i++) {
        await licensesQuery.docs[i].ref.update({ position: i });
      }
      break;
  }
};

export default resetPositions;
