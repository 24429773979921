import { firestore } from "@/firebase";
import { ownerCollection, ownerType } from "@/lib/owner";
import { Group } from "@/models/group";
import { Owner } from "@/models/owner";
import { Program } from "@/models/program";
import * as Sentry from "@sentry/nextjs";
import deleteFile from "../utils/deleteFile";
import resetPositions from "../utils/resetPositions";
import getGroup from "./getGroup";
import updateGroup from "./updateGroup";

const archiveGroup = async (owner: Owner, groupId: string): Promise<void> => {
  try {
    // archive group
    await updateGroup({
      orgId: owner.id,
      groupId,
      groupUpdate: { active: false },
    });
    const group = await getGroup(owner.id, groupId)();
    await resetPositions("library", owner, group?.parent);
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};

export default archiveGroup;
