import { css, keyframes } from "styled-components";

const fadeInKeyframe = keyframes`
  from: {
    opacity: 0;
    transform: translate(-5px)
  }
  to {
    opacity: 1;
    transform: translate(0px)
  }
`;

export const fadeIn = css`
  opacity: 0;
  transform: translate(-5px);
  animation: ${fadeInKeyframe} 500ms forwards;
`;

const fadeOutKeyframe = keyframes`
  from: {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const fadeOut = css`
  opacity: 1;
  animation-delay: 3s;
  animation: ${fadeOutKeyframe} 100ms forwards;
`;

const pulseKeyframe = keyframes`
  0% {
    transform: scale(0.93);
    -ms-transform: scale(0.93);
    -moz-transform: scale(0.93);
    -webkit-transform: scale(0.93);
    -o-transform: scale(0.93);
  }
  50% {
    transform: scale(0.98);
    -ms-transform: scale(0.98);
    -moz-transform: scale(0.98);
    -webkit-transform: scale(0.98);
    -o-transform: scale(0.98);
  }
  100% {
    transform: scale(0.93);
    -ms-transform: scale(0.93);
    -moz-transform: scale(0.93);
    -webkit-transform: scale(0.93);
    -o-transform: scale(0.93);
  }
`;

export const pulse = css`
  -webkit-animation: ${pulseKeyframe} 6s ease-in-out infinite;
  -moz-animation: ${pulseKeyframe} 6s ease-in-out infinite;
  -ms-animation: ${pulseKeyframe} 6s ease-in-out infinite;
  -o-animation: ${pulseKeyframe} 6s ease-in-out infinite;
  animation: ${pulseKeyframe} 6s ease-in-out infinite;
`;

const spinKeyframe = keyframes`
  from {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
`;

export const slowSpin = css`
  -webkit-animation: ${spinKeyframe} 100s linear infinite;
  -moz-animation: ${spinKeyframe} 100s linear infinite;
  -ms-animation: ${spinKeyframe} 100s linear infinite;
  -o-animation: ${spinKeyframe} 100s linear infinite;
  animation: ${spinKeyframe} 100s linear infinite;
`;

export const spin = css`
  -webkit-animation: ${spinKeyframe} 12s linear infinite;
  -moz-animation: ${spinKeyframe} 12s linear infinite;
  -ms-animation: ${spinKeyframe} 12s linear infinite;
  -o-animation: ${spinKeyframe} 12s linear infinite;
  animation: ${spinKeyframe} 12s linear infinite;
`;
