import { firestore } from "@/firebase";
import { Program } from "@/models/program";

const watchProgram = (
  programId: string,
  successCallback: (program: Program) => void,
  errorCallback: (error: Error) => void
): (() => void) => {
  return firestore
    .collection("programs")
    .doc(programId)
    .onSnapshot((docSnap) => {
      if (docSnap.exists) successCallback(docSnap.data() as Program);
    }, errorCallback);
};

export default watchProgram;
