import Button from "@/components/universal/Button/Button";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import { NARROW_MAX_WIDTH } from "@/lib/constants";
import { useRouter } from "next/router";
import { useMutation, useQueryClient } from "react-query";
import { ownerColor } from "@/lib/owner";
import useOwner from "@/hooks/useOwner";
import { Formik } from "formik";
import Title from "@/components/universal/Text/Title";
import Loading from "@/components/universal/Loading";
import useUid from "@/hooks/useUid";
import useAdmin from "@/hooks/useAdmin";
import VersionDescriptionField from "@/components/universal/Field/VersionDescriptionField";
import VersionTypeField from "@/components/universal/Field/VersionTypeField";
import publishVersion from "@/db/version/publishVersion";
import useVersion from "@/hooks/useVersion";
import PublishIcon from "@/components/icons/PublishIcon";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: center;
  height: 100%;
  max-width: ${NARROW_MAX_WIDTH}px;
  margin: auto auto;
  position: relative;
  padding: 10px;
`;

const Form = styled.form`
  width: 100%;
`;

type Props = {};

export const PublishVersion: React.FC<Props> = (props) => {
  const uid = useUid();
  const router = useRouter();
  const admin = useAdmin();
  const latestVersion = useVersion(true);

  const queryClient = useQueryClient();

  const publishVersionMutation = useMutation(publishVersion, {
    onSuccess: () => {
      queryClient.invalidateQueries(["version", owner?.id]);
      queryClient.invalidateQueries(["versions", owner?.id]);
      // router.reload();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const owner = useOwner();

  return (
    <Wrapper>
      <Title style={{ fontSize: 28, margin: 0, marginBottom: 0 }}>
        Publish version
      </Title>
      <Formik
        initialValues={{ type: "minor" as "minor" | "major", description: "" }}
        validate={async (values) => {
          const errors: { [fieldName: string]: string } = {};
          if (!values.type) {
            errors.type = "Required";
          } else if (!values.description) {
            errors.title = "Must include a description";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          publishVersionMutation.mutate(
            {
              type: values.type,
              description: values.description,
              orgId: owner?.id,
              uid,
            },
            {
              onSuccess: () => {
                setSubmitting(false);
              },
            }
          );
        }}
      >
        {({ handleSubmit, isSubmitting, isValid, values }) => (
          <Form onSubmit={handleSubmit}>
            <VersionTypeField />
            <VersionDescriptionField />

            <Button
              style={{
                marginTop: 20,
                height: 60,
                fontSize: 21,
                marginBottom: 0,
                width: "100%",
              }}
              backgroundColor={
                isSubmitting || !isValid ? colors.nearWhite : ownerColor(owner)
              }
              color={isSubmitting || !isValid ? colors.nearBlack : colors.white}
              submit
              disabled={isSubmitting || !isValid}
            >
              Publish version{" "}
              {values.type === "major"
                ? latestVersion?.majorVersion + 1
                : latestVersion?.majorVersion}
              .{values.type === "major" ? 0 : latestVersion?.minorVersion + 1}
              {!isSubmitting && (
                <PublishIcon
                  size={16}
                  style={{
                    marginLeft: 10,
                  }}
                  color={
                    isSubmitting || !isValid ? colors.darkGray : colors.white
                  }
                />
              )}
              {isSubmitting && <Loading size={20} style={{ marginLeft: 10 }} />}
            </Button>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default PublishVersion;
