import React from "react";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import Version from "./Version";
import { useQuery, useQueryClient } from "react-query";
import useOwner from "@/hooks/useOwner";
import Popper from "@/components/universal/Popper";
import getVersions from "@/db/version/getVersions";
import useAdmin from "@/hooks/useAdmin";
import useVersion from "@/hooks/useVersion";
import Button from "@/components/universal/Button/Button";
import { useRouter } from "next/router";
import PublishVersion from "./PublishVersion";
import { ownerPath } from "@/lib/path";
import Breakpoint from "@/components/universal/Breakpoint";
import { MOBILE_WIDTH } from "@/lib/constants";

const VersionLines = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

type Props = {
  style?: Record<string, unknown>;
};

const Versions: React.FC<Props> = (props) => {
  const { style } = props;

  const owner = useOwner();
  const queryClient = useQueryClient();
  const admin = useAdmin();
  const activeVersion = useVersion();
  const router = useRouter();
  const explicitVersion = router.query.version;

  const versionsQuery = useQuery<
    { majorVersion: number; minorVersion: number }[]
  >(["versions", owner?.id], getVersions(owner?.id));

  const versions = versionsQuery.data || [];

  return (
    <Breakpoint from={MOBILE_WIDTH}>
      <Popper
        placement="bottom-start"
        offset={[0, -1]}
        disabled={!admin}
        component={(props) => (
          <Version
            text={
              activeVersion
                ? admin
                  ? explicitVersion || "Next"
                  : `${activeVersion?.majorVersion}.${activeVersion?.minorVersion}`
                : ". . ."
            }
            style={{ marginLeft: 10 }}
            {...props}
            active
            clickable={admin}
          />
        )}
        popStyle={{ paddingBottom: 0 }}
      >
        <VersionLines>
          {admin && (
            <Version
              text={"Next"}
              active={!explicitVersion}
              key={`next-version`}
              style={{
                marginBottom: 10,
              }}
              clickable={!!explicitVersion}
              onClick={() => {
                // remove version from url and reload
                window.location.href = ownerPath(owner, "", null);
              }}
            />
          )}
          {admin && (
            <Popper
              placement="bottom-start"
              offset={[0, -1]}
              disabled={!admin}
              component={(props) => (
                <Button
                  backgroundColor={colors.nearWhite}
                  color={colors.darkGray}
                  hoverColor={colors.nearBlack}
                  style={{
                    fontSize: 12,
                    marginTop: 0,
                    marginBottom: 10,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 8,
                    paddingRight: 8,
                    whiteSpace: "nowrap",
                    borderRadius: 6,
                  }}
                  {...props}
                >
                  Publish version
                </Button>
              )}
            >
              <PublishVersion />
            </Popper>
          )}
          {versionsQuery.data &&
            versionsQuery.data.length > 0 &&
            versions.map((version) => (
              <Version
                onClick={() => {
                  window.location.href = ownerPath(owner, "", {
                    version:
                      (!admin || explicitVersion) &&
                      activeVersion?.majorVersion === version.majorVersion &&
                      activeVersion?.minorVersion === version.minorVersion
                        ? null
                        : `${version?.majorVersion}.${version?.minorVersion}`,
                  });
                }}
                key={`version-${version?.majorVersion}-${version?.minorVersion}`}
                text={`${version?.majorVersion}.${version?.minorVersion}`}
                active={
                  (!admin || explicitVersion) &&
                  activeVersion?.majorVersion === version?.majorVersion &&
                  activeVersion?.minorVersion === version?.minorVersion
                }
                style={{
                  marginBottom: 10,
                }}
                clickable={admin}
              />
            ))}
        </VersionLines>
      </Popper>
    </Breakpoint>
  );
};
Versions.defaultProps = {
  style: {},
};

export default Versions;
