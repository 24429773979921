import { useEffect, useState } from "react";

const useUrl = () => {
  const [url, setUrl] = useState(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setUrl(window.location.href);
    }
  }, [window.location.href]);
  return url;
};
export default useUrl;
