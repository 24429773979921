import React from "react";
import { colors } from "@/lib/styles";
import styled from "styled-components";
import Logo from "@/components/brand/Logo";
import NavLinks from "@/components/App/TopBar/NavLinks";
import BuildIcon from "@/components/icons/BuildIcon";
import Feedback from "./Feedback";
import { HELP_LINK, RESOURCES_LINK } from "./links";
import CaretIcon from "@/components/icons/CaretIcon";
import Button from "@/components/universal/Button/Button";
import { ownerColor } from "@/lib/owner";
import useOwner from "@/hooks/useOwner";
import { rgba } from "polished";
import { ownerPath } from "@/lib/path";
import useProgram from "@/hooks/useProgram";
import { useRouter } from "next/router";
import useUid from "@/hooks/useUid";
import PlayIcon from "@/components/icons/PlayIcon";
import { Org } from "@/models/org";

const Wrapper = styled.div`
  flex: 1;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.lightGray};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

type Props = {};
const BuildTopBar: React.FC<Props> = (props) => {
  const router = useRouter();
  const owner = useOwner();
  const program = useProgram();
  const uid = useUid();

  return (
    <Wrapper>
      <Left>
        <Button
          href={ownerPath(
            owner,
            [...(program?.slugPaths || []), program?.slug].join("/"),
            null
          )}
          color={colors.white}
          backgroundColor={colors.nearBlack}
          hoverBackgroundColor={rgba(colors.nearBlack, 0.9)}
          style={{ height: 60, padding: 20, borderRadius: 0, margin: 0 }}
        >
          <CaretIcon
            color={colors.white}
            size={20}
            style={{
              marginRight: 15,
              transform: "rotate(180deg) translateY(3px)",
            }}
          />
          Exit
        </Button>
        <BuildIcon
          color={colors.nearBlack}
          size={24}
          style={{ marginLeft: 20, transform: "translateY(2px)" }}
        />
        <Logo
          text="Steppable Builder"
          color={colors.nearBlack}
          size={16}
          style={{ marginLeft: 10 }}
          noIcon
        />
      </Left>
      <NavLinks
        links={[
          <Feedback key="topbar-feedback" />,
          HELP_LINK(owner as Org, "left"),
          RESOURCES_LINK(),
          <Button
            key="preview"
            color={colors.white}
            href={ownerPath(owner, `program/${program.id}/preview`, {
              i: router.query.i,
            })}
            hoverBackgroundColor={rgba(ownerColor(owner), 0.9)}
            backgroundColor={ownerColor(owner)}
            style={{
              height: 60,
              padding: "20px 20px",
              borderRadius: 0,
              margin: 0,
              marginLeft: 15,
            }}
          >
            Preview
            <PlayIcon
              color={colors.white}
              fill={colors.white}
              style={{ marginLeft: 10, transform: "translateY(2px)" }}
            />
          </Button>,
        ]}
      />
    </Wrapper>
  );
};

export default BuildTopBar;
