import { firestore } from "@/firebase";
import { Org } from "@/models/org";
import { Owner } from "@/models/owner";
import { User } from "@/models/user";

const watchOwner = (
  ownerId: string,
  successCallback: (org: Owner) => void,
  errorCallback: (error: Error) => void
): (() => void) => {
  if (!ownerId) return null;
  const orgUnsubscribe = firestore
    .collection("orgs")
    .doc(ownerId)
    .onSnapshot((docSnap) => {
      if (docSnap.exists) successCallback(docSnap.data() as Org);
    }, errorCallback);
  const userUnsubscribe = firestore
    .collection("users")
    .doc(ownerId)
    .onSnapshot((docSnap) => {
      if (docSnap.exists) successCallback(docSnap.data() as User);
    }, errorCallback);

  return () => {
    orgUnsubscribe();
    userUnsubscribe();
  };
};

export default watchOwner;
