import { firestore } from "@/firebase";
import { ownerType } from "@/lib/owner";
import { Group } from "@/models/group";
import { Owner } from "@/models/owner";

const getSubGroups =
  (data: { owner: Owner; group: Group }) => async (): Promise<Group[]> => {
    const { owner, group } = data;
    if (owner && group) {
      const query = await firestore
        .collection(ownerType(owner) === "org" ? "orgs" : "users")
        .doc(owner?.id)
        .collection("groups")
        .where("active", "==", true)
        .where("parent", "==", group?.id)
        .orderBy("position", "asc")
        .get();
      const groups = query.docs.map((doc) => doc.data() as Group);
      return groups;
    } else return null;
  };

export default getSubGroups;
