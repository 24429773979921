import React from "react";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import { MOBILE_WIDTH } from "@/lib/constants";
import Checkmark from "../Checkmark";
import Loading from "../Loading";
import CrossIcon from "@/components/icons/CrossIcon";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 0;
  position: relative;
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
`;

export const Title = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.nearBlack};
  text-align: left;
  width: 100%;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;

export const Description = styled.label`
  font-size: 16px;
  font-weight: 400;
  color: ${colors.darkGray};
  text-align: left;
  margin-top: 10px;
  line-height: 20px;
  width: 100%;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;

export const Error = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${colors.red};
  margin-top: 5px;
`;

export const Icon = styled.div`
  left: 15px;
  top: 13px;
  height: 16px;
  width: 16px;
  object-fit: contain;
  position: absolute;
  transform: translateY(2px);
`;

export const InputWrapper = styled.div<{ error: string }>`
  border-radius: 10px;
  width: 100%;
  border: 1px solid ${(props) => (props.error ? colors.red : colors.lightGray)};
  background-color: ${colors.white};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.08) 0 1px 5px 0;
    border: 1px solid ${(props) => (props.error ? colors.red : colors.gray)};
  }
`;

export const StyledInput = styled.input<{
  padded: boolean;
  error: string;
  color: string;
}>`
  outline: none;
  width: 100%;
  border: none;
  border-radius: 10px;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: ${(props) => props.color || colors.nearBlack};
  outline: none;
  padding: 15px 20px 15px ${(props) => (props.padded ? 50 : 20)}px;
  font-size: 16px;
  font-weight: 500;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:focus {
    ${InputWrapper} {
      border: 1px solid ${(props) => (props.error ? colors.red : colors.gray)};
      box-shadow: rgba(0, 0, 0, 0.08) 0 1px 5px 0;
    }
  }
`;

const Prefix = styled.div`
  padding: 15px 20px;
  height: 100%;
  border-right: 1px solid ${colors.lightGray};
  color: ${colors.darkGray};
  white-space: nowrap;
`;

const Suffix = styled.div`
  padding: 15px 20px;
  height: 100%;
  border-left: 1px solid ${colors.lightGray};
  color: ${colors.darkGray};
  white-space: nowrap;
`;

export type InputProps = {
  type: "text" | "number" | "password" | "email" | "date" | "tel";
  title?: string;
  style?: Record<string, unknown>;
  inputStyle?: Record<string, unknown>;
  styledInputStyle?: Record<string, unknown>;
  titleStyle?: Record<string, unknown>;
  descriptionStyle?: Record<string, unknown>;
  description?: string;
  icon?: React.ReactElement;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  prefix?: string;
  suffix?: string;
  error?: string;
  valid?: boolean;
  loading?: boolean;
  color?: string;
  autoFocus?: boolean;
  special?: {
    // track with other value, only works with formik Field
    name: string;
    relationship: (field: any) => any;
  };
};

type Props = Partial<React.HTMLProps<HTMLInputElement>> & InputProps;

const Input: React.FC<Props> = (props) => {
  const {
    type,
    title,
    style,
    icon,
    inputStyle,
    titleStyle,
    descriptionStyle,
    styledInputStyle,
    description,
    error,
    value,
    name,
    onChange,
    onBlur,
    prefix,
    suffix,
    valid,
    color,
    loading,
    ...otherProps
  } = props;

  return (
    <Wrapper style={style}>
      {title && (
        <Top>
          <Title style={titleStyle}>{title}</Title>
          {description && (
            <Description style={descriptionStyle}>{description}</Description>
          )}
        </Top>
      )}

      <InputWrapper error={error} style={inputStyle}>
        {!!icon && <Icon>{icon}</Icon>}
        {prefix && <Prefix>{prefix}</Prefix>}
        <StyledInput
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          type={type}
          padded={Boolean(icon)}
          data-cy={`input-${name}`}
          name={name}
          color={color}
          style={styledInputStyle}
          {...(otherProps as any)}
        />
        {valid && !loading && (
          <Checkmark size={20} style={{ marginRight: "10px" }} />
        )}
        {loading && <Loading size={20} style={{ marginRight: "10px" }} />}
        {error && !loading && (
          <CrossIcon
            size={20}
            color={colors.red}
            style={{
              marginRight: "10px",
              transform: "translateY(1px)",
            }}
          />
        )}
        {suffix && <Suffix>{suffix}</Suffix>}
      </InputWrapper>
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};

export default Input;
