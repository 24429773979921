import { firestore } from "@/firebase";
import { Owner } from "@/models/owner";

const getPaths = async (data: {
  owner: Owner;
  parent: string;
}): Promise<string[]> => {
  const { owner, parent } = data;

  const paths: string[] = [];
  let parentId: string = parent;

  while (parentId) {
    const parentFolder = await firestore
      .collection("orgs")
      .doc(owner.id)
      .collection("folders")
      .doc(parentId)
      .get();
    if (parentFolder.exists) {
      paths.push(parentFolder.id);
      parentId = parentFolder.data().parent as string;
    }
  }

  return paths.reverse();
};

export default getPaths;
