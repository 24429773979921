import { useRouter } from "next/router";
import useSessionIndex from "./useSessionIndex";

const useStepIndex = () => {
  const sessionIndex = useSessionIndex();
  const router = useRouter();
  const routerIndex = Number(router.query.i as string);
  return sessionIndex || routerIndex || 0;
};
export default useStepIndex;
