import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import SearchIcon from "@/components/icons/SearchIcon";
import { rgba } from "polished";
import getFolders from "@/db/folder/getFolders";
import useOwner from "@/hooks/useOwner";
import { useQuery } from "react-query";
import { Folder } from "@/models/folder";
import useAdmin from "@/hooks/useAdmin";
import { SearchFolder, SearchItem, SearchProgram } from "@/models/search";
import getPrograms from "@/db/program/getPrograms";
import { Program } from "@/models/program";
import Fuse from "fuse.js";
import CrossIcon from "../icons/CrossIcon";
import ProgramResult from "./ProgramResult";
import FolderResult from "./FolderResult";
import { useRouter } from "next/router";
import { MOBILE_WIDTH } from "@/lib/constants";
import Breakpoint from "../universal/Breakpoint";

const Wrapper = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 5;
  margin: 0 20px;
  justify-content: flex-start;
  position: relative;
  z-index: ${(props) => (props.open ? 401 : "auto")};
  @media (max-width: ${MOBILE_WIDTH}px) {
    flex: 0;
    margin: 0 20px 0 10px;
  } ;
`;

const SearchWrapper = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  width: ${(props) => (props.open ? "auto" : "100%")};
  border-radius: 10px;
  border: 1px solid ${colors.lightGray};
  background-color: ${colors.white};
  height: ${(props) => (props.open ? "60px" : "40px")};
  position: relative;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  position: ${(props) => (props.open ? "fixed" : "relative")};
  top: ${(props) => (props.open ? "20px" : "auto")};
  left: ${(props) => (props.open ? "20px" : "auto")};
  right: ${(props) => (props.open ? "20px" : "auto")};
  @media (max-width: ${MOBILE_WIDTH}px) {
    top: ${(props) => (props.open ? "20px" : "0")};
    left: ${(props) => (props.open ? "20px" : "0")};
    right: ${(props) => (props.open ? "20px" : "0")};
    height: ${(props) => (props.open ? "45px" : "50px")};
    border: ${(props) =>
      props.open ? `1px solid ${colors.lightGray}` : "none"};
    width: ${(props) => (props.open ? "auto" : "20px")};
  }
  &:hover {
    border: 1px solid
      ${(props) => (props.open ? colors.lightGray : colors.gray)};
  }
`;

const SearchInput = styled.input<{ open: boolean }>`
  font-size: ${(props) => (props.open ? "21px" : "16px")};
  font-weight: ${(props) => (props.open ? "500" : "400")};
  padding-left: ${(props) => (props.open ? "65px" : "50px")};
  color: ${colors.nearBlack};
  background: none;
  height: 40px;
  width: 100%;
  border: none;
  outline: none;
  &::placeholder {
    color: ${(props) => (props.open ? colors.nearBlack : colors.darkGray)};
  }
  @media (max-width: ${MOBILE_WIDTH}px) {
    padding-left: ${(props) => (props.open ? "50px" : "45px")};
    display: ${(props) => (props.open ? "block" : "none")};
    font-size: 16px;
  }
`;

const ResultsWrapper = styled.div<{ open: boolean }>`
  position: fixed;
  top: 100px;
  left: 20px;
  right: 20px;
  background-color: ${colors.white};
  border-radius: 10px;
  border: 1px solid ${colors.lightGray};
  opacity: ${(props) => (props.open ? 1 : 0)};
  pointer-events: ${(props) => (props.open ? "all" : "none")};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  flex-wrap: wrap;
  overflow: auto;
  max-height: calc(100vh - 120px);
  @media (max-width: ${MOBILE_WIDTH}px) {
    top: 80px;
    max-height: calc(100vh - 90px);
  }
`;

const Background = styled.div<{ open: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${rgba(colors.nearWhite, 0.9)};
  opacity: ${(props) => (props.open ? 1 : 0)};
  pointer-events: ${(props) => (props.open ? "all" : "none")};
  z-index: 401;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
`;

const Close = styled.div<{ open: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  opacity: ${(props) => (props.open ? 1 : 0)};
  pointer-events: ${(props) => (props.open ? "all" : "none")};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  line {
    transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  }
  &:hover {
    cursor: pointer;
  }
  &:hover line {
    stroke: ${colors.nearBlack};
    stroke-width: 2px;
  }
  @media (max-width: ${MOBILE_WIDTH}px) {
    width: 40px;
    line {
      stroke: ${colors.nearBlack};
      stroke-width: 2px;
    }
  }
`;

const Empty = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px;
  font-size: 16px;
  color: ${colors.darkGray};
  font-weight: 400;
  @media (max-width: ${MOBILE_WIDTH}px) {
    padding: 20px;
  }
`;

type Props = {
  placeholder?: string;
};

const Search: React.FC<Props> = ({ placeholder }) => {
  const owner = useOwner();
  const admin = useAdmin();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();

  useEffect(() => {
    setOpen(false);
    setValue("");
  }, [router.asPath]);

  const openSearch = useCallback(() => {
    setOpen(true);
  }, [inputRef]);

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpen(false);
        inputRef.current.blur();
      }
    };
    if (open) {
      inputRef?.current?.focus();
      if (foldersQuery.status === "idle") foldersQuery.refetch();
      if (programsQuery.status === "idle") programsQuery.refetch();
      document.addEventListener("keydown", handleEsc, false);
      return () => {
        document.removeEventListener("keydown", handleEsc, false);
      };
    }
  }, [open]);

  const foldersQuery = useQuery<Folder[]>(
    ["folders", owner?.id, admin],
    getFolders(owner, admin),
    {
      enabled: !!open,
    }
  );
  const folderItems: SearchFolder[] =
    foldersQuery.data
      ?.filter((folder) => folder.status !== "ARCHIVED")
      .map((folder) => ({ type: "FOLDER", folder })) || [];

  const programsQuery = useQuery<Program[]>(
    ["programs", owner?.id, admin, undefined],
    getPrograms(owner, admin, undefined),
    {
      enabled: !!open,
    }
  );
  const programItems: SearchProgram[] =
    programsQuery.data?.map((program) => ({ type: "PROGRAM", program })) || [];

  const searchItems = [...folderItems, ...programItems];

  const fuse = new Fuse<SearchItem>(searchItems, {
    keys: [
      "folder.title",
      "folder.description",
      "program.title",
      "program.description",
    ],
  });

  const results = fuse.search(value);

  return (
    <>
      <Background open={open} onClick={() => setOpen(false)} />
      <Wrapper open={open}>
        <SearchWrapper open={open}>
          <Breakpoint to={MOBILE_WIDTH} style={{ justifyContent: "flex-end" }}>
            <SearchIcon
              style={{
                position: "absolute",
                transform: `translateY(1px) translateX(${open ? 38 : 20}px)`,
                transition: "all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99)",
              }}
              height={18}
              color={open ? colors.nearBlack : colors.nearBlack}
              onClick={openSearch}
            />
          </Breakpoint>
          <Breakpoint from={MOBILE_WIDTH}>
            <SearchIcon
              style={{
                position: "absolute",
                left: open ? 25 : 20,
                transform: "translateY(2px)",
                transition: "all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99)",
              }}
              height={open ? 21 : 16}
              color={open ? colors.nearBlack : colors.darkGray}
              onClick={openSearch}
            />
          </Breakpoint>
          <SearchInput
            placeholder={placeholder || "Search"}
            value={value}
            open={open}
            ref={inputRef}
            onChange={(e) => setValue(e.target.value || "")}
            onFocus={() => setOpen(true)}
            autoFocus={open}
          />
          <Close open={open} onClick={() => setOpen(false)}>
            <Breakpoint from={MOBILE_WIDTH}>
              <CrossIcon
                size={28}
                color={colors.gray}
                style={{ transform: "translateY(2px)" }}
              />
            </Breakpoint>
            <Breakpoint to={MOBILE_WIDTH}>
              <CrossIcon
                size={21}
                color={colors.gray}
                style={{ transform: "translateY(2px)" }}
              />
            </Breakpoint>
          </Close>
        </SearchWrapper>
        <ResultsWrapper open={open}>
          {!value && <Empty>Start typing to search</Empty>}
          {results.map((result, i) => {
            switch (result.item.type) {
              case "PROGRAM":
                return (
                  <ProgramResult
                    program={result.item.program}
                    key={`search-result-${i}`}
                  />
                );
              case "FOLDER":
                return (
                  <FolderResult
                    folder={result.item.folder}
                    key={`search-result-${i}`}
                  />
                );
            }
          })}
        </ResultsWrapper>
      </Wrapper>
    </>
  );
};

export default Search;
