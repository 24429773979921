import React from "react";
import styled from "styled-components";
import { MAX_WIDTH, MOBILE_WIDTH } from "@/lib/constants";

const Wrapper = styled.div<{ from: number; to: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  align-self: center;
  @media screen and (min-width: ${(props) => props.to || MAX_WIDTH}px) {
    ${(props) => (props.to ? "display: none;" : "")}
  }
  @media screen and (max-width: ${(props) => props.from || MOBILE_WIDTH}px) {
    ${(props) => (props.from ? "display: none;" : "")}
  }
`;

type Props = {
  children: React.ReactElement | React.ReactElement[];
  style?: object;
  from?: number;
  to?: number;
};
const Breakpoint: React.FC<Props> = (props) => {
  const { style, children, from, to } = props;

  return (
    <Wrapper from={from} to={to} style={style}>
      {children}
    </Wrapper>
  );
};
Breakpoint.defaultProps = {
  style: {},
};

export default Breakpoint;
