import styled from "styled-components";
import { colors } from "@/lib/styles";
import TextButton from "../universal/Button/TextButton";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  flex-wrap: wrap;
  margin-top: auto;
`;

type Props = {};

const Legal: React.FC<Props> = (props) => {
  return (
    <>
      <Wrapper>
        <TextButton
          href={"https://www.brainhealthbootcamp.org/terms"}
          color={colors.gray}
          hoverColor={colors.darkGray}
          target={"_blank"}
          style={{ fontWeight: "400", fontSize: 12 }}
        >
          Terms of Use
        </TextButton>
        <TextButton
          href={"https://www.brainhealthbootcamp.org/privacy"}
          color={colors.gray}
          hoverColor={colors.darkGray}
          target={"_blank"}
          style={{ fontWeight: "400", fontSize: 12 }}
        >
          Privacy Policy
        </TextButton>
      </Wrapper>
    </>
  );
};

export default Legal;
