import { firestore } from "@/firebase";
import { Group } from "@/models/group";
import { Org } from "@/models/org";

const watchGroup = (
  orgId: string,
  groupId: string,
  successCallback: (group: Group) => void,
  errorCallback: (error: Error) => void
): (() => void) => {
  return firestore
    .collection("orgs")
    .doc(orgId)
    .collection("groups")
    .doc(groupId)
    .onSnapshot((docSnap) => {
      if (docSnap.exists) successCallback(docSnap.data() as Group);
    }, errorCallback);
};

export default watchGroup;
