import { firestore } from "@/firebase";
import { User } from "@/models/user";

const getGroupPeople =
  (data: { ownerId: string; groupId: string; subgroupId?: string }) =>
  async (): Promise<User[]> => {
    const { ownerId, groupId, subgroupId } = data;

    if (ownerId && groupId) {
      const query = await firestore
        .collection("users")
        .where(
          "memberships",
          "array-contains",
          groupId
            ? subgroupId
              ? `${ownerId}/${groupId}/${subgroupId}`
              : `${ownerId}/${groupId}`
            : ownerId
        )
        .orderBy("name", "desc")
        .get();
      const users = query.docs.map((doc) => doc.data() as User);
      return users;
    } else return null;
  };

export default getGroupPeople;
