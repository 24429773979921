import React, { useEffect, useRef, useState } from "react";
import { colors } from "@/lib/styles";
import styled from "styled-components";
import Link from "next/link";
import NestingIcon from "@/components/icons/NestingIcon";
import FolderIcon from "@/components/icons/FolderIcon";
import TogglePopup from "@/components/universal/TogglePopup";
import SideBarItem, {
  IconWrapper,
  Line,
  MoreButton,
} from "@/components/App/SideBar/SideBarItem";
import { useQuery, useQueryClient } from "react-query";
import MoreIcon from "@/components/icons/MoreIcon";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { DragItem } from "@/models/dnd";
import useOwner from "@/hooks/useOwner";
import moveItem from "@/db/organize/moveItem";
import { ownerColor } from "@/lib/owner";
import { rgba } from "polished";
import useAdmin from "@/hooks/useAdmin";
import useDragging from "@/hooks/useDragging";
import { useRouter } from "next/router";
import { TABLET_WIDTH } from "@/lib/constants";

const OuterOuterWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  position: relative;
`;

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  position: relative;
`;

const Wrapper = styled.div<{
  active: boolean;
  open: boolean;
  isOver: boolean;
  color: string;
  depth: number;
}>`
  position: ${(props) => (props.open ? "sticky" : "relative")};

  top: ${(props) => (props.open ? "43px" : "none")};
  text-decoration: none;
  width: 100%;
  padding: 10px 20px 10px ${(props) => 20 + props.depth * 10}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  background-color: ${(props) =>
    props.isOver
      ? rgba(props.color, 0.1)
      : props.active
      ? colors.nearWhite
      : colors.white};
  &:hover {
    background-color: ${colors.nearWhite};
    cursor: ${(props) => (props.active ? "default" : "pointer")};
  }
  h5 {
    font-weight: ${(props) => (props.active ? 500 : 400)};
    color: ${(props) => (props.active ? colors.nearBlack : colors.darkGray)};
  }
  &:hover h5 {
    color: ${colors.nearBlack};
  }
  path,
  circle {
    stroke: ${(props) => (props.active ? colors.nearBlack : colors.darkGray)};
  }
  &:hover path {
    stroke: ${colors.nearBlack};
  }
  &:hover circle {
    stroke: ${colors.nearBlack};
  }
  &:hover ${MoreButton} {
    opacity: 1;
    pointer-events: auto;
  }
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    top: ${(props) => (props.open ? "28px" : "none")};
  }
`;

const Title = styled.h5`
  font-size: 16px;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
  overflow: hidden;
`;

const ActiveIndicator = styled.div<{ active: boolean }>`
  position: absolute;
  right: 0;
  width: 2px;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: ${colors.nearBlack};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  opacity: ${(props) => (props.active ? 1 : 0)};
`;

const OpenIndicator = styled.button<{ open: boolean }>`
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  outline: none;
  border: 1px solid transparent;
  overflow: hidden;
  width: 20px;
  margin-right: 2px;
  height: 20px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background: none;
  text-decoration: none;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  transform: rotate(${(props) => (props.open ? 0 : -90)}deg);
  &:hover {
    background-color: ${colors.lightGray};
    cursor: pointer;
    path {
      fill: ${colors.darkGray};
    }
  }
`;

type Props = {
  id: string;
  parent: string;
  itemParent: string;
  position: number;
  type: string;
  itemType: string | string[];
  peerType?: string | string[];
  icon?: React.ReactElement;
  title: string;
  active: (path: string) => boolean;
  open: (path: string) => boolean;
  href: string;
  style?: object;
  iconStyle?: object;
  getItems?: (depth?: number) => () => Promise<React.ReactElement[]>;
  items?: React.ReactElement[];
  emptyPlaceholder?: string;
  more?: React.ReactElement | React.ReactElement[];
  moreLabel?: string;
  depth?: number;
};
const SideBarItemGroup: React.FC<Props> = ({
  id,
  parent,
  itemParent,
  peerType,
  position,
  icon,
  title,
  active,
  open,
  href,
  style,
  iconStyle,
  items,
  getItems,
  emptyPlaceholder,
  more,
  type,
  itemType,
  depth,
  moreLabel,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const [o, setO] = useState(open(router.asPath));
  const owner = useOwner();
  const admin = useAdmin();
  const dragging = useDragging();

  useEffect(() => {
    setO(open(router.asPath));
  }, [open(router.asPath)]);

  // group as draggable line
  const [{ isDragging, getDropResult }, drag] = useDrag(
    () => ({
      type: type,
      item: {
        parent,
        position,
        id,
        type,
      },
      canDrag: !!admin,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        getDropResult: monitor.getDropResult(),
      }),
    }),
    [parent, id, type, position]
  );

  const queryClient = useQueryClient();

  // line above group line for reordering
  const lineDrop = useDrop(
    () => ({
      accept: peerType || [],
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
      drop(item: DragItem, monitor: DropTargetMonitor) {
        moveItem(owner, item, { parent, position: position - 0.5 })(() => {
          queryClient.invalidateQueries(["groups", owner.id]);
          queryClient.invalidateQueries(["folders", owner.id]);
          queryClient.invalidateQueries(["programs", owner.id, admin]);
          queryClient.invalidateQueries("sidebar-items");
        });
      },
    }),
    [owner, position]
  );
  const isOverLine = lineDrop[0].isOver;
  const lineDropRef = lineDrop[1];

  // dropping into group itself
  const [groupDrop, drop] = useDrop(
    () => ({
      accept: itemType,
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
      drop(item: DragItem, monitor: DropTargetMonitor) {
        if (monitor.isOver({ shallow: true }))
          moveItem(owner, item, { parent: itemParent, position: -0.5 })(() => {
            queryClient.invalidateQueries(["groups", owner.id]);
            queryClient.invalidateQueries(["folders", owner.id]);
            queryClient.invalidateQueries(["programs", owner.id, admin]);
            queryClient.invalidateQueries(["sidebar-items"]);
          });
      },
    }),
    [owner, position, itemType, itemParent, admin]
  );

  const getItemsQuery = useQuery(
    ["sidebar-items", href, depth],
    getItems ? getItems(depth) : null
  );

  const data = items || getItemsQuery.data || [];

  const [focused, setFocused] = useState(false);
  const highlighted = active(router.asPath) || focused;

  drag(drop(ref));

  return (
    <OuterOuterWrapper>
      {peerType && (
        <Line
          disabled={!dragging}
          color={ownerColor(owner)}
          isOver={isOverLine}
          ref={lineDropRef}
          depth={depth}
        >
          <div />
        </Line>
      )}
      <OuterWrapper ref={ref}>
        {o && (
          <>
            {data && data}
            {data?.length === 0 && (
              <SideBarItem
                id={"empty"}
                parent={itemParent}
                position={0}
                key={`empty-group-${href}`}
                title={emptyPlaceholder || "No items"}
                active={() => false}
                depth={depth ? depth + 1 : 1}
                disabled
                noIcon
                type={"PROGRAM"}
              />
            )}
          </>
        )}
        <Link href={href} passHref>
          <Wrapper
            depth={depth}
            color={ownerColor(owner)}
            isOver={groupDrop.isOver}
            open={o}
            active={highlighted}
            style={style}
          >
            <OpenIndicator
              open={o}
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                setO((o) => !o);
              }}
            >
              <NestingIcon
                color={active(router.asPath) ? colors.darkGray : colors.gray}
                size={12}
                style={{
                  transform: "translateY(1px) ",
                }}
              />
            </OpenIndicator>
            <TogglePopup
              popupId={`choose-icon-${id}`}
              tooltip="Choose icon"
              tooltipPosition="bottom"
              onToggle={(o) => setFocused(o)}
              toggle={
                icon ? (
                  <IconWrapper style={iconStyle}>{icon}</IconWrapper>
                ) : (
                  <IconWrapper style={iconStyle}>
                    <FolderIcon
                      size={16}
                      style={{ transform: "translateY(2px)" }}
                    />
                  </IconWrapper>
                )
              }
            >
              {/* choose icon popup */}
            </TogglePopup>
            <Title>{title}</Title>
            {more && (
              <TogglePopup
                popupId={`more-${id}`}
                z={200}
                tooltip={moreLabel || "More..."}
                tooltipPosition="bottom"
                onToggle={(o) => setFocused(o)}
                toggle={
                  <MoreButton>
                    <MoreIcon size={4} color={colors.gray} />
                  </MoreButton>
                }
              >
                {more}
              </TogglePopup>
            )}

            <ActiveIndicator active={active(router.asPath)} />
          </Wrapper>
        </Link>
      </OuterWrapper>
    </OuterOuterWrapper>
  );
};

export default SideBarItemGroup;
