import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import { TABLET_WIDTH } from "@/lib/constants";
import Button from "@/components/universal/Button/Button";
import SendIcon from "@/components/icons/SendIcon";
import { useMutation } from "react-query";
import useUid from "@/hooks/useUid";
import Loading from "@/components/universal/Loading";
import toast from "react-hot-toast";
import useUrl from "@/hooks/useUrl";
import addFeedback from "@/db/feedback/addFeedback";
import useOwner from "@/hooks/useOwner";
import useProgram from "@/hooks/useProgram";
import useStepIndex from "@/hooks/useStepIndex";

const Dropdown = styled.div<{ inverse?: boolean }>`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  ${(props) => (props.inverse ? "border-radius: 10px;" : "")}
  padding: 20px 15px;
  pointer-events: none;
  opacity: 0;
  background-color: ${colors.white};
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 59px;
  right: -20px;
  z-index: 102;
  overflow: visible;
  transform: scale(0.8);
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 20px 0;
  border: 1px solid ${colors.lightGray};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:hover {
    cursor: default;
  }
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    border-radius: 10px;
  }
`;

const Wrapper = styled.a<{ inverse: boolean }>`
  display: inline-block;
  text-decoration: none;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 120%;
  font-weight: 400;
  position: relative;
  margin: 0 18px;
  color: ${(props) => (props.inverse ? colors.lightGray : colors.darkGray)};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:hover {
    cursor: pointer;
    color: ${(props) => (props.inverse ? colors.white : colors.nearBlack)};
  }
  &:hover #feedback-icon > path {
    stroke: ${(props) => (props.inverse ? colors.white : colors.nearBlack)};
  }
  &:hover ${Dropdown} {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0) scale(1);
  }
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    width: 100%;
    border: none;
    margin: 0;
    justify-content: flex-start;
  }
`;

const Feelings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
`;

const Feeling = styled.div<{ active: boolean }>`
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  border: 1px solid
    ${(props) => (props.active ? colors.nearBlack : colors.lightGray)};
  font-size: 28px;
  line-height: 20px;
  padding-top: 3px;
  margin: 0 5px;
  text-align: center;
  &:hover {
    border-color: ${(props) =>
      props.active ? colors.nearBlack : colors.darkGray};
    cursor: pointer;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  width: 100%;
  path {
    stroke: ${colors.white};
  }
`;

const FeedbackInput = styled.textarea`
  padding: 15px;
  border: 1px solid ${colors.lightGray};
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  margin: 20px 5px;
  min-width: 250px;
  min-height: 100px;
  width: 100%;
  color: ${colors.nearBlack};
  &::placeholder {
    color: ${colors.darkGray};
  }
`;

type Props = {
  inverse?: boolean;
};

const Feedback: React.FC<Props> = (props) => {
  const { inverse } = props;

  const uid = useUid();
  const [feeling, setFeeling] = useState<string>(null);
  const [feedback, setFeedback] = useState<string>(null);

  const owner = useOwner();
  const program = useProgram();
  const url = useUrl();

  const stepIndex = useStepIndex();

  const submitFeedbackMutation = useMutation(
    addFeedback({
      userId: uid,
      url,
      feeling,
      message: feedback,
      owner,
      program,
      stepIndex,
    }),
    {
      onSuccess: () => {
        setFeeling(null);
        setFeedback(null);
        toast.success("Thank you for your feedback!");
      },
    }
  );

  return (
    <Wrapper inverse={inverse}>
      Feedback
      <>
        <Dropdown inverse={inverse}>
          <Feelings>
            <Feeling
              active={feeling === "😭"}
              onClick={() =>
                feeling === "😭" ? setFeeling(null) : setFeeling("😭")
              }
            >
              😭
            </Feeling>
            <Feeling
              active={feeling === "😕"}
              onClick={() =>
                feeling === "😕" ? setFeeling(null) : setFeeling("😕")
              }
            >
              😕
            </Feeling>
            <Feeling
              active={feeling === "😐"}
              onClick={() =>
                feeling === "😐" ? setFeeling(null) : setFeeling("😐")
              }
            >
              😐
            </Feeling>
            <Feeling
              active={feeling === "🙂"}
              onClick={() =>
                feeling === "🙂" ? setFeeling(null) : setFeeling("🙂")
              }
            >
              🙂
            </Feeling>
            <Feeling
              active={feeling === "😍"}
              onClick={() =>
                feeling === "😍" ? setFeeling(null) : setFeeling("😍")
              }
            >
              😍
            </Feeling>
          </Feelings>

          <Content>
            <FeedbackInput
              placeholder="Your feedback"
              value={feedback || ""}
              onChange={(e) => setFeedback(e.target.value)}
            />

            <Button
              style={{ width: "100%", margin: "0", padding: "15px 20px" }}
              backgroundColor={colors.nearBlack}
              color={colors.white}
              onClick={submitFeedbackMutation.mutate}
              disabled={!feeling && !feedback}
            >
              Submit
              {!submitFeedbackMutation.isLoading && (
                <SendIcon
                  size={16}
                  color={colors.white}
                  style={{ marginLeft: 10 }}
                />
              )}
              {submitFeedbackMutation.isLoading && (
                <Loading size={16} style={{ marginLeft: 10 }} light />
              )}
            </Button>
          </Content>
        </Dropdown>
      </>
    </Wrapper>
  );
};

export default Feedback;
