import React, { useCallback } from "react";
import NavLinks from "@/components/App/TopBar/NavLinks";
import { colors } from "@/lib/styles";
import styled from "styled-components";
import {
  ACCOUNT_LINK,
  ADMIN_LINKS,
  GROUP_LINK,
  GROUP_ADMIN_LINKS,
  LANDING_LINKS,
  LOGIN_LINK,
} from "./links";
import useAdmin from "@/hooks/useAdmin";
import Breakpoint from "@/components/universal/Breakpoint";
import { MEDIUM_MAX_WIDTH, MOBILE_WIDTH, TABLET_WIDTH } from "@/lib/constants";
import useOwner from "@/hooks/useOwner";
import useGroup from "@/hooks/useGroup";
import NavPath from "./NavPath";
import useProgram from "@/hooks/useProgram";
import useFolder from "@/hooks/useFolder";
import useGroupAdmin from "@/hooks/useGroupAdmin";
import useLoggedIn from "@/hooks/useLoggedIn";
import { useRouter } from "next/router";
import Search from "@/components/Search";

const Wrapper = styled.div<{ menu: boolean }>`
  flex: 1;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.lightGray};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  align-items: center;
  position: relative;
  padding: 0 20px;
  @media screen and (max-width: ${MEDIUM_MAX_WIDTH}px) {
    padding-left: ${(props) => (props.menu ? "50px" : "20px")};
  }
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    justify-content: flex-start;
  }
`;

type Props = {};
const AppTopBar: React.FC<Props> = (props) => {
  const admin = useAdmin();
  const groupAdmin = useGroupAdmin();
  const owner = useOwner();
  const program = useProgram();
  const folder = useFolder();
  const group = useGroup();
  const loggedIn = useLoggedIn();
  const router = useRouter();
  const getPath = useCallback(() => {
    return router.asPath;
  }, [router.asPath]);

  return (
    <>
      <Wrapper menu={admin || groupAdmin}>
        <NavPath />
        <Breakpoint from={MOBILE_WIDTH} style={{ flex: 5 }}>
          <Search />
        </Breakpoint>
        <Breakpoint from={MEDIUM_MAX_WIDTH}>
          <NavLinks
            links={[
              ...(admin
                ? ADMIN_LINKS({ owner, program, folder })
                : groupAdmin
                ? GROUP_ADMIN_LINKS({ owner, program, folder })
                : LANDING_LINKS({ owner, program, folder })),
              ...(group && !admin
                ? [
                    GROUP_LINK(owner, group),
                    loggedIn ? ACCOUNT_LINK(owner) : LOGIN_LINK(owner, getPath),
                  ]
                : [
                    loggedIn ? ACCOUNT_LINK(owner) : LOGIN_LINK(owner, getPath),
                  ]),
            ]}
          />
        </Breakpoint>
        <Breakpoint from={MOBILE_WIDTH} to={MEDIUM_MAX_WIDTH}>
          <NavLinks
            links={[
              ...(group && !admin
                ? [loggedIn ? ACCOUNT_LINK(owner) : LOGIN_LINK(owner, getPath)]
                : [
                    loggedIn ? ACCOUNT_LINK(owner) : LOGIN_LINK(owner, getPath),
                  ]),
            ]}
          />
        </Breakpoint>
      </Wrapper>
    </>
  );
};

export default AppTopBar;
