import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
};
const MoreIcon: React.FC<Props> = ({ size, color, style }) => {
  const height = size ? String(size) : "4";
  const width = size ? String((size / 4) * 16) : "16";

  return (
    <span
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          vectorEffect={"non-scaling-stroke"}
          cx="2"
          cy="2"
          r="2"
          fill={color}
          strokeWidth={0}
        />
        <circle
          vectorEffect={"non-scaling-stroke"}
          cx="8"
          cy="2"
          r="2"
          fill={color}
          strokeWidth={0}
        />
        <circle
          vectorEffect={"non-scaling-stroke"}
          cx="14"
          cy="2"
          r="2"
          fill={color}
          strokeWidth={0}
        />
      </svg>
    </span>
  );
};
MoreIcon.defaultProps = {
  color: colors.darkGray,
  size: 4,
};

export default MoreIcon;
