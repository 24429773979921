import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  color?: string;
  style?: Record<string, unknown>;
  className?: string;
  stroke?: number;
};
const CaretIcon: React.FC<Props> = (props) => {
  const width = props.size ? String((props.size / 18) * 12) : "12";
  const height = props.size ? String(props.size) : "18";

  return (
    <div style={props.style} className={props.className ? props.className : ""}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transition: "all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99)",
        }}
      >
        <path
          d="M2.25 15.75L9.75 8.75L2.25 1.75"
          stroke={props.color}
          strokeWidth={String(props.stroke)}
          strokeLinecap="round"
          strokeLinejoin="round"
          style={{
            transition: "all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99)",
          }}
        />
      </svg>
    </div>
  );
};
CaretIcon.defaultProps = {
  size: 12,
  color: colors.primary,
  stroke: 2,
};

export default CaretIcon;
