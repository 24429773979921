import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import { TABLET_WIDTH } from "@/lib/constants";
import Portal from "./Portal";

const PopupComponent = styled.div<{
  position: [number, number];
  open: boolean;
  z: number;
}>`
  border-radius: 10px;
  border: 1px solid ${colors.lightGray};
  pointer-events: ${(props) => (props.open ? "auto" : "none")};
  opacity: ${(props) => (props.open ? 1 : 0)};
  background-color: ${colors.white};
  position: fixed;
  display: flex;
  top: ${(props) => props.position[1]}px;
  left: ${(props) => props.position[0]}px;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  z-index: ${(props) => props.z || 200};
  overflow: visible;
  transform: scale(${(props) => (props.open ? 1 : 0.8)});
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 20px 0;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    border-radius: 10px;
  }
`;

const Wrapper = styled.div<{ disabled?: boolean }>`
  display: inline-block;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  color: ${colors.darkGray};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:hover {
    cursor: ${(props) => (props.disabled ? "default" : "cursor")};
    pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  }
  @media screen and (max-width: ${TABLET_WIDTH}px) {
  }
`;

type Props = {
  style?: object;
  toggle?: React.ReactElement;
  children?: React.ReactElement | React.ReactElement[];
  rounded?: boolean;
  onToggle?: (open: boolean) => void;
  tooltip?: string;
  tooltipPosition?: "bottom" | "left" | "right";
  z?: number;
  popupId: string;
  disabled?: boolean;
};

const TogglePopup: React.FC<Props> = ({
  style,
  toggle,
  children,
  rounded,
  onToggle,
  tooltip,
  tooltipPosition,
  disabled,
  z,
  popupId,
}) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState([0, 0] as [number, number]);

  useEffect(() => {
    if (wrapperRef.current) {
      const boundaries = wrapperRef.current.getBoundingClientRect();
      setPosition([boundaries.left, boundaries.top]);
    }
  }, []);

  const clickEvent = useCallback(
    (e: any) => {
      if (!document.getElementById(`popup-${popupId}`).contains(e.target)) {
        // Clicked outside box
        setOpen((o) => {
          if (onToggle) onToggle(!o);
          return !o;
        });
      }
    },
    [setOpen, open, onToggle, popupId]
  );

  useEffect(() => {
    if (open) {
      window.addEventListener("click", clickEvent);
      return () => {
        window.removeEventListener("click", clickEvent);
      };
    }
  }, [open]);

  if (disabled) return toggle;
  if (!disabled)
    return (
      <>
        <Wrapper
          style={style}
          ref={wrapperRef}
          disabled={disabled}
          onMouseOver={(e) => {
            if (e.pageX && e.pageY && !open)
              setPosition([e.pageX + 3, e.pageY + 3]);
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            document.getElementById(`content`).click();
            setOpen((o) => {
              if (onToggle) onToggle(!o);
              return !o;
            });
          }}
        >
          {toggle}
        </Wrapper>
        <Portal el="popup">
          <PopupComponent
            z={z}
            id={`popup-${popupId}`}
            key={`popup-${popupId}`}
            position={position}
            open={open}
          >
            {children}
          </PopupComponent>
        </Portal>
      </>
    );
};

export default TogglePopup;
