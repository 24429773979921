export interface DragItem {
  position: number;
  id: string;
  parent: string;
  type: string;
}

export const PROGRAM = "PROGRAM";
export const FOLDER = "FOLDER";
export const USER = "USER";
export const GROUP = "GROUP";
export const TAG = "TAG";
