import { firestore } from "@/firebase";
import { Group } from "@/models/group";
import { Session } from "@/models/session";

const getGroups =
  (orgId: string, groupId?: string, admin?: boolean) =>
  async (): Promise<Group[]> => {
    if (orgId && (admin || groupId)) {
      let q = firestore
        .collection("orgs")
        .doc(orgId)
        .collection("groups")
        .where("active", "==", true)
        .orderBy("title", "asc");
      if (groupId) q = q.where("parent", "==", groupId);
      const query = await q.get();

      return query.docs.map((doc) => doc.data() as Group);
    } else return null;
  };

export default getGroups;
