import { firestore } from "@/firebase";
import { v4 as uuidv4 } from "uuid";
import { Session } from "@/models/session";
import * as Sentry from "@sentry/nextjs";
import { Group } from "@/models/group";

const watchSession = (
  sessionId: string,
  callback: (session: Session) => void
): (() => void) => {
  try {
    if (!sessionId) return () => {};
    return firestore
      .collection("sessions")
      .doc(sessionId)
      .onSnapshot((doc) => {
        if (doc.exists) callback(doc.data() as Session);
      });
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

export default watchSession;
