import { firestore } from "@/firebase";
import { ownerType } from "@/lib/owner";
import { DragItem } from "@/models/dnd";
import { Owner } from "@/models/owner";
import * as Sentry from "@sentry/browser";
import resetPositions from "../utils/resetPositions";
import { toast } from "react-hot-toast";
import getPaths from "../path/getPaths";
import getSlugPaths from "../path/getSlugPaths";

const moveItem =
  (
    owner: Owner,
    item: DragItem,
    newPlace: { parent?: string; position?: number }
  ) =>
  async (done: () => void): Promise<void> => {
    try {
      // cannot move item into itself
      if (item.id == newPlace.parent) return;

      const paths = ["FOLDER", "PROGRAM"].includes(item.type)
        ? await getPaths({ owner, parent: newPlace.parent })
        : [];
      const slugPaths = ["FOLDER", "PROGRAM"].includes(item.type)
        ? await getSlugPaths({ owner, parent: newPlace.parent })
        : [];

      const ownerRef = firestore
        .collection(ownerType(owner) === "org" ? "orgs" : "users")
        .doc(owner.id);

      // set the new position (in .5s)
      switch (item.type) {
        case "PROGRAM":
          // update program

          await firestore
            .collection("programs")
            .doc(item.id)
            .update({ ...newPlace, paths, slugPaths });
          await resetPositions("library", owner, item.parent);
          await resetPositions("library", owner, newPlace.parent);
          toast.success("Course moved");
          done();
          break;
        case "FOLDER":
          // update folder
          await ownerRef
            .collection("folders")
            .doc(item.id)
            .update({ ...newPlace, paths, slugPaths });
          await resetPositions("library", owner, item.parent);
          await resetPositions("library", owner, newPlace.parent);
          toast.success("Folder moved");
          done();
          break;
        case "USER":
          // await Promise.all([
          //   firestore
          //     .collection("users")
          //     .doc(item.id)
          //     .update({
          //       memberships: firebase.firestore.FieldValue.arrayRemove(
          //         item.parent
          //       ),
          //     }),
          //   firestore
          //     .collection("users")
          //     .doc(item.id)
          //     .update({
          //       memberships: firebase.firestore.FieldValue.arrayUnion(
          //         newPlace.parent
          //       ),
          //     }),
          // ]);
          // toast.success("User moved");
          done();
          break;
        case "GROUP":
          // await firestore
          //   .collection(ownerType(owner) === "org" ? "orgs" : "users")
          //   .doc(owner.id)
          //   .collection("groups")
          //   .doc(item.id)
          //   .update({ ...newPlace });
          // await resetPositions("customers", owner, item.parent);
          // await resetPositions("customers", owner, newPlace.parent);
          // toast.success("Group moved");
          done();
          break;
        case "TAG":
          await firestore
            .collection("orgs")
            .doc(owner.id)
            .collection("tags")
            .doc(item.id)
            .update({ ...newPlace });
          await resetPositions("tags", owner);
          toast.success("Tag reordered");
          done();
          break;
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

export default moveItem;
