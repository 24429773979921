import React from "react";
import { colors } from "@/lib/styles";
import styled from "styled-components";
import CurveArrowIcon from "@/components/icons/CurveArrowIcon";

const Wrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
`;

const Title = styled.p<{ color: string }>`
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  width: 180px;
  color: ${(props) => props.color};
`;

type Props = {
  title: string;
  style?: object;
  color?: string;
};
const EmptySection: React.FC<Props> = ({ title, style, color }) => {
  return (
    <Wrapper style={style}>
      <Title color={color || colors.gray}>{title}</Title>
      <CurveArrowIcon
        style={{
          position: "absolute",
          right: 35,
          transform: "rotate(180deg)",
        }}
        color={color || colors.gray}
      />
    </Wrapper>
  );
};

export default EmptySection;
