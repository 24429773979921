import { sessionSelectors } from "@/redux/session";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import usePreview from "./usePreview";
import useSession from "./useSession";

const useSessionIndex = () => {
  const session = useSession();
  const preview = usePreview();
  const router = useRouter();
  let stepIndex = preview
    ? router.query.i
      ? Number(router.query.i)
      : 0
    : session?.activeIndex || 0;
  return Math.floor(stepIndex);
};
export default useSessionIndex;
