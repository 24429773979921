import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
};
const SendIcon: React.FC<Props> = (props) => {
  return (
    <span style={props.style}>
      <svg
        width={String(props.size)}
        height={String(props.size)}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.8506 1L7.68276 9.16782"
          stroke={props.color}
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8506 1L10.6529 15.8506L7.68276 9.16782L1 6.1977L15.8506 1Z"
          stroke={props.color}
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
SendIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default SendIcon;
