import React, { useRef } from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import { TABLET_WIDTH } from "@/lib/constants";
import useDidUpdateEffect from "@/hooks/useDidUpdateEffect";

// https://assets9.lottiefiles.com/packages/lf20_LoJiR8.json

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  padding: 15px;
  z-index: 401;
  display: none;
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    display: block;
  }
`;

const LottieWrapper = styled.div`
  height: 28px;
  width: 28px;
`;

type Props = {
  open: boolean;
  onClick: () => void;
  inverse?: boolean;
};
const MenuButton: React.FC<Props> = (props) => {
  const { inverse, open, onClick } = props;

  const ref = useRef<any>(null);

  useDidUpdateEffect(() => {
    if (ref.current) ref.current.play();
  }, [open]);

  return (
    <Wrapper onClick={onClick}>
      <LottieWrapper>
        <Lottie
          ref={ref}
          direction={open ? 1 : -1}
          speed={2}
          isPaused={true}
          options={{
            loop: false,
            autoplay: false,
            animationData: !inverse
              ? require("@/lib/animations/menuDark.json")
              : require("@/lib/animations/menuLight.json"),
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
        />
      </LottieWrapper>
    </Wrapper>
  );
};
MenuButton.defaultProps = {
  inverse: false,
};

export default MenuButton;
