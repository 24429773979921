import { Group } from "@/models/group";
import { Owner } from "@/models/owner";
import { ownerAdmin } from "./owner";

export const groupAdmin = (
  owner: Owner,
  group: Group,
  uid: string,
  userEmail?: string
): boolean => {
  try {
    if (group?.admins?.includes(uid)) return true;
    if (ownerAdmin(owner, uid)) return true;
    if (userEmail && group && group?.admins?.includes(userEmail)) return true;
    return false;
  } catch (e) {
    return null;
  }
};
