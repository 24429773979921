import { firestore } from "@/firebase";
import { Group } from "@/models/group";

const getGroup =
  (orgId: string, groupId: string) => async (): Promise<Group> => {
    if (orgId && groupId) {
      const groupDoc = await firestore
        .collection("orgs")
        .doc(orgId)
        .collection("groups")
        .doc(groupId)
        .get();

      if (groupDoc.exists) return groupDoc.data() as Group;
      else return null;
    } else return null;
  };

export default getGroup;
