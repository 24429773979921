import { firestore } from "@/firebase";
import { Program, Step } from "@/models/program";
import moment from "moment";
import * as Sentry from "@sentry/nextjs";
import { ownerAdmin } from "@/lib/owner";
import { Owner } from "@/models/owner";
import {
  FolderVersion,
  NextOrgVersion,
  OrgVersion,
  ProgramVersion,
} from "@/models/version";
import { Org } from "@/models/org";
import { Folder } from "@/models/folder";

const publishVersion = async (data: {
  type: "minor" | "major";
  description?: string;
  orgId: string;
  uid: string;
}): Promise<void> => {
  try {
    const { type, orgId, uid, description } = data;

    let batch = firestore.batch();

    // get current, latest, and next org docs
    console.log("getting current, latest, and next org docs");
    const currentOrgDoc = await firestore.collection("orgs").doc(orgId).get();
    const currentOrg = currentOrgDoc.data() as Org;
    const latestOrgVersionDoc = await firestore
      .collection("orgs")
      .doc(orgId)
      .collection("versions")
      .doc("latest")
      .get();
    const latestOrgVersion = latestOrgVersionDoc.data() as OrgVersion;
    const nextOrgVersionDoc = await firestore
      .collection("orgs")
      .doc(orgId)
      .collection("versions")
      .doc("next")
      .get();
    const nextOrgVersion = nextOrgVersionDoc.data() as OrgVersion;

    if (!ownerAdmin(currentOrg as Owner, uid))
      throw new Error("User is not an admin of this org");

    // get current and latest program docs
    console.log("getting current and latest program docs");
    const currentProgramDocs = (
      await firestore.collection("programs").where("orgId", "==", orgId).get()
    ).docs;
    const latestProgramDocs = await Promise.all(
      currentProgramDocs.map(
        async (doc) => await doc.ref.collection("versions").doc("latest").get()
      )
    );

    // get current and latest folder docs
    console.log("getting current and latest folder docs");
    const currentFolderDocs = (
      await firestore.collection("orgs").doc(orgId).collection("folders").get()
    ).docs;
    const latestFolderDocs = await Promise.all(
      currentFolderDocs.map(
        async (doc) => await doc.ref.collection("versions").doc("latest").get()
      )
    );

    // save latest docs as major.minor excluding archived programs and folders
    batch.set(
      firestore
        .collection("orgs")
        .doc(orgId)
        .collection("versions")
        .doc(
          `${latestOrgVersion.majorVersion}.${latestOrgVersion.minorVersion}`
        ),
      latestOrgVersion as OrgVersion,
      { merge: true }
    );
    console.log(
      `archiving ${latestOrgVersion.org.title} ${latestOrgVersion.majorVersion}.${latestOrgVersion.minorVersion}`
    );
    for (const latestProgramDoc of latestProgramDocs) {
      if (!latestProgramDoc.exists) continue;
      const latestProgram = latestProgramDoc.data() as ProgramVersion;
      batch.set(
        firestore
          .collection("programs")
          .doc(latestProgram.program.id)
          .collection("versions")
          .doc(`${latestProgram.majorVersion}.${latestProgram.minorVersion}`),
        latestProgram as ProgramVersion,
        { merge: true }
      );
      console.log(
        `archiving ${latestProgram.program.title} ${latestProgram.majorVersion}.${latestProgram.minorVersion}`
      );
    }
    for (const latestFolderDoc of latestFolderDocs) {
      if (!latestFolderDoc.exists) continue;
      const latestFolder = latestFolderDoc.data() as FolderVersion;
      batch.set(
        firestore
          .collection("orgs")
          .doc(latestFolder.folder.owner)
          .collection("folders")
          .doc(latestFolder.folder.id)
          .collection("versions")
          .doc(`${latestFolder.majorVersion}.${latestFolder.minorVersion}`),
        latestFolder as FolderVersion,
        { merge: true }
      );
      console.log(
        `archiving ${latestFolder.folder.title} ${latestFolder.majorVersion}.${latestFolder.minorVersion}`
      );
    }

    // calculate new major and minor versions
    const newMajorVersion =
      latestOrgVersion.majorVersion + (type === "major" ? 1 : 0);
    const newMinorVersion =
      type === "major" ? 0 : latestOrgVersion.minorVersion + 1;

    // set new versions as latest
    batch.set(
      firestore
        .collection("orgs")
        .doc(orgId)
        .collection("versions")
        .doc("latest"),
      {
        majorVersion: newMajorVersion,
        minorVersion: newMinorVersion,
        org: currentOrg,
        changed: nextOrgVersion?.changed || [],
        description,
        publishedBy: uid,
        publishedTime: moment().unix(),
      } as OrgVersion
    );
    console.log(
      `publishing ${currentOrg.title} ${newMajorVersion}.${newMinorVersion} | set orgs/${currentOrg.id}/versions/latest`,
      {
        majorVersion: newMajorVersion,
        minorVersion: newMinorVersion,
        org: currentOrg,
        changed: nextOrgVersion?.changed || [],
        description,
        publishedBy: uid,
        publishedTime: moment().unix(),
      }
    );
    for (const currentProgramDoc of currentProgramDocs) {
      if (!currentProgramDoc.exists) continue;
      const currentProgram = currentProgramDoc.data() as Program;
      if (!currentProgram.orgId) continue;
      batch.set(
        firestore
          .collection("programs")
          .doc(currentProgram.id)
          .collection("versions")
          .doc("latest"),
        {
          majorVersion: newMajorVersion,
          minorVersion: newMinorVersion,
          program: currentProgram,
        } as ProgramVersion
      );
      console.log(
        `publishing ${currentProgram.title} ${newMajorVersion}.${newMinorVersion} | update programs/${currentProgram.id}/versions/latest`,
        {
          majorVersion: newMajorVersion,
          minorVersion: newMinorVersion,
          program: currentProgram,
        } as ProgramVersion
      );
    }
    for (const currentFolderDoc of currentFolderDocs) {
      if (!currentFolderDoc.exists) continue;
      const currentFolder = currentFolderDoc.data() as Folder;
      if (!currentFolder.owner) continue;
      batch.set(
        firestore
          .collection("orgs")
          .doc(currentFolder.owner)
          .collection("folders")
          .doc(currentFolder.id)
          .collection("versions")
          .doc("latest"),
        {
          folder: currentFolder,
          majorVersion: newMajorVersion,
          minorVersion: newMinorVersion,
        } as FolderVersion
      );
      console.log(
        `publishing ${currentFolder.title} ${newMajorVersion}.${newMinorVersion} | update orgs/${currentFolder.owner}/folders/${currentFolder.id}/versions/latest`,
        {
          folder: currentFolder,
          majorVersion: newMajorVersion,
          minorVersion: newMinorVersion,
        }
      );
    }

    // create new next org version doc
    batch.set(nextOrgVersionDoc.ref, {
      changed: [],
      org: {
        id: orgId,
      },
    } as NextOrgVersion);
    console.log(
      `resetting changelog for next version | set orgs/${orgId}/versions/next`,
      {
        changed: [],
        org: {
          id: orgId,
        },
      }
    );

    console.log(`committing ${newMajorVersion}.${newMinorVersion}`);
    await batch.commit();
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

export default publishVersion;
