import { firestore } from "@/firebase";
import { Folder } from "@/models/folder";
import { Owner } from "@/models/owner";

const watchFolder = (
  owner: Owner,
  folderId: string,
  successCallback: (folder: Folder) => void,
  errorCallback: (error: Error) => void
): (() => void) => {
  return firestore
    .collection("orgs")
    .doc(owner.id)
    .collection("folders")
    .doc(folderId)
    .onSnapshot((docSnap) => {
      if (docSnap.exists) successCallback(docSnap.data() as Folder);
    }, errorCallback);
};

export default watchFolder;
