import { firestore } from "@/firebase";
import { ownerSlug, ownerType } from "@/lib/owner";
import { Owner } from "@/models/owner";
import { Program } from "@/models/program";
import { ProgramVersion } from "@/models/version";
import getVersion from "../version/getVersion";
import * as Sentry from "@sentry/nextjs";

const getPrograms =
  (owner: Owner, admin?: boolean, parent?: string) =>
  async (): Promise<Program[]> => {
    try {
      const filterPrograms = (programs: Program[]): Program[] => {
        return programs.filter((program) => {
          if (
            program.status !== "ARCHIVED" &&
            ((admin &&
              !version?.explicit &&
              ["PRIVATE", "TEASER", "PUBLIC"].includes(program.status)) ||
              ["TEASER", "PUBLIC"].includes(program.status))
          )
            return true;
          else return false;
        });
      };

      let query =
        ownerType(owner) === "org"
          ? firestore
              .collection("programs")
              .where("orgSlug", "==", ownerSlug(owner))
          : firestore.collection("programs").where("userId", "==", owner?.id);
      if (typeof parent !== "undefined") {
        query = query.where("parent", "==", parent);
      }
      const queryResult = await query.get();

      const version = await getVersion(owner?.id)();

      if (admin && !version?.explicit) {
        return filterPrograms(
          queryResult.docs.map((programDoc) => {
            return programDoc.data() as Program;
          })
        );
      }

      let programs: Program[] = [];

      for (const programDoc of queryResult.docs) {
        const latestProgramDoc = await programDoc.ref
          .collection("versions")
          .doc(
            version.latest
              ? "latest"
              : `${version.majorVersion}.${version.minorVersion}`
          )
          .get();
        if (!latestProgramDoc.exists) continue;
        const programVersion = latestProgramDoc.data() as ProgramVersion;
        const program = programVersion.program;
        programs.push(program);
      }
      return filterPrograms(programs);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

export default getPrograms;
