import SettingsIcon from "@/components/icons/SettingsIcon";
import useAdmin from "@/hooks/useAdmin";
import useGroup from "@/hooks/useGroup";
import useGroupAdmin from "@/hooks/useGroupAdmin";
import useOwner from "@/hooks/useOwner";
import { MEDIUM_MAX_WIDTH, MOBILE_WIDTH, TABLET_WIDTH } from "@/lib/constants";
import { orgGroupPath, ownerPath } from "@/lib/path";
import { colors } from "@/lib/styles";
import { Org } from "@/models/org";
import { useRouter } from "next/router";
import React, { useState } from "react";
import styled from "styled-components";
import MenuButton from "../TopBar/MenuButton";
import SideBarLink from "./SideBarLink";
import Groups from "./Groups";
import Library from "./Library";
import Breakpoint from "@/components/universal/Breakpoint";
import Link from "next/link";
import Button from "@/components/universal/Button/Button";
import { ownerColor } from "@/lib/owner";
import useLoggedIn from "@/hooks/useLoggedIn";
import UserIcon from "@/components/icons/UserIcon";
import Image from "next/image";
import useUserImage from "@/hooks/useUserImage";
import DashboardIcon from "@/components/icons/DashboardIcon";
import Legal from "@/components/Legal";
import ZapIcon from "@/components/icons/ZapIcon";

const Outer = styled.div<{ mobileOpen: boolean }>`
  width: 300px;
  position: fixed;
  z-index: 199;
  top: 60px;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    overflow-y: auto;
    pointer-events: ${(props) => (props.mobileOpen ? "auto" : "none")};
  }
`;

const Wrapper = styled.div<{ mobileOpen: boolean }>`
  transition: all 500ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  width: 300px;
  z-index: 200;
  flex: 1;
  overflow: visible;
  pointer-events: auto;
  min-height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${colors.white};
  border-right: 1px solid ${colors.lightGray};
  padding: 15px 0;
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    opacity: ${(props) => (props.mobileOpen ? 1 : 0)};
    transform: translateX(${(props) => (props.mobileOpen ? 0 : -50)}px);
    pointer-events: ${(props) => (props.mobileOpen ? "auto" : "none")};
    background-color: ${colors.white};
    max-width: 300px;
    width: 100%;
    left: 0;
    bottom: 0;
    top: 60px;
    right: 0;
    overflow: auto;
    position: fixed;
    padding: 15px 0px 80px;
    box-shadow: ${() => "rgba(0, 0, 0, 0.05)  0 1px 20px 0"};
  }
`;

export type SideBarType = "app" | "build" | "session";

type Props = {
  type: SideBarType;
};

export const SideBar: React.FC<Props> = ({ type }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const router = useRouter();
  const owner = useOwner();
  const admin = useAdmin();
  const groupAdmin = useGroupAdmin();
  const group = useGroup();
  const userImage = useUserImage();
  const loggedIn = useLoggedIn();

  if (type !== "app") return null;

  return (
    <>
      <>
        <Outer mobileOpen={mobileOpen}>
          <Wrapper mobileOpen={mobileOpen}>
            {(admin || groupAdmin) && (
              <SideBarLink
                title="Dashboard"
                href={ownerPath(owner, "dashboard")}
                icon={
                  <DashboardIcon
                    size={16}
                    style={{ transform: "translateY(1px)" }}
                  />
                }
                active={router.asPath === ownerPath(owner, "dashboard")}
              />
            )}

            <Library />
            {(admin || groupAdmin) && (
              <SideBarLink
                title={"Lesson Planner"}
                href={ownerPath(owner, `planner`, null)}
                icon={
                  <ZapIcon size={22} style={{ transform: "translateY(1px)" }} />
                }
                active={router.asPath.includes(ownerPath(owner, `planner`))}
              />
            )}
            <Groups />
            {loggedIn && (
              <Breakpoint to={MOBILE_WIDTH} style={{ width: "100%" }}>
                <SideBarLink
                  title="Account"
                  href={ownerPath(owner, "my/progress")}
                  icon={
                    userImage ? (
                      <div
                        style={{
                          width: 16,
                          height: 16,
                          borderRadius: 8,
                          overflow: "hidden",
                        }}
                      >
                        <Image
                          src={userImage}
                          width={16}
                          height={16}
                          objectFit="contain"
                          objectPosition={"center center"}
                        />
                      </div>
                    ) : (
                      <UserIcon />
                    )
                  }
                  active={router.asPath === ownerPath(owner, "my/progress")}
                />
              </Breakpoint>
            )}
            {(admin || groupAdmin) && (
              <SideBarLink
                title={"Settings"}
                href={
                  admin
                    ? ownerPath(owner, `settings`, null)
                    : groupAdmin
                    ? orgGroupPath(owner as Org, group, `admins`, null)
                    : null
                }
                icon={
                  <SettingsIcon
                    size={16}
                    style={{ transform: "translateY(1px)" }}
                  />
                }
                active={
                  admin
                    ? router.asPath.includes(ownerPath(owner, `settings`))
                    : groupAdmin
                    ? router.asPath.includes(
                        orgGroupPath(owner as Org, group, `about`)
                      ) ||
                      router.asPath.includes(
                        orgGroupPath(owner as Org, group, `admins`)
                      )
                    : false
                }
              />
            )}
            {!loggedIn && (
              <Breakpoint
                to={MEDIUM_MAX_WIDTH}
                style={{ width: "100%", marginTop: "auto", paddingTop: 20 }}
              >
                <Link
                  href={ownerPath(owner, "login", {
                    next: router.asPath !== "/" ? router.asPath : undefined,
                  })}
                  passHref
                >
                  <Button
                    backgroundColor={ownerColor(owner)}
                    color={colors.white}
                    style={{ flex: 1, margin: "0 20px" }}
                  >
                    Log in
                  </Button>
                </Link>
              </Breakpoint>
            )}
            {owner && <Legal />}
          </Wrapper>
        </Outer>
        <MenuButton
          open={mobileOpen}
          onClick={() => setMobileOpen(!mobileOpen)}
        />
      </>
    </>
  );
};
export default SideBar;
