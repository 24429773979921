import styled from "styled-components";
import Link from "next/link";
import { colors } from "@/lib/styles";
import { fadeIn } from "@/lib/animations";
import { ownerPath } from "@/lib/path";
import { MOBILE_WIDTH, TABLET_WIDTH } from "@/lib/constants";
import useOwnerColor from "@/hooks/useOwnerColor";
import useOwner from "@/hooks/useOwner";
import { Folder } from "@/models/folder";
import FolderIcon from "../icons/FolderIcon";
import TagComponent from "../universal/Tags/Tag";
import { rgba } from "polished";
import { useQuery } from "react-query";
import { Tag } from "@/models/tag";
import getTags from "@/db/tag/getTags";
import { find } from "lodash";
import Image from "next/image";

const Wrapper = styled.a`
  height: 200px;
  margin: 10px;
  border: 1px solid ${colors.lightGray};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  /* flex: 1; */
  flex-basis: 50%;
  max-width: calc(50% - 20px);
  flex-shrink: 0;
  border-radius: 10px;
  text-decoration: none;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  ${fadeIn}
  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.08) 0 1px 5px 0;
  }
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    max-width: calc(50% - 20px);
    flex-basis: 50%;
  }
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    height: auto;
    max-width: calc(100% - 20px);
    flex-basis: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px;
`;

const Title = styled.h3`
  font-size: 21px;
  font-weight: 500;
  color: ${colors.nearBlack};
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 21px;
  }
`;

const CoverImage = styled.div`
  width: 300px;
  flex-shrink: 0;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;
  position: relative;
  background-color: ${colors.nearWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${colors.lightGray};
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    height: 150px;
    width: 100%;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-right: 0;
    border-bottom: 1px solid ${colors.lightGray};
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 0 0;
  margin-top: auto;
`;

const FolderTag = styled.div`
  padding: 4px 6px;
  border-radius: 6px;
  background-color: ${rgba(colors.blue, 0.2)};
  color: ${colors.nearBlack};
  font-size: 16px;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type Props = {
  folder: Folder;
  style?: object;
};
export const FolderResult: React.FC<Props> = ({ folder, style }) => {
  const owner = useOwner();
  const ownerColor = useOwnerColor();
  const link = ownerPath(owner, [...folder.slugPaths, folder.slug].join("/"));

  const tagsQuery = useQuery<Tag[]>(["tags", owner.id], getTags(owner));

  return (
    <Link href={link} passHref>
      <Wrapper style={style}>
        <CoverImage>
          {!!folder.coverImage && (
            <Image layout="fill" objectFit="cover" src={folder.coverImage} />
          )}
          {!folder.coverImage && (
            <FolderIcon size={40} color={colors.gray} strokeWidth={3} />
          )}
        </CoverImage>
        <Content>
          <Title>{folder.title}</Title>
          {!tagsQuery.isLoading && folder.tags && (
            <Bottom>
              {folder.tags.map((tag, i) => (
                <TagComponent
                  key={`tag-${i}`}
                  tag={find(tagsQuery.data, { id: tag })}
                  style={{ marginRight: 10 }}
                />
              ))}
            </Bottom>
          )}
        </Content>
      </Wrapper>
    </Link>
  );
};

export default FolderResult;
