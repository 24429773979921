import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
  strokeWidth?: number;
};
const FolderIcon: React.FC<Props> = ({ size, style, color, strokeWidth }) => {
  const height = size ? String(size) : "14";
  const width = size ? String((size / 16) * 14) : "16";
  return (
    <span style={style}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 11.6667C15 12.0203 14.8525 12.3594 14.5899 12.6095C14.3274 12.8595 13.9713 13 13.6 13H2.4C2.0287 13 1.6726 12.8595 1.41005 12.6095C1.1475 12.3594 1 12.0203 1 11.6667V2.33333C1 1.97971 1.1475 1.64057 1.41005 1.39052C1.6726 1.14048 2.0287 1 2.4 1H5.9L7.3 3H13.6C13.9713 3 14.3274 3.14048 14.5899 3.39052C14.8525 3.64057 15 3.97971 15 4.33333V11.6667Z"
          stroke={color}
          strokeWidth={strokeWidth ? String(strokeWidth) : 2}
          strokeLinecap="round"
          vectorEffect="non-scaling-stroke"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
FolderIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default FolderIcon;
