import { firestore } from "@/firebase";
import { Folder } from "@/models/folder";
import { Owner } from "@/models/owner";
import { Program } from "@/models/program";
import getSlugPaths from "./getSlugPaths";

const updateChildrenPaths = async (data: {
  owner: Owner;
  folder: Folder;
}): Promise<void> => {
  const { owner, folder } = data;

  const childrenQuery = await firestore
    .collection("orgs")
    .doc(owner.id)
    .collection("folders")
    .where("parent", "==", folder.id)
    .get();
  if (!childrenQuery.empty) {
    for (const childrenDoc of childrenQuery.docs) {
      const child: Folder | Program = childrenDoc.data() as Folder | Program;
      const slugPaths = await getSlugPaths({ owner, parent: child.parent });
      await childrenDoc.ref.update({
        slugPaths,
      });
    }
  }
};

export default updateChildrenPaths;
