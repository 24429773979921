import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
  svgStyle?: Record<string, unknown>;
};
const CurveArrowIcon: React.FC<Props> = (props) => {
  const width = props.size ? String((props.size / 13) * 18) : "18";
  const height = props.size ? String(props.size) : "13";

  return (
    <span style={props.style}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.01644 10.5521C4.60297 7.45183 7.98809 1.2011 16.8363 1"
          stroke={props.color}
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
        />
        <path
          d="M1 7.28424L3.5137 11.5575L8.79247 9.79794"
          stroke={props.color}
          strokeWidth="2"
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
        />
      </svg>
    </span>
  );
};
CurveArrowIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default CurveArrowIcon;
