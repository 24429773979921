import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
  strokeWidth?: number;
};
const BuildIcon: React.FC<Props> = ({ size, style, color, strokeWidth }) => {
  const width = String(size);
  const height = String((21 / 20) * size);

  return (
    <span style={style}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.1035 13.6036V6.40048C18.1031 6.08469 18.0198 5.77454 17.8618 5.50114C17.7037 5.22774 17.4766 5.00071 17.2031 4.84281L10.9004 1.24126C10.6266 1.08321 10.3161 1 9.99998 1C9.68388 1 9.37334 1.08321 9.09959 1.24126L2.79687 4.84281C2.52339 5.00071 2.29624 5.22774 2.1382 5.50114C1.98017 5.77454 1.89681 6.08469 1.89648 6.40048V13.6036C1.89681 13.9194 1.98017 14.2295 2.1382 14.5029C2.29624 14.7763 2.52339 15.0034 2.79687 15.1613L9.09959 18.7628C9.37334 18.9209 9.68388 19.0041 9.99998 19.0041C10.3161 19.0041 10.6266 18.9209 10.9004 18.7628L17.2031 15.1613C17.4766 15.0034 17.7037 14.7763 17.8618 14.5029C18.0198 14.2295 18.1031 13.9194 18.1035 13.6036Z"
          stroke={color}
          strokeWidth={strokeWidth ? String(strokeWidth) : "2"}
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.13965 5.46411L10 10.0111L17.8604 5.46411"
          stroke={color}
          strokeWidth={strokeWidth ? String(strokeWidth) : "2"}
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 19.078V10.002"
          stroke={color}
          strokeWidth={strokeWidth ? String(strokeWidth) : "2"}
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
BuildIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default BuildIcon;
