import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";

const Wrapper = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  align-self: center;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
`;

type Props = {
  size: number;
  style?: object;
  light?: boolean;
};
const Checkmark: React.FC<Props> = (props) => {
  const { size, style, light } = props;

  return (
    <Wrapper size={size} style={style}>
      <Lottie
        options={{
          loop: false,
          autoplay: true,
          animationData: !light
            ? require("@/lib/animations/checkmarkDark.json")
            : require("@/lib/animations/checkmarkLight.json"),
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={size}
        width={size}
      />
    </Wrapper>
  );
};
Checkmark.defaultProps = {
  style: {},
};

export default Checkmark;
