import React from "react";
import { colors } from "@/lib/styles";
import styled from "styled-components";
import Link from "next/link";
import EmptyIcon from "@/components/universal/EmptyIcon";

const Wrapper = styled.a<{ active: boolean }>`
  text-decoration: none;
  width: 100%;
  padding: 12px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  background-color: ${(props) =>
    props.active ? colors.nearWhite : "transparent"};
  &:hover {
    background-color: ${colors.nearWhite};
    cursor: ${(props) => (props.active ? "default" : "pointer")};
  }
  h5 {
    font-weight: ${(props) => (props.active ? 500 : 400)};
    color: ${(props) => (props.active ? colors.nearBlack : colors.darkGray)};
  }
  &:hover h5 {
    color: ${colors.nearBlack};
  }
  path,
  circle {
    stroke: ${(props) => (props.active ? colors.nearBlack : colors.darkGray)};
  }
  &:hover path,
  &:hover circle {
    stroke: ${colors.nearBlack};
  }
`;

const Title = styled.h5`
  font-size: 16px;
  margin-left: 10px;
  flex: 1;
`;

const ActiveIndicator = styled.div<{ active: boolean }>`
  position: absolute;
  right: 0;
  width: 2px;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: ${colors.nearBlack};
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  opacity: ${(props) => (props.active ? 1 : 0)};
`;

const IconWrapper = styled.div`
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

type Props = {
  icon?: React.ReactElement;
  title: string;
  active: boolean;
  href: string;
  action?: React.ReactElement;
  style?: object;
  iconStyle?: object;
  target?: string;
};
const SideBarLink: React.FC<Props> = ({
  icon,
  title,
  active,
  action,
  href,
  style,
  iconStyle,
  target,
}) => {
  return (
    <>
      <Link href={href} passHref>
        <Wrapper active={active} style={style} target={target}>
          {icon && <IconWrapper style={iconStyle}>{icon}</IconWrapper>}
          {!icon && <EmptyIcon size={20} title={title} />}
          <Title>{title}</Title>
          <ActiveIndicator active={active} />
        </Wrapper>
      </Link>
      {action}
    </>
  );
};

export default SideBarLink;
