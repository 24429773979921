import { useField, useFormikContext } from "formik";
import { InputProps } from "../Input/Input";
import TextAreaInput from "../Input/TextAreaInput";

const VersionDescriptionField: React.FC<Partial<InputProps>> = (props) => {
  const [field, meta] = useField("description");
  const { isValid } = useFormikContext<{}>();
  return (
    <TextAreaInput
      name={"description"}
      title="Version description"
      inputStyle={{ width: 350 }}
      description="Summarize the changes in this version"
      {...field}
      valid={field.value && !meta.error && isValid}
      error={meta.touched && meta.error ? meta.error : undefined}
      {...props}
    />
  );
};

export default VersionDescriptionField;
