import Search from "@/components/Search";
import Breakpoint from "@/components/universal/Breakpoint";
import { MOBILE_WIDTH } from "@/lib/constants";
import React from "react";
import styled from "styled-components";
import AppTopBar from "./AppTopBar";
import BuildTopBar from "./BuildTopBar";
import SessionTopBar from "./SessionTopBar";

const Wrapper = styled.div`
  width: 100%;
  transition: all 500ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  z-index: 300;
`;

export type TopBarType = "app" | "build" | "session";

type Props = {
  type: TopBarType;
};

export const TopBar: React.FC<Props> = (props) => {
  const { type } = props;

  let component: React.ReactElement;
  switch (type) {
    case "app":
      component = <AppTopBar />;
      break;
    case "build":
      component = <BuildTopBar />;
      break;
    case "session":
      component = <SessionTopBar />;
      break;
  }

  return (
    <>
      <Wrapper id="top-bar">{component}</Wrapper>
      {type === "app" && (
        <Breakpoint
          to={MOBILE_WIDTH}
          style={{
            zIndex: 401,
            position: "fixed",
            top: 0,
            right: 0,
            height: 60,
          }}
        >
          <Search />
        </Breakpoint>
      )}
    </>
  );
};

export default TopBar;
