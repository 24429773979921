import { firestore } from "@/firebase";
import { ownerCollection, ownerType } from "@/lib/owner";
import { Group } from "@/models/group";
import { Owner } from "@/models/owner";
import { Program } from "@/models/program";
import * as Sentry from "@sentry/nextjs";
import deleteFile from "../utils/deleteFile";
import resetPositions from "../utils/resetPositions";
import getFolder from "./getFolder";
import updateFolder from "./updateFolder";

const archiveFolder = async (data: {
  owner: Owner;
  folderId: string;
}): Promise<void> => {
  try {
    const { owner, folderId } = data;
    // archive group
    await updateFolder({
      owner,
      folderId,
      folderUpdate: { status: "ARCHIVED" },
    });
    const folder = await getFolder(owner, folderId, true)();
    await resetPositions("library", owner, folder.parent);
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};

export default archiveFolder;
