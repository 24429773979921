import { OwnerContext } from "@/components/App";
import { ownerColor } from "@/lib/owner";
import { useContext } from "react";
import useOwner from "./useOwner";

const useOwnerColor = () => {
  const owner = useOwner();
  return ownerColor(owner);
};
export default useOwnerColor;
