import React from "react";
import NavPath from "./NavPath";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import { ACCOUNT_LINK } from "./links";
import NavLinks from "./NavLinks";
import useLoggedIn from "@/hooks/useLoggedIn";
import Button from "@/components/universal/Button/Button";
import useOwner from "@/hooks/useOwner";
import { ownerPath } from "@/lib/path";
import { useRouter } from "next/router";
import { rgba } from "polished";
import CaretIcon from "@/components/icons/CaretIcon";
import Feedback from "./Feedback";
import Breakpoint from "@/components/universal/Breakpoint";
import { TABLET_WIDTH } from "@/lib/constants";

const Wrapper = styled.div`
  flex: 1;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.lightGray};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
  height: 60px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

type Props = {};

const SessionTopBar: React.FC<Props> = (props) => {
  const {} = props;
  const owner = useOwner();
  const loggedIn = useLoggedIn();
  const router = useRouter();
  const preview = router.pathname.includes("preview");

  return (
    <Wrapper>
      <Left>
        {preview && (
          <Button
            onClick={router.back}
            color={colors.white}
            backgroundColor={colors.nearBlack}
            hoverBackgroundColor={rgba(colors.nearBlack, 0.9)}
            style={{
              height: 60,
              padding: 20,
              borderRadius: 0,
              margin: 0,
              transform: "translateX(-20px)",
            }}
          >
            <CaretIcon
              color={colors.white}
              size={20}
              style={{
                marginRight: 15,
                transform: "rotate(180deg) translateY(3px)",
              }}
            />
            Back
          </Button>
        )}
        <NavPath clearParams />
      </Left>
      <Breakpoint from={TABLET_WIDTH}>
        <NavLinks
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: 0,
          }}
          links={
            loggedIn
              ? [<Feedback key="topbar-feedback" />, ACCOUNT_LINK(owner)]
              : [
                  <Feedback key="topbar-feedback" />,
                  <Button
                    key="login"
                    href={
                      owner
                        ? ownerPath(owner, `login`, { next: router.asPath })
                        : `/?next=${router.asPath}`
                    }
                    style={{ padding: "10px 15px", margin: "auto 0" }}
                    color={colors.nearBlack}
                    backgroundColor={colors.nearWhite}
                  >
                    Log in
                  </Button>,
                ]
          }
        />
      </Breakpoint>
      <Breakpoint to={TABLET_WIDTH}>
        <NavLinks
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: 0,
          }}
          links={[<Feedback key="topbar-feedback" />, ACCOUNT_LINK(owner)]}
        />
      </Breakpoint>
    </Wrapper>
  );
};

export default SessionTopBar;
