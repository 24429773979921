import { useField, useFormikContext } from "formik";
import { InputProps } from "../Input/Input";
import SelectInput from "../Input/SelectInput";

const VersionTypeField: React.FC<Partial<InputProps>> = (props) => {
  const [field, meta] = useField("type");
  const { isValid } = useFormikContext<{}>();
  return (
    <SelectInput
      name="type"
      title="Version type"
      description="Major versions reset tracking of sessions and surveys. Minor versions are used for small changes."
      {...(field as any)}
      options={[
        { title: "Minor", value: "minor" },
        { title: "Major", value: "major" },
      ]}
      valid={field.value && !meta.error && isValid}
      error={meta.touched && meta.error ? meta.error : undefined}
      {...props}
    />
  );
};

export default VersionTypeField;
