import { MOBILE_WIDTH } from "@/lib/constants";
import { colors } from "@/lib/styles";
import styled from "styled-components";

const Description = styled.p`
  font-size: 28px;
  color: ${colors.darkGray};
  font-weight: 400;
  text-align: center;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 21px;
  }
`;
export default Description;
