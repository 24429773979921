import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import { MOBILE_WIDTH } from "@/lib/constants";
import { Description, Title, Top } from "./Input";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
  position: relative;
`;

const Error = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.darkGray};
  margin-top: 5px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 12px;
  }
`;
const TextArea = styled.textarea`
  padding: 10px 15px;
  outline: none;
  border: 1px solid ${colors.lightGray};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: ${colors.nearBlack};
  font-weight: 500;
  min-height: 200px;
  background-color: ${colors.white};
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 5px 0;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  &:hover {
  }
`;

type OwnProps = {
  autoHeight?: boolean;
  title?: string;
  description?: string;
  style?: Record<string, unknown>;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  inputStyle?: Record<string, unknown>;
  labelStyle?: Record<string, unknown>;
};

type Props = OwnProps & Partial<React.HTMLProps<HTMLInputElement>>;

const TextAreaInput: React.FC<Props> = (props) => {
  const {
    type,
    title,
    style,
    labelStyle,
    inputStyle,
    value,
    description,
    error,
    onChange,
    name,
    autoHeight,
    ...otherProps
  } = props;

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (autoHeight) {
      if (!inputRef.current || !inputRef.current?.style) return;
      inputRef.current.style.height = "0px";
      const scrollHeight = inputRef.current.scrollHeight;
      inputRef.current.style.height = scrollHeight + "px";
    }
  }, [autoHeight, value]);

  return (
    <Wrapper style={style}>
      {title && (
        <Top>
          {title && (
            <Title style={labelStyle} htmlFor={name}>
              {title}
            </Title>
          )}
          {description && <Description>{description}</Description>}
        </Top>
      )}
      <TextArea
        ref={inputRef}
        value={value}
        onChange={onChange}
        type={type}
        name={name}
        style={inputStyle}
        {...(otherProps as any)}
      />
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};

export default TextAreaInput;
