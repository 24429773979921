import { firestore } from "@/firebase";
import { ownerCollection, ownerType } from "@/lib/owner";
import { Group } from "@/models/group";
import { Owner } from "@/models/owner";
import { Program } from "@/models/program";
import * as Sentry from "@sentry/nextjs";
import getOrg from "../org/getOrg";
import getOwner from "../owner/getOwner";
import deleteFile from "../utils/deleteFile";
import resetPositions from "../utils/resetPositions";
import getProgram from "./getProgram";
import updateProgram from "./updateProgram";

const archiveProgram = async (data: { programId: string }): Promise<void> => {
  try {
    const { programId } = data;
    // archive group
    await updateProgram({
      programId,
      programUpdate: { status: "ARCHIVED" },
    });
    const program = await getProgram(programId, true)();
    const org = await getOrg(program.orgId)();
    await resetPositions("library", org, program.parent);
  } catch (error) {
    Sentry.captureException(error);
    console.log(error);
  }
};

export default archiveProgram;
