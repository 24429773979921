import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { colors, optionColors } from "@/lib/styles";
import { Tag } from "@/models/tag";
import { find } from "lodash";

type Props = {
  tag: Tag;
  action?: React.ReactElement;
  style?: Record<string, unknown>;
};

const Wrapper = styled.div<{ color: string }>`
  padding: 4px 6px;
  border-radius: 6px;
  background-color: ${(props) => rgba(props.color, 0.2)};
  color: ${colors.nearBlack};
  font-size: 16px;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TagComponent: React.FC<Props> = (props) => {
  const { style, action, tag } = props;

  return (
    <Wrapper
      color={find(optionColors, { name: tag?.color })?.color}
      style={style}
    >
      {tag?.title}
      {action}
    </Wrapper>
  );
};
TagComponent.defaultProps = {
  style: {},
};

export default TagComponent;
