import React from "react";
import { colors } from "@/lib/styles";

type Props = {
  size?: number;
  style?: Record<string, unknown>;
  color?: string;
};
const LibraryIcon: React.FC<Props> = ({ size, style, color }) => {
  return (
    <span style={style}>
      <svg
        width={String(size)}
        height={String(size)}
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1H5.2C5.94261 1 6.6548 1.295 7.1799 1.8201C7.705 2.3452 8 3.05739 8 3.8V13.6C8 13.043 7.77875 12.5089 7.38492 12.1151C6.9911 11.7212 6.45695 11.5 5.9 11.5H1V1Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M15 1H10.8C10.0574 1 9.3452 1.295 8.8201 1.8201C8.295 2.3452 8 3.05739 8 3.8V13.6C8 13.043 8.22125 12.5089 8.61508 12.1151C9.0089 11.7212 9.54305 11.5 10.1 11.5H15V1Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </span>
  );
};
LibraryIcon.defaultProps = {
  color: colors.darkGray,
  size: 16,
};

export default LibraryIcon;
