import api, { idToken } from "./api";

const log =
  (
    type: "feedback" | "session" | "lesson",
    text: string,
    url?: string,
    uid?: string
  ) =>
  async () => {
    await api(null, null).post("/log", {
      type,
      text,
      url,
      uid,
    });
  };
export default log;
