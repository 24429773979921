import { MOBILE_WIDTH, TABLET_WIDTH } from "@/lib/constants";
import Link from "next/link";
import styled, { css, CSSObject } from "styled-components";

type Props = {
  hoverColor?: string;
  color?: string;
  disabled?: boolean;
  href?: string;
  style?: Record<string, unknown>;
  desktopStyle?: CSSObject;
  tabletStyle?: CSSObject;
  mobileStyle?: CSSObject;
  onClick?: (e?: any) => void;
  submit?: boolean;
  id?: string;
  target?: string;
  ref?: any;
};

const style = (props: Props) => css<Props>`
  padding: 10px 20px;
  outline: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: ${(props) => props.color};
  font-weight: 500;
  background-color: transparent;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  text-decoration: none;
  ${(props) => props.desktopStyle && css(props.desktopStyle)}
  border: none;
  &:hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    color: ${(props) => (props.hoverColor ? props.hoverColor : props.color)};
  }
  @media screen and (max-width: ${TABLET_WIDTH}px) {
    ${(props) => props.tabletStyle && css(props.tabletStyle)}
  }
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    ${(props) => props.mobileStyle && css(props.mobileStyle)}
  }
  &:hover .stroke-path {
    stroke: ${(props) => (props.hoverColor ? props.hoverColor : props.color)};
  }
`;
const StyledButton = styled.button<Props>`
  ${style}
`;

const StyledAnchor = styled.a<Props>`
  ${style}
`;

const TextButton: React.FC<Props> = (props) => {
  if (props.href)
    return (
      <Link href={props.href}>
        <StyledAnchor {...props} ref={props.ref} />
      </Link>
    );
  else
    return (
      <StyledButton
        ref={props.ref}
        type={props.submit ? "submit" : "button"}
        {...props}
      />
    );
};
export default TextButton;
