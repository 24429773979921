import { ownerAdmin } from "@/lib/owner";
import { Org } from "@/models/org";
import { authSelectors } from "@/redux/auth";
import { userSelectors } from "@/redux/user";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import useAdmin from "./useAdmin";
import useGroupAdmin from "./useGroupAdmin";
import useOwner from "./useOwner";

const usePreview = () => {
  const router = useRouter();
  const previewQuery = router.query.preview;
  const previewPath = router.pathname.includes("preview");
  const admin = useAdmin();
  const groupAdmin = useGroupAdmin();
  if ((previewQuery || previewPath) && (admin || groupAdmin)) return true;
  else return false;
};
export default usePreview;
