import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "@/lib/styles";
import Link from "next/link";
import { MEDIUM_MAX_WIDTH, MOBILE_WIDTH } from "@/lib/constants";
import { ownerPath } from "@/lib/path";
import Image from "next/image";
import useOwner from "@/hooks/useOwner";
import { ownerImage, ownerTitle } from "@/lib/owner";
import useAdmin from "@/hooks/useAdmin";
import Versions from "../Versions";
import useUserOwnerships from "@/hooks/useUserOwnerships";
import WorkspaceSwitcher from "../SideBar/WorkspaceSwitcher";

const MobileButton = styled.button<{ open: boolean }>`
  display: none;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  background: none;
  outline: none;
  border: none;
  @media screen and (max-width: ${MEDIUM_MAX_WIDTH}px) {
    display: block;
  }
`;

const Wrapper = styled.div<{ mobileOpen: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  margin-right: auto;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    max-width: 100%;
    margin: 0 auto;
  }
`;

export const ItemTitle = styled.h2`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.nearBlack};
  white-space: nowrap;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
`;

export const ItemWrapper = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  transition: all 100ms cubic-bezier(0.21, 0.94, 0.64, 0.99);
  border: 1px solid transparent;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const ImageWrapper = styled.div<{ round?: boolean }>`
  height: 36px;
  width: 36px;
  flex-shrink: 0;
  margin-right: 10px;
  position: relative;
  border-radius: ${(props) => (props.round ? "50%" : "4px")};
  overflow: hidden;
`;

export const ItemDivider = styled.span`
  font-size: 16px;
  color: ${colors.lightGray};
  font-weight: 400;
  margin: 0 10px;
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    display: none;
  }
`;

type Props = {
  style?: object;
  clearParams?: boolean;
};

const NavPath: React.FC<Props> = ({ style, clearParams }) => {
  const admin = useAdmin();
  const owner = useOwner();
  const userOwnerships = useUserOwnerships();

  const [mobileOpen, setMobileOpen] = useState(false);

  const OwnerSection =
    admin && userOwnerships?.length > 1 ? (
      <>
        <WorkspaceSwitcher />
        <Versions />
      </>
    ) : (
      <>
        <Link
          href={ownerPath(owner, ``, clearParams ? null : undefined)}
          passHref
        >
          <ItemWrapper>
            {ownerImage(owner) && (
              <ImageWrapper round>
                <Image
                  src={ownerImage(owner)}
                  objectFit="contain"
                  layout="fixed"
                  width={36}
                  height={36}
                  priority
                />
              </ImageWrapper>
            )}
            <ItemTitle id="owner-title">{ownerTitle(owner)}</ItemTitle>
          </ItemWrapper>
        </Link>
        <Versions />
      </>
    );

  return (
    <>
      <Wrapper style={style} mobileOpen={mobileOpen}>
        {OwnerSection}
      </Wrapper>
      <MobileButton
        open={mobileOpen}
        onClick={() => setMobileOpen((o) => !o)}
      />
    </>
  );
};

export default NavPath;
