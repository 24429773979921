import { firestore } from "@/firebase";
import { ownerSlug, ownerType } from "@/lib/owner";
import { Folder } from "@/models/folder";
import { Owner } from "@/models/owner";
import { Tag } from "@/models/tag";

const getTags = (owner: Owner) => async (): Promise<Tag[]> => {
  let query = firestore
    .collection("orgs")
    .doc(owner.id)
    .collection("tags")
    .orderBy("position", "asc");
  const queryResult = await query.get();

  return queryResult.docs.map((doc) => doc.data() as Tag);
};

export default getTags;
